import React from "react";

type Props = {
  title: string;
  name: string;
  defaultValue?: string;
  lines?: number;
  error?: boolean;
  onChange?: (e: string) => void;
};

const TextAreaInputForm = ({
  title = "",
  defaultValue,
  name,
  error = false,
  lines = 3,
  onChange,
}: Props) => {
  return (
    <div className="flex flex-col w-full gap-2">
      <span className={`flex text-[15px] ${error ? "text-red-500" : ""}`}>
        {title}
      </span>
      <textarea
        name={name}
        defaultValue={defaultValue}
        placeholder={title}
        style={{
          maxLines: 10,
        }}
        rows={lines}
        onChange={(e) => {
          if (!!onChange) {
            !!e && onChange(e?.target?.value ?? "");
          }
        }}
        className={`transition-all duration-150 flex outline-4 min-h-[50px] outline-primary px-4 min-w-0 text-[18px] w-full bg-slate-200 rounded-[8px] ${
          error
            ? "placeholder-red-500 border-[3px] border-red-500 outline-transparent outline-none"
            : ""
        }`}
      />
    </div>
  );
};

export default TextAreaInputForm;
