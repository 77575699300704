import React from "react";
import { ScreenHeaderActionRefresh } from "../../../../../../components/screen_header";
import ListItems from "./list_items";
import { useDataStore } from "../../../../../../configs/stores/dataStore";
import { useQuery } from "@tanstack/react-query";
import { useAuthStore } from "../../../../../../configs/stores/auth";

const DashMyMissionsScreenIntervews = () => {
  const { isAuthenticated, user } = useAuthStore();
  const { interviews, isRefetchingInterviews, refetchInterviews } =
    useDataStore();
  const nextInterviews = interviews.filter((i) => {
    if (i.status !== "pending") {
      return false;
    }
    return true;
  });
  const passedInterviews = interviews.filter((i) => {
    if (i.status === "pending") {
      return false;
    }
    return true;
  });
  useQuery({
    enabled: isAuthenticated,
    queryFn: refetchInterviews,
    queryKey: ["interviews", "meets", user?.id],
    staleTime: 10 * 60 * 1000, // 5 minutes in milliseconds
    refetchInterval: 10 * 60 * 1000, // 5 minutes in milliseconds
  });
  return (
    <div className="flex flex-1 flex-col gap-4 h-full w-full">
      <div className="flex flex-col max-w-[300px]">
        <ScreenHeaderActionRefresh
          onClick={refetchInterviews}
          isLoading={isRefetchingInterviews}
          title="Rafraichir les entretiens"
        />
      </div>
      <div className="flex flex-1 flex-col gap-8">
        <ListItems
          title="Entretiens en attentent"
          list={nextInterviews}
          state="next"
        />
        <ListItems
          title="Entretiens passés"
          list={passedInterviews}
          state="passed"
        />
      </div>
    </div>
  );
};

export default DashMyMissionsScreenIntervews;
