import React from "react";
import ScreenContentLayoutMenu from "../../../../../components/screen_content_layout/menu";
import { Outlet } from "react-router-dom";

const DashInterviewsScreenSub = () => {
  const menus = [
    {
      title: "Géneral",
      url: "",
    },
    {
      title: "Questionnaires d'entretiens",
      url: "surveys",
    },
  ];
  return (
    <React.Fragment>
      <div className="flex flex-1 flex-col h-full w-full">
        <ScreenContentLayoutMenu menus={menus} />
        <Outlet />
      </div>
    </React.Fragment>
  );
};

export default DashInterviewsScreenSub;
