import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { ZustandLocalStorageCrypted } from "./utils";

const LOCAL_DATASTORE_NAME = "missionary-conf-datas";

type ContextProps = {
  openSideBar: boolean;
  updateSidebarOpenState: (state: boolean) => void;
};

export const useConfigManagingDataStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      openSideBar: true,
      updateSidebarOpenState: (bool = true) => {
        set(
          produce((state: ContextProps) => {
            state.openSideBar = bool;
          })
        );
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_cf")),
    }
  )
);
