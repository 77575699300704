import { Outlet } from "react-router-dom"

const DashNotificationsScreenEmail = () => {
  return (
    <div className="flex flex-1 flex-col h-full w-full">
      <Outlet />
    </div>
  )
}

export default DashNotificationsScreenEmail
