import React from "react";
import TableDataAutoList from "../../../../../../../../components/table";
import { useAuthStore } from "../../../../../../../../configs/stores/auth";
import { useAdminDataStore } from "../../../../../../../../configs/stores/admindataStore";
import { useQuery } from "@tanstack/react-query";
import UserActions from "./actions";
import MissionariesScreenHeader from "./header";
import { useParams } from "react-router-dom";

const MissionMissionariesScreen = () => {
  const { id } = useParams();
  const { isAuthenticated } = useAuthStore();
  const { refetchMissionaries } = useAdminDataStore();
  async function fetchData() {
    if (!id) {
      return;
    }
    const res = await refetchMissionaries(id);
    if (!res.success) {
      return null;
    }
    return res.data;
  }
  const { data = [] } = useQuery({
    enabled: isAuthenticated,
    queryFn: fetchData,
    queryKey: ["missions", id, "missionaries"],
    staleTime: 10 * 60 * 1000, // 5 minutes in milliseconds
    refetchInterval: 10 * 60 * 1000, // 5 minutes in milliseconds
  });
  const columns = [
    {
      label: "Nom & Prénoms",
      slug: "name",
    },
    {
      label: "Contact",
      slug: "phone",
    },
    {
      label: "Adresse email",
      slug: "email",
    },
    {
      label: "Pays",
      slug: "country",
    },
    {
      label: "Ville",
      slug: "city",
    },
  ];
  return (
    <div className="flex flex-col gap-4">
      <MissionariesScreenHeader />
      <TableDataAutoList
        columns={columns}
        rows={data ?? []}
        actions={UserActions}
      />
    </div>
  );
};

export default MissionMissionariesScreen;
