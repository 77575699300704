import clsx from "clsx";
import { Edit, Trash2 } from "lucide-react";
import React from "react";
import { useAdminDataStore } from "../../../../../../../../configs/stores/admindataStore";
import MissionUpdateModal from "../../../../../../../../components/modals/models/mission_update";
import ConfirmActionModal from "../../../../../../../../components/modals/models/confirmation";

type Props = {
  row?: any;
};

const UserActions = ({ row }: Props) => {
  const { removeMission } = useAdminDataStore();
  const [isOpenModify, setisOpenModify] = React.useState(false);
  function closeModify() {
    setisOpenModify(false);
  }
  const [isOpen, setisOpen] = React.useState(false);
  function close() {
    // setisOpen(false);
  }
  async function confirm() {
    if (!row?.id) {
      alert("Requète non valide !");
      return;
    }
    // return await removeMission(row?.id).then((res) => {
    //   if (!res.success) {
    //     !res?.message && alert(res?.message);
    //   }
    // });
  }
  return (
    <React.Fragment>
      <div className="flex flex-col">
        <div
          onClick={() => {
            setisOpenModify(true);
          }}
          className={clsx(
            "flex cursor-pointer flex-row gap-[8px] h-[40px] w-full items-center rounded-[8px] px-3",
            "hover:bg-slate-500/20 active:bg-slate-500/40 "
          )}
        >
          <Edit size={18} />
          <span className="flex text-black font-medium text-[15px]">
            Modifier
          </span>
        </div>
        <div
          onClick={() => {
            setisOpen(true);
          }}
          className={clsx(
            "flex cursor-pointer flex-row gap-[8px] h-[40px] w-full items-center rounded-[8px] px-3",
            "hover:bg-slate-500/20 active:bg-slate-500/40 "
          )}
        >
          <Trash2 size={18} />
          <span className="flex text-black font-medium text-[15px]">
            Supprimer
          </span>
        </div>
      </div>
      <MissionUpdateModal
        open={isOpenModify}
        item={row}
        handleClose={closeModify}
      />
      <ConfirmActionModal
        open={isOpen}
        message="Voulez-vous vraiment supprimer cette mission ?"
        confirm={confirm}
        handleClose={close}
      />
    </React.Fragment>
  );
};

export default UserActions;
