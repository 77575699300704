import React, { ReactNode } from "react";
import clsx from "clsx";
import { Download, Filter } from "lucide-react";
import { usePopUpHook } from "../../hooks/popuphook";
import * as XLSX from "xlsx";

type Props = {
  data?: any;
  columns?: any;
  selectedSearchIndex?: any;
  onChange?: (str: string) => void;
  setIndex?: (str: string) => void;
};

function useTableButtonRowOverlayState() {
  const { refInput, refPopUp, open, switchMenu } = usePopUpHook<
    HTMLDivElement,
    HTMLDivElement
  >({});
  function onClick() {
    switchMenu();
  }
  return { onClick, refInput, refPopUp, open };
}

const TableSearchRow = ({
  data,
  columns,
  selectedSearchIndex,
  onChange,
  setIndex,
}: Props) => {
  const {
    onClick: onClickFilter,
    refInput: refInputFilter,
    refPopUp: refPopUpFilter,
    open: openFilter,
  } = useTableButtonRowOverlayState();
  const {
    onClick: onClickExport,
    refInput: refInputExport,
    refPopUp: refPopUpExport,
    open: openExport,
  } = useTableButtonRowOverlayState();
  function extractFields(data: Array<any>, model: Array<any>) {
    return data.map((item: any) => {
      let newItem: any = {};
      model.forEach(({ label, slug }) => {
        if (item.hasOwnProperty(slug)) {
          newItem[label] = item[slug];
        }
      });
      return newItem;
    });
  }
  const handleExport = () => {
    const dataTryied = extractFields(data, columns);
    // Convert JSON data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(dataTryied);
    // Calculate and set column widths
    worksheet["!cols"] = getColumnWidths(dataTryied);
    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, "liste.xlsx");
  };
  const getMaxLength = (data: any, key: any) => {
    return Math.max(
      ...data.map((item: any) => (item[key] ? item[key].toString().length : 0)),
      key.length
    );
  };

  const getColumnWidths = (jsonData: any) => {
    const keys = Object.keys(jsonData[0]);
    return keys.map((key) => ({ wch: getMaxLength(jsonData, key) + 10 }));
  };

  return (
    <React.Fragment>
      <div className="flex gap-4 px-[20px] pt-[18px] pb-[5px] flex-row w-full">
        <TableSearch
          onChange={onChange}
          placeHolder={
            columns.filter((e: any) => e.slug === selectedSearchIndex)?.[0]
              ?.label ?? ""
          }
        />
        <div className="flex flex-col relative">
          <TableButton
            refrence={refInputFilter}
            icon={<Filter size={18} />}
            title={"Filtrer"}
            onClick={() => {
              if (openExport) {
                onClickExport();
              }
              onClickFilter();
            }}
          />
          <div
            ref={refPopUpFilter}
            className="absolute select-none z-[700] flex border-[1px] p-1 gap-1 border-black/10 flex-col top-[45px] right-0 phone:right-0 w-[200px] overflow-y-auto rounded-[10px] bg-white overflow-hidden shadow-sm shadow-black/15 text-[16px]"
            style={{
              visibility: openFilter ? "visible" : "hidden",
            }}
          >
            {columns?.map((e: any, index: any) => {
              return (
                <div
                  key={index}
                  className={clsx(
                    "flex text-[15px] py-[6px] px-3 cursor-pointer rounded-[6px] hover:bg-black/10 active:bg-black/25",
                    selectedSearchIndex === e.slug
                      ? "text-primary font-medium bg-black/10"
                      : ""
                  )}
                  onClick={() => {
                    !!setIndex && setIndex(e.slug);
                    onClickFilter();
                  }}
                >
                  <span className="flex">{e.label}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col relative">
          <TableButton
            refrence={refInputExport}
            icon={<Download size={20} />}
            title={"Exporter la liste"}
            onClick={() => {
              if (openFilter) {
                onClickFilter();
              }
              onClickExport();
            }}
          />
          <div
            ref={refPopUpExport}
            className="absolute select-none z-[700] flex border-[1px] p-1 gap-1 border-black/10 flex-col top-[45px] right-0 phone:right-0 w-[165px] overflow-y-auto rounded-[10px] bg-white overflow-hidden shadow-sm shadow-black/15 text-[16px]"
            style={{
              visibility: openExport ? "visible" : "hidden",
            }}
          >
            <div
              className={clsx(
                "flex text-[15px] py-[6px] px-3 font-medium cursor-pointer rounded-[6px] hover:bg-black/10 active:bg-black/25"
              )}
              onClick={() => {
                if (openExport) {
                  onClickExport();
                }
                handleExport();
              }}
            >
              <span className="flex">En Excel</span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

type TableSearchProps = {
  placeHolder: String;
  onChange?: (str: string) => void;
};

const TableSearch = ({ placeHolder, onChange }: TableSearchProps) => {
  return (
    <div className="flex flex-col max-w-[300px]">
      <input
        type="text"
        placeholder={"Rechercher : " + placeHolder}
        className={`flex transition-all duration-150 outline-4 h-[40px] outline-primary px-4 text-[15px] min-w-0 w-full bg-slate-200 rounded-[8px]`}
        onChange={(e) => {
          !!onChange && onChange(e.currentTarget.value);
        }}
      />
    </div>
  );
};

type TableButtonProps = {
  refrence?: any;
  icon?: ReactNode;
  title?: string;
  onClick?: () => void;
};

const TableButton = ({ refrence, icon, title, onClick }: TableButtonProps) => {
  return (
    <div
      ref={refrence}
      onClick={onClick}
      className={clsx(
        "flex relative h-[40px] rounded-[8px] overflow-clip select-none transition-all duration-200 flex-row justify-center items-center px-3 py-2 gap-x-2 bg-black/10 cursor-pointer hover:bg-black/15 active:bg-black/25 "
      )}
    >
      {!!icon && (
        <div className="flex  rounded-[10px] items-center justify-center">
          {icon}
        </div>
      )}
      <span className="hidden min_pc_mini:flex text-[15px] font-medium">
        {title}
      </span>
    </div>
  );
};

export default TableSearchRow;
