import React from "react";
import { Link } from "react-router-dom";
import { Edit, PanelLeftOpen, Trash2 } from "lucide-react";
import clsx from "clsx";
import TableDataAutoList from "../../../../../../components/table";
import InterviewUpdateModal from "../../../../../../components/modals/models/interview_update";
import ConfirmActionModal from "../../../../../../components/modals/models/confirmation";
import { useAdminDataStore } from "../../../../../../configs/stores/admindataStore";
import moment from "moment";

type Props = {
  item: any;
};

const InterviewItem = ({ item }: Props) => {
  const { removeInterview } = useAdminDataStore();
  const [isOpen, setisOpen] = React.useState(false);
  function close() {
    setisOpen(false);
  }
  const [isOpenModify, setisOpenModify] = React.useState(false);
  function closeModify() {
    setisOpenModify(false);
  }
  async function confirm() {
    if (!item?.id) {
      return;
    }
    const res = await removeInterview(item.id);
    if (!res.success) {
      return null;
    }
    return res.data;
  }
  const columns = [
    {
      label: "Jour d'entretien",
      slug: "date",
    },
    {
      label: "Entretien validés",
      slug: "success",
    },
    {
      label: "Entretien refusés",
      slug: "reject",
    },
    {
      label: "Entretien en attentes",
      slug: "pending",
    },
  ];
  return (
    <React.Fragment>
      <div className="flex flex-col gap-2 bg-white rounded-[10px] w-full">
        <div className="flex flex-col gap-2 p-5 pb-0">
          <div className="flex flex-col">
            <span className="flex text-[14px]">Titre de l'entretien</span>
            <span className="flex text-[22px] font-medium">{item?.name}</span>
          </div>
          <InterviewItemInfo
            title="Questionnaire"
            value={item?.survey.name ?? "Non definie"}
          />
          <InterviewItemInfo
            title="Type d'entretien"
            value={item?.type ?? "Non definie"}
          />
          {item.type === "PARTICIPATION" && !!item?.mission?.name && (
            <InterviewItemInfo
              title="Mission Concernée"
              value={item?.mission?.name ?? "Non definie"}
            />
          )}
          <InterviewItemInfo
            title="Représentation Concernée"
            value={item?.mdvieoffice?.name ?? "Non definie"}
          />
          <div className="flex flex-row gap-4 pt-2">
            <Link
              to={item.id}
              className={clsx(
                "flex transition-all cursor-pointer flex-row gap-[8px] h-[40px] w-fit items-center rounded-[8px] px-4",
                "bg-slate-500/10 hover:bg-slate-500/20 active:bg-slate-500/40 "
              )}
            >
              <PanelLeftOpen size={18} />
              <span className="flex text-black/85 font-medium text-[15px]">
                Ouvrir
              </span>
            </Link>
            <div
              onClick={() => {
                setisOpenModify(true);
              }}
              className={clsx(
                "flex transition-all cursor-pointer flex-row gap-[8px] h-[40px] w-fit items-center rounded-[8px] px-4",
                "bg-slate-500/10 hover:bg-slate-500/20 active:bg-slate-500/40 "
              )}
            >
              <Edit size={18} />
              <span className="flex text-black/85 font-medium text-[15px]">
                Modifier
              </span>
            </div>
            <div
              onClick={() => {
                setisOpen(true);
              }}
              className={clsx(
                "flex transition-all cursor-pointer flex-row gap-[8px] h-[40px] w-fit items-center rounded-[8px] px-4",
                "bg-slate-500/10 hover:bg-slate-500/20 active:bg-slate-500/40 "
              )}
            >
              <Trash2 size={18} />
              <span className="flex text-black/85 font-medium text-[15px]">
                Supprimer
              </span>
            </div>
          </div>
        </div>
        <TableDataAutoList
          columns={columns}
          showSearchRow={false}
          rows={(item?.dates ?? []).map((e: any) => ({
            date: moment(e?.date).format("LL"),
            success:
              (e?.interviews?.success?.length ?? 0) +
              (e?.participations?.success?.length ?? 0),
            reject:
              (e?.interviews?.reject?.length ?? 0) +
              (e?.participations?.reject?.length ?? 0),
            pending:
              (e?.interviews?.pending?.length ?? 0) +
              (e?.participations?.pending?.length ?? 0),
            item: e,
          }))}
        />
      </div>
      <InterviewUpdateModal
        item={item}
        open={isOpenModify}
        handleClose={closeModify}
      />
      <ConfirmActionModal
        open={isOpen}
        message="Voulez-vous vraiment supprimer ?"
        confirm={confirm}
        handleClose={close}
      />
    </React.Fragment>
  );
};

type InfoItemProps = {
  title: string;
  value: string;
};

const InterviewItemInfo = ({ title, value }: InfoItemProps) => {
  return (
    <div className="flex flex-row gap-2 items-center">
      <span className="flex text-[14px] text-black/50">{title} :</span>
      <span className="flex text-[14px] line-clamp-1 overflow-clip">
        {value}
      </span>
    </div>
  );
};

export default InterviewItem;
