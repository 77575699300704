import React from "react";
import ModalCustom from "../..";
import TextInputForm from "../../../form_components/text_input";
import FormMessage from "../../../form_components/message";
import SubmitForm from "../../../form_components/submit";
import { v4 as uuIdv4 } from "uuid";
import { useAdminDataStore } from "../../../../configs/stores/admindataStore";

type Props = {
  handleClose: () => void;
  open: boolean;
};

const DepartmentNewModal = ({
  open = false,
  handleClose = () => {},
}: Props) => {
  const [formKey, setFormKey] = React.useState(uuIdv4());
  const { addDepartment } = useAdminDataStore();
  const [closable] = React.useState(true);
  const [isLoading, setisLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<{
    [x: string]: any;
  }>({
    name: null,
    general: null,
  });
  function checkForm(form: any) {
    let errorOccured = false;
    // name
    if (form.name.length < 2) {
      errorOccured = true;
      let msg = "Nom incorrecte";
      setErrors((e) => ({ ...e, name: msg }));
    } else {
      setErrors((e) => ({ ...e, name: null }));
    }
    return errorOccured;
  }
  function updateCheckForm(e: any) {
    e.preventDefault();
    const formInput = e.target;
    if (!formInput) {
      return;
    }
    if (!!errors.general) {
      setErrors((e) => ({ ...e, general: null }));
    }
    setErrors((e) => ({ ...e, [formInput.name]: null }));
  }
  function onSubmitForm(e: any) {
    e.preventDefault();
    const form = e.target.elements;
    if (errors.general !== null) {
      setErrors((e) => ({ ...e, general: null }));
    }
    if (form === null) {
      setErrors((e) => ({ ...e, general: "Renseigner le formulaire !" }));
      return;
    }
    const formValues = {
      name: form.name.value,
    };
    if (checkForm(formValues)) {
      console.log("Error Occured !!!");
      return;
    }
    setErrors((e) => ({
      ...e,
      general: "",
    }));
    setisLoading(true);
    addDepartment(formValues.name).then((res: any) => {
      setisLoading(false);
      if (res.success) {
        setFormKey(uuIdv4());
        handleClose();
      } else {
        setErrors((e) => ({
          ...e,
          general: res.message.toString(),
        }));
      }
    });
  }
  React.useEffect(() => {
    if (!open) {
      setFormKey(uuIdv4());
      setErrors({
        name: null,
        general: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  return (
    <ModalCustom open={open} handleClose={closable ? handleClose : undefined}>
      <div className="flex flex-col w-[350px] p-3 pt-0 space-y-4 items-start justify-center">
        <span className="flex text-[20px] font-medium">
          Nouveau departement
        </span>
        <form
          key={formKey}
          className="flex flex-col w-full gap-3"
          onSubmit={!isLoading ? onSubmitForm : undefined}
          onChange={updateCheckForm}
        >
          <TextInputForm
            title={"Nom du département"}
            name={"name"}
            error={!!errors.name}
          />
          {errors.general && (
            <FormMessage message={errors.general} styleMessage={"error"} />
          )}
          <SubmitForm isLoading={isLoading} title="Enregistrer" />
        </form>
      </div>
    </ModalCustom>
  );
};

export default DepartmentNewModal;
