import { useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";
import MissionUpdateModal from "../../../../../../../../components/modals/models/mission_update";
import clsx from "clsx";
import { Edit, Plus } from "lucide-react";
import LocalityNewModal from "../../../../../../../../components/modals/models/locality_new";

const WelcomeInfoScreen = () => {
  const { id } = useParams();
  const [isOpenNewLocality, setisOpenNewLocality] = React.useState(false);
  const [isOpenModify, setisOpenModify] = React.useState(false);
  const queryClient = useQueryClient();
  const { data: mission } = useQuery<any>({
    queryKey: ["missions", id],
  });
  const { data: localities = Array<any>() } = useQuery<any>({
    queryKey: ["missions", id, "localities"],
  });
  function closeModify() {
    setisOpenModify(false);
  }
  function closeNewLocality() {
    setisOpenNewLocality(false);
  }

  // Convertir les chaînes de caractères en objets Date
  const dateObjects = (
    localities
      ?.map((e: any) => [e.start, e.end])
      .reduce((prev: any, curr: any, index: any, arr: any) => {
        return prev.concat(curr);
      }, []) ?? []
  ).map((date: any) => new Date(date));

  // Trouver la plus petite et la plus grande date
  const minDate = new Date(Math.min(...dateObjects));
  const maxDate = new Date(Math.max(...dateObjects));
  return (
    <React.Fragment>
      <div className="flex select-none flex-col gap-4 p-5 bg-white rounded-[10px]">
        <div className="flex flex-col gap-1">
          <span className="flex text-[14px]">Mission</span>
          <span className="flex text-[26px]">{mission?.name}</span>
        </div>
        <InfoItem title="Pays cible" value={mission?.country.name} />
        <InfoItem title="Type de mission" value={mission?.type} />
        <InfoItem
          title="Départ des équipes"
          value={moment(mission?.teams_departure).format("LL")}
        />
        <InfoItem
          title="Retour des équipes"
          value={moment(mission?.teams_return).format("LL")}
        />
        <div className="flex flex-col gap-2 pl-[10px] border-l-[3px] border-primary">
          <InfoItem
            title="Démarage de la mission"
            value={
              !minDate.toString().toLowerCase().includes("invalid")
                ? moment(minDate).format("LL")
                : "Non définie"
            }
          />
          <InfoItem
            title="Fin de la mission"
            value={
              !maxDate.toString().toLowerCase().includes("invalid")
                ? moment(maxDate).format("LL")
                : "Non définie"
            }
          />
        </div>
        <div className="flex flex-row gap-4">
          <div
            onClick={() => {
              setisOpenModify(true);
            }}
            className={clsx(
              "flex transition-all cursor-pointer flex-row gap-[8px] h-[40px] w-fit items-center rounded-[8px] px-4",
              "bg-slate-500/10 hover:bg-slate-500/20 active:bg-slate-500/40 "
            )}
          >
            <Edit size={18} />
            <span className="flex text-black/85 font-medium text-[15px]">
              Modifier les informations de la mission
            </span>
          </div>
          <div
            onClick={() => {
              setisOpenNewLocality(true);
            }}
            className={clsx(
              "flex transition-all cursor-pointer flex-row gap-[8px] h-[40px] w-fit items-center rounded-[8px] px-4",
              "bg-slate-500/10 hover:bg-slate-500/20 active:bg-slate-500/40 "
            )}
          >
            <Plus size={18} />
            <span className="flex text-black/85 font-medium text-[15px]">
              Ajouter une ville
            </span>
          </div>
        </div>
      </div>
      {mission && (
        <MissionUpdateModal
          open={isOpenModify}
          item={mission}
          handleClose={closeModify}
          updateItem={(updated) => {
            if (updated?.[0]) {
              queryClient.setQueryData(["missions", id], updated[0]);
            }
          }}
        />
      )}
      {mission && (
        <LocalityNewModal
          mission={mission}
          open={isOpenNewLocality}
          handleClose={closeNewLocality}
          handleResult={(res) => {
            if (res.success) {
              queryClient.refetchQueries({
                queryKey: ["missions", id, "stats"],
              });
              queryClient.setQueryData(
                ["missions", id, "localities"],
                res.data
              );
            }
          }}
        />
      )}
    </React.Fragment>
  );
};

type InfoItemProps = {
  title: string;
  value: string;
};

const InfoItem = ({ title, value }: InfoItemProps) => {
  return (
    <div className="flex flex-row gap-5 items-center">
      <span className="flex text-[16px] text-black/50">{title} :</span>
      <span className="flex text-[16px]">{value}</span>
    </div>
  );
};

export default WelcomeInfoScreen;
