import React from "react";
import { useAuthStore } from "../../configs/stores/auth";
import SideBar from "./sidebar";
import clsx from "clsx";
import { Navigate, Outlet } from "react-router-dom";
import SideBarDrawer from "./sidebar/drawer";
import { useConfigManagingDataStore } from "../../configs/stores/configManagingStore";

const DashboardPage = () => {
  const { openSideBar } = useConfigManagingDataStore();
  const { isAuthenticated } = useAuthStore();
  if (!isAuthenticated) {
    return <Navigate to={"/"} />;
  }
  return (
    <div className="flex flex-1 h-full w-full flex-row">
      <div
        className={clsx(
          "flex h-full w-0 transition-all duration-300",
          "bg-sidebarBgColor overflow-x-clip border-t-[1px] border-white/30 overflow-clip",
          openSideBar ? "min_pc_mini:w-[250px]" : "min_pc_mini:w-[0px]"
        )}
      >
        <SideBar />
      </div>
      <div className="flex flex-1 h-full flex-col overflow-y-auto">
        <Outlet />
      </div>
      {window.innerWidth < 1025 && <SideBarDrawer />}
    </div>
  );
};

export default DashboardPage;
