import React from "react";
import ImageBg from "../../assets/backgroundImage.jpg";
import { isEmail, isPhoneNumber } from "../../constants";
import { useBasicsStore } from "../../configs/stores/basics";
import { useAuthStore } from "../../configs/stores/auth";
import { MissionModel, UserModel } from "../../models/models";
import TextInputForm from "../../components/form_components/text_input";
import SelectInputForm from "../../components/form_components/select_input";
import PhoneInputForm from "../../components/form_components/phone_input";
import EmailInputForm from "../../components/form_components/email_input";
import SubmitForm from "../../components/form_components/submit";
import FormMessage from "../../components/form_components/message";

const RegistrationPage = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [user, setUser] = React.useState<UserModel | null>(null);
  const [mission, setMission] = React.useState<MissionModel | null>(null);
  const { registration } = useAuthStore();
  const { missions, departments, mdvieOffices, countries } = useBasicsStore();
  const [errors, setErrors] = React.useState<{
    [x: string]: any;
  }>({
    lastname: null,
    firstname: null,
    country: null,
    representation: null,
    mission: null,
    department: null,
    phone: null,
    email: null,
    general: null,
  });
  function checkForm(form: any) {
    let errorOccured = false;
    // lastname
    if (form.lastname.length < 2) {
      errorOccured = true;
      setErrors((e) => ({ ...e, lastname: true }));
    } else {
      setErrors((e) => ({ ...e, lastname: null }));
    } // firstname
    if (form.firstname.length < 2) {
      errorOccured = true;
      setErrors((e) => ({ ...e, firstname: true }));
    } else {
      setErrors((e) => ({ ...e, firstname: null }));
    } // country
    if (form.country.length === 0) {
      errorOccured = true;
      setErrors((e) => ({ ...e, country: true }));
    } else {
      setErrors((e) => ({ ...e, country: null }));
    } // representation
    if (form.representation.length === 0) {
      errorOccured = true;
      setErrors((e) => ({ ...e, representation: true }));
    } else {
      setErrors((e) => ({ ...e, representation: null }));
    } // mission
    if (form.mission.length === 0) {
      errorOccured = true;
      setErrors((e) => ({ ...e, mission: true }));
    } else {
      setErrors((e) => ({ ...e, mission: null }));
    } // department
    if (form.department.length === 0) {
      errorOccured = true;
      setErrors((e) => ({ ...e, department: true }));
    } else {
      setErrors((e) => ({ ...e, department: null }));
    } // phone
    if (form.phone.length === 0 || !isPhoneNumber(form.phone)) {
      errorOccured = true;
      let msg = "Contact téléphonique incorrecte";
      setErrors((e) => ({ ...e, phone: msg }));
    } else {
      setErrors((e) => ({ ...e, phone: null }));
    } // email
    if (form.email.length === 0 || !isEmail(form.email)) {
      errorOccured = true;
      let msg = "Email incorrecte";
      setErrors((e) => ({ ...e, email: msg }));
    } else {
      setErrors((e) => ({ ...e, email: null }));
    }
    return errorOccured;
  }
  function updateCheckForm(e: any) {
    e.preventDefault();
    const formInput = e.target;
    if (!formInput) {
      return;
    }
    if (!!errors.general) {
      setErrors((e) => ({ ...e, general: null }));
    }
    setErrors((e) => ({ ...e, [formInput.name]: null }));
  }
  function onSubmitForm(e: any) {
    e.preventDefault();
    if (errors.general !== null) {
      setErrors((e) => ({ ...e, general: null }));
    }
    const form = e.target.elements;
    if (form === null) {
      setErrors((e) => ({ ...e, general: "Renseigner le formulaire !" }));
      return;
    }
    const formValues = {
      lastname: form.lastname.value,
      firstname: form.firstname.value,
      country: form.country.value,
      representation: form.representation.value,
      mission: form.mission.value,
      department: form.department.value,
      phone: form.phone.value,
      email: form.email.value,
    };
    if (checkForm(formValues)) {
      return;
    }
    function getValue(t: any) {
      let value = JSON.parse(t)?.value ?? "none";
      return value !== "none" ? value : null;
    }
    setIsLoading(true);
    const formDatas = {
      userform: {
        lastname: form.lastname.value,
        firstname: form.firstname.value,
        country: getValue(form.country.value),
        mdvieoffice: getValue(form.representation.value),
        department: getValue(form.department.value),
        phone: form.phone.value,
        email: form.email.value,
      },
      missionary: {
        mission: getValue(form.mission.value),
      },
    };
    registration({
      ...formDatas,
    }).then((res: any) => {
      if (res.success) {
        setUser(res.data.user);
        setMission(res.data.mission);
      }
      if (!res.success) {
        setErrors((e) => ({
          ...e,
          general: res.message.toString(),
        }));
      }
      setIsLoading(false);
    });
  }
  return (
    <div className="flex w-full flex-row">
      <div className="hidden min_pc_mini:flex flex-1 py-10">
        <div className="flex w-[85%] phone:w-[100%] mx-auto rounded-[8px] h-[600px] items-center justify-center bg-[#e2e8f0]">
          <img
            src={ImageBg}
            alt="campagne"
            className="flex w-full h-full my-auto select-none mx-auto object-contain rounded-[8px]"
          />
          {/* <div
          className="flex w-full h-[450px] mx-4 bg-[#e2e8f0] select-none object-contain rounded-[8px]"
        /> */}
        </div>
      </div>
      <div className="flex flex-1 h-auto flex-col min_pc_mini:py-10 py-10 phone:py-5">
        {!user ? (
          <div className="flex flex-col min_pc_mini:pt-7 gap-y-4 w-[80%] min_pc_mini:w-[100%] h-auto phone:w-[100%]">
            <div className="flex min_pc_mini:hidden flex-1 justify-center pb-0">
              {/* <div className="flex w-[100%] rounded-[8px] max-h-[600px] items-center justify-center bg-black">
              <img
                src={ImageBg}
                alt="campagne"
                className="flex w-full h-full my-auto select-none mx-auto object-contain rounded-[8px]"
              />
            </div> */}
            </div>
            <span className="flex text-[22px] font-bold">
              Bienvenue sur la plateforme des missions
            </span>
            <span className="flex text-[18px] font-semibold">
              Inscrivez vous pour participer aux missions d’évangélisations
            </span>
            <form
              className="flex flex-col gap-4"
              onSubmit={!isLoading ? onSubmitForm : undefined}
              onChange={updateCheckForm}
            >
              <div className="flex flex-col min_pc_mini:flex-row min_pc_mini:gap-6 gap-4">
                <TextInputForm
                  title={"Nom"}
                  name={"lastname"}
                  error={!!errors.lastname}
                />
                <TextInputForm
                  title={"Prénoms"}
                  name={"firstname"}
                  error={!!errors.firstname}
                />
              </div>
              <SelectInputForm
                title={"Pays"}
                name={"country"}
                error={!!errors.country}
                options={countries
                  .filter((e) => e.active)
                  .map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                onChange={(_) => {
                  setErrors((e) => ({ ...e, country: null }));
                }}
              />
              <SelectInputForm
                title={
                  "Représentation Messages de Vie à laquelle vous appartenez"
                }
                name={"representation"}
                error={!!errors.representation}
                options={mdvieOffices
                  .map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))
                  .concat({
                    value: "none",
                    label: "Aucune Représentation",
                  })}
                onChange={(_) => {
                  setErrors((e) => ({ ...e, representation: null }));
                }}
              />
              <SelectInputForm
                title={"À quelle mission souhaitez-vous participer ?"}
                name={"mission"}
                error={!!errors.mission}
                options={missions
                  .filter((e) => !e.closed)
                  .map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                onChange={(_) => {
                  setErrors((e) => ({ ...e, mission: null }));
                }}
              />
              <SelectInputForm
                title={"Département souhaité ?"}
                name={"department"}
                error={!!errors.department}
                options={departments.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                onChange={(_) => {
                  setErrors((e) => ({ ...e, department: null }));
                }}
              />
              <div className="flex flex-col min_pc_mini:flex-row min_pc_mini:gap-6 gap-4">
                <PhoneInputForm
                  title={"Contact Téléphonique"}
                  subTitle={"Préciser l’indicatif du pays (Ex: +000)"}
                  name={"phone"}
                  error={!!errors.phone}
                />
                <EmailInputForm title={"Email"} error={!!errors.email} />
              </div>
              {errors.general && (
                <div className="mt-2">
                  <FormMessage
                    message={errors.general}
                    styleMessage={"error"}
                  />
                </div>
              )}
              <SubmitForm isLoading={isLoading} title="Inscription" />
            </form>
            <div className="flex flex-col gap-1 my-2 items-center justify-center">
              <span className="flex font-medium text-[16px] ">
                Vous êtes un(e) missionnaire validé déja inscrit ?
              </span>
              <a
                href="/login"
                className="flex text-primary text-[16px] font-medium underline underline-offset-2 cursor-pointer"
              >
                Connectez-vous
              </a>
            </div>
          </div>
        ) : (
          <div className="flex flex-col min_pc_mini:pt-7 pb-20 gap-y-4 w-[80%] min_pc_mini:w-[100%] h-auto phone:w-[100%]">
            <div className="flex min_pc_mini:hidden flex-1 justify-center pb-5">
              <div className="flex w-[100%] rounded-[8px] max-h-[600px] items-center justify-center bg-black">
                <img
                  src={ImageBg}
                  alt="campagne"
                  className="flex w-full h-full my-auto select-none mx-auto object-contain rounded-[8px]"
                />
              </div>
            </div>
            <span className="flex text-[22px] font-bold text-green-500">
              Félicitation {user.firstname + " " + user.lastname}, inscription
              réussie !
            </span>
            <span className="flex text-[18px] font-semibold">
              Votre inscription a bien été effectuée avec succès et prise en
              compte. Vous serez contacté pour l’organisation d’un rendez-vous
              en vue de la confirmation de votre participation à la mission:
            </span>
            <span className="flex text-[20px] font-bold">{mission?.name}</span>
            <span className="flex text-[18px] font-semibold">Merci !</span>
            <a
              href="/registration"
              className="flex text-primary underline underline-offset-2 text-[12px]"
            >
              Actualiser la page
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default RegistrationPage;
