import React from "react";
import {
  ScreenHeaderActionItem,
  ScreenHeaderActionRefresh,
} from "../../../../../../../../components/screen_header";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import SelectInputForm from "../../../../../../../../components/form_components/select_input";
import { Plus } from "lucide-react";

const MissionariesScreenHeader = () => {
  const { id } = useParams();
  const { refetch, isRefetching, isLoading } = useQuery({
    queryKey: ["missions", id, "missionaries"],
  });
  return (
    <div className="flex flex-col bg-white rounded-[10px] px-5 py-5 gap-2">
      <div className="flex  items-center  pb-0 flex-row gap-4 justify-between">
        <span className="flex text-[16px] font-medium">
          0 Validés / 0 Demandes
        </span>
        <div className="flex flex-row gap-4">
          <ScreenHeaderActionRefresh
            onClick={refetch}
            isLoading={isRefetching || isLoading}
          />
        </div>
      </div>
      <div className="flex flex-row flex-wrap pt-0 gap-4">
        <div className="flex w-[230px]">
          <SelectInputForm
            height={40}
            placeholder={"Trier par: Validation"}
            name={"validation"}
            defaultValue="all"
            options={[
              {
                label: "Tous validé et non",
                value: "all",
              },
              {
                label: "Validés",
                value: "validated",
              },
              {
                label: "Non validés",
                value: "pending",
              },
            ]}
            onChange={(_) => {}}
          />
        </div>
        <div className="flex w-[230px]">
          <SelectInputForm
            height={40}
            placeholder={"Trier par: Ville"}
            name={"locality"}
            defaultValue="all"
            options={[
              {
                label: "Toutes les villes",
                value: "all",
              },
            ]}
            onChange={(_) => {}}
          />
        </div>
        {/* <ScreenHeaderActionItem
          isLoading={isRefetching || isLoading}
          title="Ajouter Missionnaire"
          icon={<Plus size={20} fill="black" />}
          // onClick={openCloseModal}
        /> */}
      </div>
    </div>
  );
};

export default MissionariesScreenHeader;
