import React from "react";
import ScreenHeader, {
  ScreenHeaderActionRefresh,
} from "../../../../../../../../../components/screen_header";
import { ArrowLeft } from "lucide-react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import SelectInputForm from "../../../../../../../../../components/form_components/select_input";
import InterviewDateSurvey from "./survey";
import InterviewDateMissionaries from "./linterview_list_context";
import { useAuthStore } from "../../../../../../../../../configs/stores/auth";
import { useAdminDataStore } from "../../../../../../../../../configs/stores/admindataStore";
import ScreenContentLayoutMenu from "../../../../../../../../../components/screen_content_layout/menu";

const InterviewScreenDate = () => {
  const { id, date_id } = useParams();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthStore();
  const { getInterviewDate, interviews } = useAdminDataStore();
  const interview: any = interviews.find((i: any) => i.id === id);
  const interviewDate: any = interview?.dates?.find(
    (d: any) => d.id === date_id
  );
  async function fetchData() {
    if (!date_id) {
      return;
    }
    const res = await getInterviewDate(date_id);
    if (!res.success) {
      return null;
    }
    return res.data;
  }
  const {
    isRefetching,
    data: date,
    refetch,
  } = useQuery({
    initialData: interviewDate,
    enabled: isAuthenticated,
    queryFn: fetchData,
    queryKey: ["interview", id, "dates", date_id],
    refetchInterval: 5 * 60 * 1000, // 5 minutes in milliseconds
  });
  const changeDateId = (newId: string) => {
    navigate(`../${newId}`);
    window.location.reload();
  };
  const menus = [
    {
      title: "Missionnaires",
      url: "",
    },
    {
      title: "Participant",
      url: "participants",
    },
  ];
  return (
    <React.Fragment>
      <div className={`flex flex-col gap-5 w-full`}>
        <ScreenHeader
          height={100}
          title={
            <div className="flex flex-row items-center gap-4">
              <Link
                to=".."
                className="flex transition-all p-2 cursor-pointer items-center justify-center rounded-full bg-black/5 hover:bg-black/10 active:bg-black/15"
              >
                <ArrowLeft size={20} />
              </Link>
              <div className="flex flex-col gap-2 select-none">
                <span className="flex text-[14px] text-primary">
                  Jour d'entretien
                </span>
                <div className="flex flex-row gap-4">
                  <div className="flex flex-col w-[250px]">
                    <SelectInputForm
                      key={
                        interview?.id ??
                        "0" + interview?.dates?.length?.toString() ??
                        ""
                      }
                      height={40}
                      defaultValue={date_id}
                      placeholder={
                        (interview?.dates ?? [])
                          .filter((e: any) => e.id === date_id)
                          .map((item: any) => ({
                            value: item.id,
                            label: moment(item.date).format("LL"),
                          }))?.[0]?.label ?? ""
                      }
                      options={(interview?.dates ?? []).map((item: any) => ({
                        value: item.id,
                        label: moment(item.date).format("LL"),
                      }))}
                      onChange={(_) => {
                        _?.value && changeDateId(_.value);
                      }}
                    />
                  </div>
                  <ScreenHeaderActionRefresh
                    onClick={() => refetch()}
                    isLoading={isRefetching}
                  />
                </div>
              </div>
            </div>
          }
        ></ScreenHeader>
        <div key={interview?.id} className="flex flex-row gap-5">
          <InterviewDateSurvey survey={interview?.survey} />
          <div className="flex flex-1 flex-col rounded-[10px]">
            <ScreenContentLayoutMenu menus={menus} />
            <InterviewDateMissionaries date={date} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InterviewScreenDate;
