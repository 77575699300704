import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useBasicsStore } from "../../../configs/stores/basics";

const InfoMissionaryPage = () => {
  const { getMissionary } = useBasicsStore();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id") ?? "";
  async function fetchMissionary() {
    if (!id) {
      return;
    }
    const res = await getMissionary(id);
    return res;
  }
  useQuery({
    enabled: !!id,
    queryFn: fetchMissionary,
    queryKey: ["missionary", id],
    staleTime: 7 * 60 * 1000, // 5 minutes in milliseconds
    refetchInterval: 7 * 60 * 1000, // 5 minutes in milliseconds
  });
  return (
    <div className="flex w-full flex-row">
      <div className="flex flex-1 h-auto items-center flex-col min_pc_mini:py-10 py-10 phone:py-5">
        {}
      </div>
    </div>
  );
};

export default InfoMissionaryPage;
