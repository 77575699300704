import React from "react";
import ModalCustom from "../..";
import FormMessage from "../../../form_components/message";
import SubmitForm from "../../../form_components/submit";
import { v4 as uuIdv4 } from "uuid";
import { useAdminDataStore } from "../../../../configs/stores/admindataStore";
import TextInputForm from "../../../form_components/text_input";
import SelectInputForm from "../../../form_components/select_input";
import PhoneInputForm from "../../../form_components/phone_input";
import EmailInputForm from "../../../form_components/email_input";
import { isEmail, isPhoneNumber } from "../../../../constants";

type Props = {
  handleClose: () => void;
  open: boolean;
};

const MissionnaireNewModal = ({
  open = false,
  handleClose = () => {},
}: Props) => {
  const [formKey, setFormKey] = React.useState(uuIdv4());
  const [haveOtherChurch, setHaveOtherChurch] = React.useState(false);
  const {
    churchs,
    departments,
    // mdvieOffices,
    countries,
    addUser,
  } = useAdminDataStore();
  const [closable] = React.useState(true);
  const [isLoading, setisLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<{
    [x: string]: any;
  }>({
    lastname: null,
    firstname: null,
    country: null,
    representation: null,
    church: null,
    department: null,
    phone: null,
    email: null,
    general: null,
  });
  function checkForm(form: any) {
    let errorOccured = false;
    // lastname
    if (form.lastname.length < 2) {
      errorOccured = true;
      setErrors((e) => ({ ...e, lastname: true }));
    } else {
      setErrors((e) => ({ ...e, lastname: null }));
    } // firstname
    if (form.firstname.length < 2) {
      errorOccured = true;
      setErrors((e) => ({ ...e, firstname: true }));
    } else {
      setErrors((e) => ({ ...e, firstname: null }));
    } // country
    if (form.country.length === 0) {
      errorOccured = true;
      setErrors((e) => ({ ...e, country: true }));
    } else {
      setErrors((e) => ({ ...e, country: null }));
    } // church
    if (form.church.length === 0) {
      errorOccured = true;
      setErrors((e) => ({ ...e, church: true }));
    } else {
      setErrors((e) => ({ ...e, church: null }));
    } // department
    if (form.department.length === 0) {
      errorOccured = true;
      setErrors((e) => ({ ...e, department: true }));
    } else {
      setErrors((e) => ({ ...e, department: null }));
    } // phone
    if (form.phone.length === 0 || !isPhoneNumber(form.phone)) {
      errorOccured = true;
      let msg = "Contact téléphonique incorrecte";
      setErrors((e) => ({ ...e, phone: msg }));
    } else {
      setErrors((e) => ({ ...e, phone: null }));
    } // email
    if (form.email.length === 0 || !isEmail(form.email)) {
      errorOccured = true;
      let msg = "Email incorrecte";
      setErrors((e) => ({ ...e, email: msg }));
    } else {
      setErrors((e) => ({ ...e, email: null }));
    }
    return errorOccured;
  }
  function updateCheckForm(e: any) {
    e.preventDefault();
    const formInput = e.target;
    if (!formInput) {
      return;
    }
    if (!!errors.general) {
      setErrors((e) => ({ ...e, general: null }));
    }
    setErrors((e) => ({ ...e, [formInput.name]: null }));
  }
  function onSubmitForm(e: any) {
    e.preventDefault();
    if (errors.general !== null) {
      setErrors((e) => ({ ...e, general: null }));
    }
    const form = e.target.elements;
    if (form === null) {
      setErrors((e) => ({ ...e, general: "Renseigner le formulaire !" }));
      return;
    }
    const formValues = {
      lastname: form.lastname.value,
      firstname: form.firstname.value,
      country: form.country.value,
      department: form.department.value,
      otherchurch: haveOtherChurch ? form.otherchurch.value : null,
      church: form.church.value,
      phone: form.phone.value,
      email: form.email.value,
    };
    if (checkForm(formValues)) {
      return;
    }
    function getValue(t: any) {
      let value = JSON.parse(t)?.value ?? "none";
      return value !== "none" ? value : null;
    }
    const formDatas = {
      lastname: form.lastname.value,
      firstname: form.firstname.value,
      church: !haveOtherChurch ? getValue(form.church.value) : null,
      otherchurch: haveOtherChurch ? form.otherchurch.value : "",
      country: getValue(form.country.value),
      department: getValue(form.department.value),
      phone: form.phone.value,
      email: form.email.value,
    };
    setisLoading(true);
    addUser(formDatas).then((res: any) => {
      setisLoading(false);
      if (res.success) {
        setFormKey(uuIdv4());
        setErrors({
          general: null,
        });
        handleClose();
      } else {
        setErrors((e) => ({
          ...e,
          general: res.message.toString(),
        }));
      }
    });
  }
  React.useEffect(() => {
    if (!open) {
      setFormKey(uuIdv4());
      setErrors({
        general: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  const egliseCentre = churchs.filter((e) =>
    e.name.toLowerCase().startsWith("c")
  );
  return (
    <ModalCustom open={open} handleClose={closable ? handleClose : undefined}>
      <div className="flex flex-col w-[450px] p-3 pr-1 pt-0 space-y-2 items-start justify-center">
        <span className="flex text-[20px] font-medium">
          Ajouter un utilisateur
        </span>
        <form
          key={formKey}
          className="flex flex-col w-full px-1 gap-2 max-h-[80vh] pr-3 overflow-y-auto"
          onSubmit={!isLoading ? onSubmitForm : undefined}
          onChange={updateCheckForm}
        >
          <TextInputForm
            title={"Nom"}
            name={"lastname"}
            error={!!errors.lastname}
          />
          <TextInputForm
            title={"Prénoms"}
            name={"firstname"}
            error={!!errors.firstname}
          />
          <SelectInputForm
            title={"Pays"}
            name={"country"}
            error={!!errors.country}
            options={countries
              .filter((e) => e.active)
              .map((item) => ({
                value: item.id,
                label: item.name,
              }))}
            onChange={(_) => {
              setErrors((e) => ({ ...e, country: null }));
            }}
          />
          <SelectInputForm
            title={"Église"}
            name={"church"}
            error={!!errors.church}
            options={egliseCentre
              .concat(
                churchs.filter(
                  (e) =>
                    !egliseCentre
                      .map((i) => i.id.toString())
                      .includes(e.id.toString())
                )
              )
              .map((item) => ({
                value: item.id,
                label: item.name,
              }))
              .concat({
                value: "otherchurch",
                label: "Autre Église",
              })}
            onChange={(_) => {
              if (_) {
                const v = _.value;
                if (v === "otherchurch") {
                  setHaveOtherChurch(true);
                } else {
                  if (haveOtherChurch) {
                    setHaveOtherChurch(false);
                  }
                }
              }
              setErrors((e) => ({ ...e, church: null }));
            }}
          />
          {haveOtherChurch && (
            <TextInputForm
              key={"otherchurch"}
              title={"Préciser le nom de l'église"}
              name={"otherchurch"}
              error={!!errors.otherchurch}
            />
          )}
          <SelectInputForm
            title={"Département"}
            name={"department"}
            error={!!errors.department}
            options={departments.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            onChange={(_) => {
              setErrors((e) => ({ ...e, department: null }));
            }}
          />
          <PhoneInputForm
            title={"Contact Téléphonique"}
            subTitle={"Préciser l’indicatif du pays (Ex: +000)"}
            name={"phone"}
            error={!!errors.phone}
          />
          <EmailInputForm title={"Email"} error={!!errors.email} />
          {errors.general && (
            <div className="mt-2">
              <FormMessage message={errors.general} styleMessage={"error"} />
            </div>
          )}
          <div className="flex flex-col w-full h-[70px]">
            <SubmitForm isLoading={isLoading} title="Enregistrer" />
          </div>
        </form>
      </div>
    </ModalCustom>
  );
};

export default MissionnaireNewModal;
