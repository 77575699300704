import React from "react";
import ScreenHeader from "../../../../../components/screen_header";
import ScreenContentLayout from "../../../../../components/screen_content_layout";
import { Outlet } from "react-router-dom";
import { useAdminDataStore } from "../../../../../configs/stores/admindataStore";
import { useQuery } from "@tanstack/react-query";
import { useAuthStore } from "../../../../../configs/stores/auth";

const DashInterviewsScreen = () => {
  const { isAuthenticated } = useAuthStore();
  const { refetchInterviews, refetchSurveys } = useAdminDataStore();
  useQuery({
    enabled: isAuthenticated,
    queryFn: refetchInterviews,
    queryKey: ["interviews"],
    staleTime: 2 * 60 * 1000, // 5 minutes in milliseconds
    refetchInterval: 2 * 60 * 1000, // 5 minutes in milliseconds
  });
  useQuery({
    enabled: isAuthenticated,
    queryFn: refetchSurveys,
    queryKey: ["surveys"],
    staleTime: 2 * 60 * 1000, // 5 minutes in milliseconds
    refetchInterval: 2 * 60 * 1000, // 5 minutes in milliseconds
  });
  return (
    <React.Fragment>
      <div className="flex flex-1 flex-col h-full w-full">
        <ScreenHeader title="Entretiens"></ScreenHeader>
        <ScreenContentLayout>
          <Outlet />
        </ScreenContentLayout>
      </div>
    </React.Fragment>
  );
};

export default DashInterviewsScreen;
