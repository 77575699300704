import React from "react";
import ListItems from "./list_items";
import { ScreenHeaderActionRefresh } from "../../../../components/screen_header";
import { useDataStore } from "../../../../configs/stores/dataStore";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { useAuthStore } from "../../../../configs/stores/auth";

const DashMyMissionsScreenDefault = () => {
  const { isAuthenticated } = useAuthStore();
  const { missions, interviews, refetchMissions, isRefetchingMissions } =
    useDataStore();
  const interviewsSavedIds = interviews.map((e) => e.mission.id);
  const nowMissions = missions.filter((m) => {
    if (interviewsSavedIds.includes(m.id)) {
      return false;
    }
    const isCurrentMission = m.localities.filter(
      (l: any) => moment(l.start).valueOf() - moment().valueOf() > 0
    );
    const diff = moment().valueOf() - moment(m.teams_departure).valueOf();
    const diff2 = moment(m.teams_return).valueOf() - moment().valueOf();
    if (diff > 0 && diff2 > 0 && isCurrentMission?.length > 0) {
      return true;
    }
    return false;
  });
  const disponibleMissions = missions.filter((m) => {
    if (interviewsSavedIds.includes(m.id)) {
      return false;
    }
    if (m.disponible) {
      return false;
    }
    const diff = moment(m.teams_departure).valueOf() - moment().valueOf();
    if (diff > 0) {
      return true;
    }
    return false;
  });
  // const nextMissions = missions.filter((m) => {
  //   const diff = moment(m.teams_departure).valueOf() - moment().valueOf();
  //   if (diff > 0) {
  //     return true;
  //   }
  //   return false;
  // });
  useQuery({
    enabled: isAuthenticated,
    queryFn: refetchMissions,
    queryKey: ["mymissions"],
    staleTime: 10 * 60 * 1000, // 5 minutes in milliseconds
    refetchInterval: 10 * 60 * 1000, // 5 minutes in milliseconds
  });
  return (
    <div className="flex flex-1 flex-col gap-4 h-full w-full">
      <div className="flex flex-col max-w-[300px]">
        <ScreenHeaderActionRefresh
          onClick={refetchMissions}
          isLoading={isRefetchingMissions}
          title="Rafraichir les missions"
        />
      </div>
      <div className="flex flex-1 flex-col gap-8">
        {nowMissions.length !== 0 && (
          <ListItems
            key={"Missions en cours"}
            title="Missions en cours"
            list={nowMissions}
            state="now"
          />
        )}
        {disponibleMissions.length !== 0 && (
          <ListItems
            key={"Missions Disponible"}
            title="Missions Disponibles"
            list={disponibleMissions}
            state="disponible"
          />
        )}
        {/* {nextMissions.length !== 0 && (
          <ListItems
            key={"Prochaines Missions"}
            title="Prochaines Missions"
            list={nextMissions}
            state="next"
          />
        )} */}
      </div>
    </div>
  );
};

export default DashMyMissionsScreenDefault;
