import React from "react";
import { useOutletContext } from "react-router-dom";
import SearchInputForm from "../../../../../../../../../../components/form_components/search_input";
import ScreenViewLayout from "../../../../../../../../../../components/scroll_view";
import { ArrowLeft } from "lucide-react";
import DateInterviewsItem from "../item";

type ContextType = {
  date: any;
};

type Props = {
  switchOnClick: () => void;
};

const DateMissionnariesInterviewedList = ({ switchOnClick }: Props) => {
  const { date } = useOutletContext<ContextType>();
  const interview: any = date?.interview;
  const interviews: any = date?.interviews;
  return (
    <div className="flex flex-1 flex-col bg-white p-5 gap-4 rounded-[10px]">
      <div className="flex flex-row text-[16px] duration-150 transition-all cursor-pointer font-semibold rounded-[10px] items-center gap-3">
        <div
          onClick={switchOnClick}
          className="flex flex-row text-[16px] duration-150 transition-all cursor-pointer font-semibold rounded-[10px] bg-black/10 hover:bg-black/15 active:bg-slate-200 items-center gap-3 p-3"
        >
          <ArrowLeft color={"black"} />
        </div>
        <span className="flex">Entretenus</span>
        <div className="flex flex-1" />
        <span className="flex">
          {(interviews?.success?.length ?? 0) +
            (interviews?.reject?.length ?? 0)}
        </span>
      </div>
      <SearchInputForm maxWidth={"auto"} placeHolder={"Nom du missionnaire"} />
      <ScreenViewLayout
        axis={"col"}
        backgroundColor={"#fff"}
        maxHeight={"calc(100vh-300px)"}
        minHeight={400}
      >
        <div className="flex flex-col gap-4">
          {(interviews?.success ?? [])
            .concat(interviews?.reject ?? [])
            .map((item: any, index: any) => {
              return (
                <div key={item.id} className="flex flex-row gap-3">
                  <div className="flex items-center mt-1 justify-center h-[30px] w-[30px] border border-black rounded-[50%]">
                    <span className="flex text-[14px]">{index + 1}</span>
                  </div>
                  <DateInterviewsItem
                    key={index}
                    interviewType={interview?.type ?? ""}
                    item={item}
                  />
                </div>
              );
            })}
        </div>
      </ScreenViewLayout>
    </div>
  );
};

export default DateMissionnariesInterviewedList;
