import React from "react";
import ScreenContentLayout from "../../../../components/screen_content_layout";
import ProfilUpdateUserForm from "./updateUser";
const DashProfilScreenDefault = () => {
  return (
    <div className="flex flex-1 flex-col h-full w-full">
      <ScreenContentLayout>
        <ProfilUpdateUserForm />
      </ScreenContentLayout>
    </div>
  );
};

export default DashProfilScreenDefault;
