import React from "react";
import ScreenHeader, {
  ScreenHeaderActionItem,
  ScreenHeaderActionRefresh,
} from "../../../../../components/screen_header";
import ScreenContentLayout from "../../../../../components/screen_content_layout";
import { Plus } from "lucide-react";
import DepartmentNewModal from "../../../../../components/modals/models/department_new";
import TableDataAutoList from "../../../../../components/table";
import { useAdminDataStore } from "../../../../../configs/stores/admindataStore";
import { useQuery } from "@tanstack/react-query";
import { useAuthStore } from "../../../../../configs/stores/auth";
import DepartmentActions from "./actions";

const DashDepartmentsScreen = () => {
  const { isAuthenticated } = useAuthStore();
  const [isOpen, setisOpen] = React.useState(false);
  function openCloseModal() {
    setisOpen(!isOpen);
  }
  const { departments, isRefetchingDepartments, refetchDepartments } =
    useAdminDataStore();
  useQuery({
    enabled: isAuthenticated,
    queryFn: refetchDepartments,
    queryKey: ["departements"],
    staleTime: 7 * 60 * 1000, // 5 minutes in milliseconds
    refetchInterval: 10 * 60 * 1000, // 5 minutes in milliseconds
  });
  const columns = [
    {
      label: "Nom du département",
      slug: "name",
    },
  ];
  return (
    <React.Fragment>
      <div className="flex flex-1 flex-col h-full w-full">
        <ScreenHeader title="Départements">
          <ScreenHeaderActionItem
            isLoading={isRefetchingDepartments}
            title="Ajouter"
            icon={<Plus size={20} fill="black" />}
            onClick={openCloseModal}
          />
          <ScreenHeaderActionRefresh
            onClick={refetchDepartments}
            isLoading={isRefetchingDepartments}
          />
        </ScreenHeader>
        <ScreenContentLayout>
          <TableDataAutoList
            columns={columns}
            rows={departments}
            actions={DepartmentActions}
          />
        </ScreenContentLayout>
      </div>
      <DepartmentNewModal open={isOpen} handleClose={openCloseModal} />
    </React.Fragment>
  );
};

export default DashDepartmentsScreen;
