import React from "react";
import { CircleUserRound } from "lucide-react";

const ProfilUpdateUserProfil = () => {
  return (
    <div className="flex flex-col w-[250px] h-[200px] items-center justify-center rounded-[15px] p-5 bg-slate-200">
      <CircleUserRound size={50} />
    </div>
  );
};

export default ProfilUpdateUserProfil;
