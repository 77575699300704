type PropsCard = {
  value: string;
  text: string;
  suffix?: string;
  motion?: string;
};

function StatCard({ text = "", value = "0", suffix, motion }: PropsCard) {
  return (
    <div className="flex flex-col p-4 pb-3 pt-0 w-[230px] h-[200px] rounded-[10px] bg-white">
      <div className="flex flex-col h-[80%] relative justify-center items-center">
        {motion && (
          <div className="flex top-0 absolute justify-center items-center">
            <span className="flex pt-4 leading-none text-[16px] text-black/90 font-medium">
              {motion}
            </span>
          </div>
        )}
        <div className="flex h-full w-fit pt-4 justify-center items-center">
          <span className="flex text-[50px] text-black font-medium">
            {value}
          </span>
        </div>
        {suffix && (
          <div className="flex bottom-0 absolute justify-center items-center">
            <span className="flex pb-2 leading-none text-[15px] text-black/90">
              {suffix}
            </span>
          </div>
        )}
      </div>
      <div className="flex flex-1 pt-2 mx-2 justify-center items-center border-t border-black/20">
        <span className="flex text-[15px] text-black font-medium">{text}</span>
      </div>
    </div>
  );
}

export default StatCard;
