import React from "react";
import DateMissionnariesPendingList from "./pending_list";
import DateMissionnariesInterviewedList from "./interviewed_list";

type Props = {
  date?: any;
};

const InterviewDateMissionariesPanel = ({ date }: Props) => {
  const [showInterviwedList, setshowInterviwedList] = React.useState(false);
  return (
    <React.Fragment>
      {showInterviwedList ? (
        <DateMissionnariesInterviewedList
          switchOnClick={() => {
            setshowInterviwedList(false);
          }}
        />
      ) : (
        <DateMissionnariesPendingList
          switchOnClick={() => {
            setshowInterviwedList(true);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default InterviewDateMissionariesPanel;
