import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useAuthStore } from "../../../../../../../../configs/stores/auth";
import { Link, Navigate, useParams } from "react-router-dom";
import { useAdminDataStore } from "../../../../../../../../configs/stores/admindataStore";
import { ArrowLeft } from "lucide-react";
import ScreenHeader from "../../../../../../../../components/screen_header";
import ScreenContentLayout from "../../../../../../../../components/screen_content_layout";
import SurveyScreenDefaultHeader from "./header";
import SurveyQuestion from "./question";

const SurveyScreenSurvey = () => {
  const { id } = useParams();
  const { isAuthenticated } = useAuthStore();
  const { getSurvey } = useAdminDataStore();
  const [searchQuestion, setSearchQuestion] = React.useState("");
  async function fetchData() {
    if (!id) {
      return;
    }
    const res = await getSurvey(id);
    if (!res.success) {
      return null;
    }
    return res.data;
  }
  const {
    isLoading,
    data: survey,
    refetch,
  } = useQuery({
    enabled: isAuthenticated,
    queryFn: fetchData,
    queryKey: ["surveys", id],
    staleTime: 5 * 60 * 1000, // 5 minutes in milliseconds
    refetchInterval: 5 * 60 * 1000, // 5 minutes in milliseconds
  });
  React.useEffect(() => {
    if (!survey) {
      refetch();
    }
  }, [refetch, survey]);
  if (!id) {
    return <Navigate to={".."} />;
  }
  return (
    <React.Fragment>
      <div className="flex flex-col flex-1 h-full">
        <div className="flex flex-1 flex-col h-full w-full">
          <ScreenHeader
            title={
              <div className="flex flex-row items-center gap-4">
                <Link
                  to=".."
                  className="flex transition-all p-2 cursor-pointer items-center justify-center rounded-full bg-black/5 hover:bg-black/10 active:bg-black/15"
                >
                  <ArrowLeft size={20} />
                </Link>
                <div className="flex flex-col select-none">
                  <span className="flex text-[12px]">Questionnaires</span>
                  {isLoading && (
                    <span className="flex text-[18px]">
                      Chargement du questionnaire...
                    </span>
                  )}
                  {!isLoading &&
                    (!!survey ? (
                      <span className="flex text-[18px]">{survey.name}</span>
                    ) : (
                      <div className="flex text-red-500 text-[16px]">
                        Questionnaire introuvable !
                      </div>
                    ))}
                </div>
              </div>
            }
          ></ScreenHeader>
          <ScreenContentLayout>
            <SurveyScreenDefaultHeader
              onSearchText={(e) => setSearchQuestion(e)}
            />
            <div className="flex flex-col gap-4 pt-5">
              {survey?.questions
                .filter((f: any) => f?.question.includes(searchQuestion))
                .sort((a: any, b: any) => {
                  return (a?.index ?? 0) - (b?.index ?? 0);
                })
                .map((e: any, index: any) => {
                  return <SurveyQuestion key={e?.id ?? index} item={e} />;
                })}
            </div>
          </ScreenContentLayout>
        </div>
        {isLoading && (
          <div className="absolute flex h-full w-full justify-center items-center backdrop-filter backdrop-blur-sm bg-black/20">
            <div className="flex flex-col p-5 items-center gap-3 max-w-[300px] cursor-default shadow-md select-none bg-white rounded-[10px]">
              <span className="flex text-[14px] text-center">
                Patientez un instant !
              </span>
              <span className="flex text-[18px] text-center">
                Chargement des données du questionnaire en cours...
              </span>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default SurveyScreenSurvey;
