import React from "react";
import { Outlet } from "react-router-dom";

type Props = {
  date?: any;
};

const InterviewDateMissionaries = ({ date }: Props) => {
  // console.log(date)
  return (
    <React.Fragment>
      <Outlet context={{ date }} />
    </React.Fragment>
  );
};

export default InterviewDateMissionaries;
