import clsx from "clsx";

type PropsCard = {
  icon?: any;
  text: string;
  height?: number;
  width?: number | string;
  centerText?: boolean;
  color?: "primary" | "secondary" | "tertiary";
  onClick?: () => void;
};

function ButtonClassic({
  text = "",
  color = "tertiary",
  height = 40,
  width,
  centerText = false,
  icon,
  onClick,
}: PropsCard) {
  function getColorSwitchable(color: string) {
    switch (color) {
      case "primary":
        return "bg-primary active:bg-active hover:bg-hover text-white";
      case "secondary":
        return "bg-secondary active:bg-secondaryactive hover:bg-secondaryhover text-white";
      case "tertiary":
        return "bg-tertiary active:bg-tertiaryactive hover:bg-tertiaryhover text-black";
    }
  }
  const Icon = icon;
  return (
    <button
      onClick={onClick}
      className={clsx(
        "flex select-none flex-row gap-3 p-4 w-fit items-center rounded-[10px] transition-all duration-150",
        getColorSwitchable(color)
      )}
      style={{
        height,
        width,
      }}
    >
      {icon && <div className="flex">{<Icon size={18} />}</div>}
      <div className="flex flex-1 justify-center items-center">
        <span className="flex text-[15px] font-semibold">{text}</span>
      </div>
    </button>
  );
}

export default ButtonClassic;
