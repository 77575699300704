import React from "react";
import { ScreenHeaderActionRefresh } from "../../../../../../../components/screen_header";
import ListItems from "./list_items";
import { ChartLine, Send } from "lucide-react";
import StatCard from "../../../../../../../components/stat_card";
import ScreenContentLayout from "../../../../../../../components/screen_content_layout";
import ButtonClassic from "../../../../../../../components/button";
import { useNavigate } from "react-router-dom";

const DashNotificationsScreenSmsDefault = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="flex flex-1 flex-col h-full w-full">
        <ScreenContentLayout>
          <div className="flex flex-1 flex-col gap-8 h-full w-full">
            <div className="flex flex-row gap-4">
              <div className="flex flex-col max-w-[300px]">
                <ScreenHeaderActionRefresh
                  // onClick={refetchMissions}
                  // isLoading={isRefetchingMissions}
                  title="Rafraichir les sms envoyés"
                />
              </div>
              <ButtonClassic
                color="primary"
                icon={Send}
                text={"Envoyer une Notif. SMS"}
                onClick={() => {
                  navigate("./new");
                }}
              />
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex flex-row gap-3">
                <ChartLine size={24} color="black" />
                <span className="flex text-[18px] text-black font-medium">
                  Statistiques
                </span>
              </div>
              <div className="flex flex-row gap-4 flex-wrap">
                <StatCard
                  text="Toutes les Notif. SMS"
                  value="0"
                  motion="Toutes les Notif."
                />
                <StatCard
                  text="Notif. Sms envoyées"
                  value="0"
                  motion="Sept 2024"
                  suffix="Ce mois"
                />
                <StatCard
                  text="Sms Distribué"
                  value="0"
                  motion="Sept 2024"
                  suffix="Ce mois"
                />
              </div>
            </div>
            <div className="flex flex-1 flex-col gap-8">
              <ListItems
                title="Toutes les notifications SMS envoyés"
                // list={missions}
                state="next"
              />
            </div>
          </div>
        </ScreenContentLayout>
      </div>
    </React.Fragment>
  );
};

export default DashNotificationsScreenSmsDefault;
