type PropsCard = {
  value: string;
  text: string;
};

function DashWelcomeScreenCard({ text = "", value = "0" }: PropsCard) {
  return (
    <div className="flex flex-col p-4 w-[230px] h-[200px] rounded-[10px] bg-white">
      <div className="flex h-[80%] justify-center items-center">
        <span className="flex text-[50px] text-black font-medium">{value}</span>
      </div>
      <div className="flex flex-1 pt-2 mx-2 justify-center items-center border-t border-black/20">
        <span className="flex text-[15px] text-black font-medium">{text}</span>
      </div>
    </div>
  );
}

export default DashWelcomeScreenCard;
