import React from "react";
import ScreenHeader from "../../../../components/screen_header";
import ScreenContentLayout from "../../../../components/screen_content_layout";
import SelectInputForm from "../../../../components/form_components/select_input";

const DashSettingsScreen = () => {
  return (
    <div className="flex flex-1 flex-col h-full w-full">
      <ScreenHeader
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="flex"
          >
            <path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z" />
            <circle cx="12" cy="12" r="3" />
          </svg>
        }
        title="Paramètres"
      />
      <ScreenContentLayout>
        <div className="flex flex-col max-w-[400px] bg-white p-5 rounded-[10px]">
          <SelectInputForm
            height={45}
            title={"Langue"}
            name={"lang"}
            defaultValue="fr"
            options={[
              {
                value: "fr",
                label: "Français",
              },
            ]}
          />
        </div>
      </ScreenContentLayout>
    </div>
  );
};

export default DashSettingsScreen;
