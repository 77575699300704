import React from "react";
import ScreenHeader, {
  ScreenHeaderActionItem,
  ScreenHeaderActionRefresh,
} from "../../../../components/screen_header";
import ScreenContentLayout from "../../../../components/screen_content_layout";
import { Plus } from "lucide-react";

const DashWalletScreen = () => {
  const [isOpen, setisOpen] = React.useState(false);
  function openCloseModal() {
    setisOpen(!isOpen);
  }
  return (
    <div className="flex flex-1 flex-col h-full w-full">
      <ScreenHeader title="Mon épargne">
        <ScreenHeaderActionItem
          // isLoading={isRefetchingChurchs}
          title="Faire un dépot"
          icon={<Plus size={20} fill="black" />}
          onClick={openCloseModal}
        />
        <ScreenHeaderActionRefresh
          // onClick={refetchChurchs}
          // isLoading={isRefetchingChurchs}
        />
      </ScreenHeader>
      <ScreenContentLayout>
      </ScreenContentLayout>
    </div>
  );
};

export default DashWalletScreen;
