import React from "react";
import ShowDemandeModal from "../../../../../../../../../components/modals/models/show_demande";
import ConfirmActionModal from "../../../../../../../../../components/modals/models/confirmation";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useAdminDataStore } from "../../../../../../../../../configs/stores/admindataStore";

type Props = {
  interviewType: string;
  item: any;
};

const DateInterviewsItem = ({ interviewType, item }: Props) => {
  const { id, date_id } = useParams();
  const queryClient = useQueryClient();
  const { setUserInterviewDateValidation } = useAdminDataStore();
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const user = item.user;
  function confirm() {
    if (!date_id) {
      return;
    }
    return setUserInterviewDateValidation(date_id, {
      id: item.id,
      status: "pending",
    }).then((res) => {
      if (res.success) {
        queryClient.setQueryData(["interview", id, "dates", date_id], res.data);
      }
      setOpenDelete(false);
    });
  }
  return (
    <React.Fragment>
      <div className="flex flex-col flex-1 gap-[4px] p-4 py-3 bg-black/10 rounded-[10px]">
        <span className="flex text-[15px] leading-tight">
          {user.lastname + " " + user.firstname}
        </span>
        {item.status === "pending" ? (
          <button
            onClick={() => setOpen(true)}
            className="flex text-[13px] underline underline-offset-2 text-primary active:text-active hover:text-hover"
          >
            Afficher la demande
          </button>
        ) : (
          <button
            onClick={() => setOpenDelete(true)}
            className="flex text-[13px] underline underline-offset-2 text-primary active:text-active hover:text-hover"
          >
            Annuler la validation
          </button>
        )}
      </div>
      {item.status === "pending" && (
        <ShowDemandeModal
          handleClose={() => setOpen(false)}
          open={open}
          item={item}
        />
      )}
      {item.status !== "pending" && (
        <ConfirmActionModal
          handleClose={() => setOpenDelete(false)}
          open={openDelete}
          message="Annuler la validation de l'entretien"
          confirm={confirm}
        />
      )}
    </React.Fragment>
  );
};

export default DateInterviewsItem;
