import clsx from "clsx";
import React from "react";
import { NavLink } from "react-router-dom";

const SideBarBottom = () => {
  return (
    <div className="flex flex-col p-4 pb-3 border-t-[1px] gap-[10px] border-white/30">
      <NavLink
        to={"settings"}
        end={false}
        className={({ isActive }) => {
          return clsx(
            "flex flex-row gap-[8px] h-[40px] w-full items-center justify-center rounded-[8px]  border-[1px] border-white/30",
            isActive
              ? "bg-slate-500/30"
              : "hover:bg-slate-500/20 active:bg-slate-500/40 "
          );
        }}
      >
        {({ isActive }) => (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="flex"
            >
              <path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z" />
              <circle cx="12" cy="12" r="3" />
            </svg>
            <span className="flex text-white font-medium text-[14px]">
              Paramètres
            </span>
          </>
        )}
      </NavLink>
      <div className="flex flex-row items-center justify-center gap-x-3">
        <a
          href="/help"
          className="font-sans text-white/80 underline underline-offset-2 text-[12px]"
        >
          Aide
        </a>
        <span className="flex text-[12px] text-white/70">&</span>
        <a
          href="/contact"
          className="font-sans text-white/80 underline underline-offset-2 text-[12px]"
        >
          Contact
        </a>
      </div>
      <div className="flex flex-row items-center justify-center gap-x-1">
        <span className="font-sans text-white/80 text-[11px]">
          Développé par
        </span>
        <a
          href="https://www.awanze.com"
          target="_blank"
          className="font-sans text-white/80 underline underline-offset-2 text-[11px]"
          rel="noreferrer"
        >
          Awanze
        </a>
      </div>
    </div>
  );
};

export default SideBarBottom;
