import React from "react";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { Edit, Plus, Trash2 } from "lucide-react";
import clsx from "clsx";
import TableDataAutoList from "../../../../../../../../../components/table";
import LocalityUpdateModal from "../../../../../../../../../components/modals/models/locality_update";
import ConfirmActionModal from "../../../../../../../../../components/modals/models/confirmation";
import { useAdminDataStore } from "../../../../../../../../../configs/stores/admindataStore";
import LocalityMemberNewModal from "../../../../../../../../../components/modals/models/locality_member_new";
import LocalityTeamActions from "./actions";

type Props = {
  item: any;
};

const LocalityItem = ({ item }: Props) => {
  const locality = item;
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { removeLocality } = useAdminDataStore();
  const [isOpenUpdateLocality, setisOpenUpdateLocality] = React.useState(false);
  function closeUpdateLocality() {
    setisOpenUpdateLocality(false);
  }
  const [isOpen, setisOpen] = React.useState(false);
  function close() {
    setisOpen(false);
  }
  const [isOpenNewMember, setisOpenNewMember] = React.useState(false);
  function closeNewMember() {
    setisOpenNewMember(false);
  }
  async function confirm() {
    if (!id) {
      return;
    }
    const res = await removeLocality(locality.id);
    if (!res.success) {
      return null;
    }
    queryClient.setQueryData(["missions", id, "localities"], res.data);
    return res.data;
  }
  const columns = [
    {
      label: "Membre de l'équipe du DC",
      slug: "user",
    },
    {
      label: "Contact",
      slug: "phone",
    },
    {
      label: "Adresse email",
      slug: "email",
    },
  ];
  return (
    <React.Fragment>
      <div className="flex flex-col gap-2 bg-white rounded-[10px] w-full">
        <div className="flex flex-col gap-2 p-5 pb-0">
          <div className="flex flex-col">
            <span className="flex text-[14px]">Ville</span>
            <span className="flex text-[22px] font-medium">
              {locality?.name}
            </span>
          </div>
          <LocalityItemInfo
            title="Directeur de la campagne"
            value={
              locality.director.firstname + " " + locality.director.lastname
            }
          />
          <LocalityItemInfo
            title="Début de la campagne"
            value={moment(locality?.start).format("LL")}
          />
          <LocalityItemInfo
            title="Fin de la campagne"
            value={moment(locality?.end).format("LL")}
          />
          <div className="flex flex-row gap-4 pt-2">
            <div
              onClick={() => {
                setisOpenUpdateLocality(true);
                // setisOpenModify(true);
              }}
              className={clsx(
                "flex transition-all cursor-pointer flex-row gap-[8px] h-[40px] w-fit items-center rounded-[8px] px-4",
                "bg-slate-500/10 hover:bg-slate-500/20 active:bg-slate-500/40 "
              )}
            >
              <Edit size={18} />
              <span className="flex text-black/85 font-medium text-[15px]">
                Modifier
              </span>
            </div>
            <div
              onClick={() => {
                setisOpen(true);
              }}
              className={clsx(
                "flex transition-all cursor-pointer flex-row gap-[8px] h-[40px] w-fit items-center rounded-[8px] px-4",
                "bg-slate-500/10 hover:bg-slate-500/20 active:bg-slate-500/40 "
              )}
            >
              <Trash2 size={18} />
              <span className="flex text-black/85 font-medium text-[15px]">
                Supprimer
              </span>
            </div>
            <div
              onClick={() => {
                setisOpenNewMember(true);
              }}
              className={clsx(
                "flex transition-all cursor-pointer flex-row gap-[8px] h-[40px] w-fit items-center rounded-[8px] px-4",
                "bg-slate-500/10 hover:bg-slate-500/20 active:bg-slate-500/40 "
              )}
            >
              <Plus size={18} />
              <span className="flex text-black/85 font-medium text-[15px]">
                Ajouter un membre à l'équipe
              </span>
            </div>
          </div>
        </div>
        <TableDataAutoList
          columns={columns}
          rows={(locality?.director_team ?? []).map((e: any) => ({
            locality: locality,
            item: e,
            user: e.firstname + " " + e.lastname,
            phone: e.phone,
            email: e.email,
          }))}
          actions={LocalityTeamActions}
        />
      </div>
      <LocalityUpdateModal
        item={locality}
        open={isOpenUpdateLocality}
        handleClose={closeUpdateLocality}
        handleResult={(res) => {
          if (res.success) {
            queryClient.setQueryData(["missions", id, "localities"], res.data);
          }
        }}
      />
      <LocalityMemberNewModal
        item={locality}
        open={isOpenNewMember}
        handleClose={closeNewMember}
        handleResult={(res) => {
          if (res.success) {
            queryClient.setQueryData(["missions", id, "localities"], res.data);
          }
        }}
      />
      <ConfirmActionModal
        open={isOpen}
        message="Voulez-vous vraiment supprimer ?"
        confirm={confirm}
        handleClose={close}
      />
    </React.Fragment>
  );
};

type InfoItemProps = {
  title: string;
  value: string;
};

const LocalityItemInfo = ({ title, value }: InfoItemProps) => {
  return (
    <div className="flex flex-row gap-2 items-center">
      <span className="flex text-[14px] text-black/50">{title} :</span>
      <span className="flex text-[14px]">{value}</span>
    </div>
  );
};

export default LocalityItem;
