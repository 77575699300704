import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { ZustandLocalStorageCrypted } from "./utils";
import {
  confirmReinitForgotPassword,
  getAllBasic,
  getMissionary,
  getParticipant,
  registrationAspirant,
  registrationParticipant,
  sendReinitForgotPasswordLink,
  updateRegistrationParticipant,
} from "./functions/basics";

const LOCAL_DATASTORE_NAME = "missionary-basics";

type ContextProps = {
  isLoading: boolean;
  langs: Array<any>;
  countries: Array<any>;
  mdvieOffices: Array<any>;
  missions: Array<any>;
  churchs: Array<any>;
  departments: Array<any>;
  getAll: () => Promise<any>;
  getMissionary: (id: string) => Promise<any>;
  registrationAspirant: (credentials: any) => Promise<{
    [x: string]: any;
    success: Boolean;
    message: String;
    data: any;
  }>;
  getParticipant: (id: string) => Promise<{
    [x: string]: any;
    success: Boolean;
    message: String;
    data: any;
  }>;
  registrationParticipant: (credentials: any) => Promise<{
    [x: string]: any;
    success: Boolean;
    message: String;
    data: any;
  }>;
  updateRegistrationParticipant: (
    id: string,
    credentials: any
  ) => Promise<{
    [x: string]: any;
    success: Boolean;
    message: String;
    data: any;
  }>;
  sendReinitForgotPasswordLink: (
    data: any
  ) => Promise<{
    [x: string]: any;
    success: Boolean;
    message: String;
    data: any;
  }>;
  confirmReinitForgotPassword: (
    data: any
  ) => Promise<{
    [x: string]: any;
    success: Boolean;
    message: String;
    data: any;
  }>;
};

export const useBasicsStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      isLoading: false,
      langs: [],
      missions: [],
      countries: [],
      mdvieOffices: [],
      churchs: [],
      departments: [],
      getAll: async () => {
        set(
          produce((state: ContextProps) => {
            state.isLoading = true;
          })
        );
        const res = await getAllBasic();
        const data = res?.data;
        set(
          produce((state: ContextProps) => {
            state.langs = data?.langs ?? [];
            state.missions = data?.missions ?? [];
            state.countries = data?.countries ?? [];
            state.mdvieOffices = data?.mdvieOffices ?? [];
            state.departments = data?.departments ?? [];
            state.churchs = data?.churchs ?? [];
            state.isLoading = false;
          })
        );
        return data;
      },
      registrationAspirant: async (credentials) => {
        const res = await registrationAspirant(credentials);
        return res;
      },
      getParticipant: async (id) => {
        const res = await getParticipant(id);
        return res;
      },
      registrationParticipant: async (credentials) => {
        const res = await registrationParticipant(credentials);
        return res;
      },
      updateRegistrationParticipant: async (id, credentials) => {
        const res = await updateRegistrationParticipant(id, credentials);
        return res;
      },
      sendReinitForgotPasswordLink: async (form:any) => {
        const res = await sendReinitForgotPasswordLink(form);
        return res;
      },
      confirmReinitForgotPassword: async (form:any) => {
        const res = await confirmReinitForgotPassword(form);
        return res;
      },
      getMissionary: async (id) => {
        const res = await getMissionary(id);
        return res;
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_bsc")),
    }
  )
);
