import React from "react";

type Props = {
  title: string;
  placeholder?: string;
  name: string;
  defaultValue?: string;
  error?: boolean;
  onChange?: (e: string) => void;
};

const TextInputForm = ({
  title = "",
  placeholder,
  defaultValue,
  name,
  error = false,
  onChange,
}: Props) => {
  return (
    <div className="flex flex-col w-full gap-2">
      <span className={`flex text-[15px] ${error ? "text-red-500" : ""}`}>
        {title}
      </span>
      <input
        type="text"
        name={name}
        defaultValue={defaultValue}
        placeholder={placeholder ?? title}
        onChange={(e) => {
          if (!!onChange) {
            !!e && onChange(e?.target?.value ?? "");
          }
        }}
        className={` transition-all duration-150 flex outline-4 h-[50px] outline-primary px-4 min-w-0 text-[18px] w-full bg-slate-200 rounded-[8px] ${
          error
            ? "placeholder-red-500 border-[3px] border-red-500 outline-transparent outline-none"
            : ""
        }`}
      />
    </div>
  );
};

export default TextInputForm;
