import React from "react";
import { useAuthStore } from "../../configs/stores/auth";
import OfflineHeader from "./offline";
import HeaderConnected from "./connected";

const Header = () => {
  const { isAuthenticated } = useAuthStore();
  return (
    <React.Fragment>
      {isAuthenticated ? <HeaderConnected /> : <OfflineHeader />}
    </React.Fragment>
  );
};

export default Header;
