import React from "react";
import { Outlet } from "react-router-dom";
import DashboardAdminUserNotAuthorizedPage from "./not_authorized";
import { useAuthStore } from "../../../../configs/stores/auth";
import { UserRole } from "../../../../configs/stores/admindataStore";

const DashboardAdminPage = () => {
  const { user } = useAuthStore();
  if (!authorizeUserToAdminPages(user?.role ?? "", user?.grade ?? "")) {
    return <DashboardAdminUserNotAuthorizedPage />;
  }
  return <Outlet />;
};

function authorizeUserToAdminPages(role: any, grade: any) {
  if (!role || typeof role !== "string") {
    return false;
  }
  if (role.toUpperCase() === UserRole.SUPERADMIN) {
    return true;
  }
  if (
    ["ADMIN", "SUPERADMIN"].includes(role.toUpperCase()) ||
    [
      "ASPIRANT",
      "MESSAGER",
      "MISSIONNAIRE",
      "ASSISTANT_EVANGELISTE",
      "EVANGELISTE",
      "RESPONSABLE_DEPARTEMENT",
      "SUPERADMIN",
    ].includes(grade)
  ) {
    return true;
  }
  return false;
}

export default DashboardAdminPage;
