import React from "react";
import ModalCustom from "../..";
import { useAdminDataStore } from "../../../../configs/stores/admindataStore";
import clsx from "clsx";

type Props = {
  item: any;
  handleClose: () => void;
  open: boolean;
};

const MissionaryUpdateMDPModal = ({
  item,
  open = false,
  handleClose = () => {},
}: Props) => {
  const { updateUserReinitPassword } = useAdminDataStore();
  const [closable] = React.useState(true);
  const [isLoading, setisLoading] = React.useState(false);
  function onSubmitForm() {
    setisLoading(true);
    updateUserReinitPassword(item.id).then((res: any) => {
      setisLoading(false);
      if (res.success) {
        handleClose();
      } else {
      }
    });
  }
  return (
    <ModalCustom open={open} handleClose={closable ? handleClose : undefined}>
      <div className="flex flex-col w-[450px] p-3 pt-0 gap-y-3 items-start justify-center">
        <span className="flex text-[20px] font-medium">
          Réinitialiser Mot de passe Missionnaire
        </span>
        <span className="flex text-[15px] font-medium">
          Après la réinitialisation du mot de passe de cet utilisateur, un email
          de notification lui sera automatiquement envoyé. Ce message contiendra
          toutes les informations nécessaires pour qu’il puisse accéder de
          nouveau à son compte en utilisant un mot de passe par défaut. Ce mot
          de passe temporaire est destiné à faciliter la première connexion
          suivant la réinitialisation et doit être modifié dès que possible pour
          garantir la sécurité de son compte.
        </span>
        <span className="flex text-[15px] font-medium">
          Nous recommandons vivement à l’utilisateur de suivre les instructions
          fournies dans le mail et de mettre à jour son mot de passe dès sa
          connexion initiale. Cette étape permettra de renforcer la protection
          de ses informations personnelles et de sécuriser l’accès à son compte.
        </span>
        <button
          disabled={isLoading}
          onClick={!isLoading ? onSubmitForm : undefined}
          className={clsx(
            "flex justify-center items-center mt-2 w-fit px-10 rounded-[8px] h-[47px] transition-all duration-150",
            !isLoading
              ? " bg-[#009ADE] hover:bg-hover active:bg-active"
              : "bg-buttonInactiveColor"
          )}
        >
          <span
            className={clsx(
              "flex select-none",
              !isLoading
                ? "text-[16px] font-bold text-white "
                : "text-[15px] font-semibold text-white/80"
            )}
          >
            {!isLoading
              ? "Réinitialiser le mon de passe"
              : "Patienter un instant..."}
          </span>
        </button>
      </div>
    </ModalCustom>
  );
};

export default MissionaryUpdateMDPModal;
