import React, { ReactNode } from "react";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";
import clsx from "clsx";
import ScreenHeaderActionRefreshBrut from "./refreshButton";

type Props = {
  icon?: ReactNode;
  title: any;
  children?: ReactNode;
  isFixed?: boolean;
  height?: number;
};

const ScreenHeader = ({
  icon,
  title,
  height = 70,
  isFixed = false,
  children,
}: Props) => {
  return (
    <div
      style={{
        height,
      }}
      className={`flex flex-row bg-white justify-between items-center w-full border-b border-b-black/10 px-[22px] py-4 gap-x-4 ${
        isFixed ? "top-0 fixed" : ""
      }`}
    >
      <div className="flex flex-row gap-x-4 items-center">
        {icon && typeof title === "string" && (
          <div className="flex h-[45px] w-[47px] rounded-[10px] bg-primary items-center justify-center">
            {icon}
          </div>
        )}
        {typeof title === "string" ? (
          <span className="flex text-[18px] font-medium">{title}</span>
        ) : (
          title
        )}
      </div>
      <div className="flex items-center gap-x-4">{children}</div>
    </div>
  );
};

type ScreenActionItemProps = {
  icon?: ReactNode;
  title?: string;
  isLoading?: boolean;
  url?: string;
  onClick?: () => void;
  primary?: boolean;
};

export const ScreenHeaderActionItem = ({
  icon,
  title,
  onClick,
  isLoading = false,
  url,
  primary = false,
}: ScreenActionItemProps) => {
  const navigate = useNaviLoadBoth;
  function click() {
    !!onClick && onClick();
    !!url && navigate(url);
  }
  return (
    <div className="flex flex-col relative h-[40px] rounded-[8px] overflow-clip">
      <div
        onClick={!isLoading ? click : undefined}
        className={clsx(
          "flex select-none h-full transition-all duration-200 flex-row justify-center items-center px-3 py-2 gap-x-2",
          !isLoading
            ? primary
              ? "bg-primary cursor-pointer hover:bg-primary/75 active:bg-primary/85 text-white"
              : "bg-black/10 cursor-pointer hover:bg-black/15 active:bg-black/25"
            : primary
            ? "bg-primary/80 cursor-progress text-white"
            : "bg-black/10"
        )}
      >
        {!!icon && (
          <div className="flex rounded-[10px] items-center justify-center">
            {icon}
          </div>
        )}
        <span className="hidden min_pc_mini:flex text-[15px] font-medium">
          {title}
        </span>
      </div>
      {!!url && (
        <a href={url} className="flex w-full h-full bg-white/5 absolute">
          .
        </a>
      )}
    </div>
  );
};

type ScreenActionRefreshProps = {
  title?: string;
  isLoading?: boolean;
  onClick?: () => void;
};

export const ScreenHeaderActionRefresh = ({
  title,
  onClick,
  isLoading = false,
}: ScreenActionRefreshProps) => {
  return (
    <ScreenHeaderActionRefreshBrut
      title={title}
      isLoading={isLoading}
      onClick={onClick}
    />
  );
};

export default ScreenHeader;
