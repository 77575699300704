import clsx from "clsx";
import { Edit, SquareArrowOutUpRight, Trash2 } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";
import ConfirmActionModal from "../../../../../../../components/modals/models/confirmation";
import { useAdminDataStore } from "../../../../../../../configs/stores/admindataStore";
import SurveyUpdateModal from "../../../../../../../components/modals/models/survey_update";
import { useQueryClient } from "@tanstack/react-query";

type Props = {
  row?: any;
};

const SurveyActions = ({ row }: Props) => {
  const queryClient = useQueryClient();
  const { removeSurvey } = useAdminDataStore();
  const [isOpenModify, setisOpenModify] = React.useState(false);
  function closeModify() {
    setisOpenModify(false);
  }
  const [isOpen, setisOpen] = React.useState(false);
  function close() {
    setisOpen(false);
  }
  async function confirm() {
    if (!row?.item.id) {
      alert("Requète non valide !");
      return;
    }
    return await removeSurvey(row?.item.id).then((res) => {
      if (!res.success) {
        !res?.message && alert(res?.message);
      }
    });
  }
  return (
    <React.Fragment>
      <div className="flex flex-col">
        <Link
          to={row.item.id}
          className={clsx(
            "flex cursor-pointer flex-row gap-[8px] h-[40px] w-full items-center rounded-[8px] px-3",
            "hover:bg-slate-500/20 active:bg-slate-500/40 "
          )}
          onClick={() => {
            queryClient.invalidateQueries({
              queryKey: ["surveys", row.item.id],
              exact: true,
            });
          }}
        >
          <SquareArrowOutUpRight size={18} />
          <span className="flex text-black font-medium text-[15px]">
            Ouvrir
          </span>
        </Link>
        <div
          onClick={() => {
            setisOpenModify(true);
          }}
          className={clsx(
            "flex cursor-pointer flex-row gap-[8px] h-[40px] w-full items-center rounded-[8px] px-3",
            "hover:bg-slate-500/20 active:bg-slate-500/40 "
          )}
        >
          <Edit size={18} />
          <span className="flex text-black font-medium text-[15px]">
            Modifier
          </span>
        </div>
        <div
          onClick={() => {
            setisOpen(true);
          }}
          className={clsx(
            "flex cursor-pointer flex-row gap-[8px] h-[40px] w-full items-center rounded-[8px] px-3",
            "hover:bg-slate-500/20 active:bg-slate-500/40 "
          )}
        >
          <Trash2 size={18} />
          <span className="flex text-black font-medium text-[15px]">
            Supprimer
          </span>
        </div>
      </div>
      <SurveyUpdateModal
        open={isOpenModify}
        item={row.item}
        handleClose={closeModify}
      />
      <ConfirmActionModal
        open={isOpen}
        message="Voulez-vous vraiment supprimer ce questionnaire ?"
        confirm={confirm}
        handleClose={close}
      />
    </React.Fragment>
  );
};

export default SurveyActions;
