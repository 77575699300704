import React from "react";
import ScreenViewLayout from "../../../../../../../../../components/scroll_view";

type Prop = {
  survey?: any;
};

const InterviewDateSurvey = ({ survey }: Prop) => {
  return (
    <div className="flex h-fit flex-1 max-w-[45%] flex-col gap-4 bg-white p-5 rounded-[10px] overflow-y-auto max-h-[calc(100vh - 200px)]">
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-1">
          <span className="flex text-[15px] text-primary">Questionnaire</span>
          <span className="flex text-[25px] text-black">{survey?.name}</span>
        </div>
        <div className="flex flex-row gap-1">
          <span className="flex text-[13px] text-black underline underline-offset-2">
            Description:
          </span>
          <span className="flex text-[13px] text-black">
            {survey?.description}
          </span>
        </div>
      </div>
      <ScreenViewLayout
        axis={"col"}
        gap={3}
        backgroundColor={"#fff"}
        maxHeight={"calc(100vh-300px)"}
        minHeight={400}
        pb={50}
        px={0}
        py={0}
      >
        {(survey?.questions ?? []).map((e: any, index: any) => {
          return <SurveyQuestion key={e?.id ?? index} item={e} />;
        })}
      </ScreenViewLayout>
    </div>
  );
};

type Props = {
  item: any;
};

const SurveyQuestion = ({ item }: Props) => {
  return (
    <div className="flex flex-row gap-2">
      <span className="flex text-[16px] font-semibold">{item?.index}.</span>
      <div className="flex flex-col gap-1 pt-[1px]">
        <span className="flex text-[16px] text-black font-medium">
          Question: {item?.question}
        </span>
        <div className="flex flex-row gap-2 text-primary justify-start">
          <div className="flex flex-col gap-1">
            <span className="flex text-[13px] font-medium whitespace-nowrap">
              • Intervalle de reponse:
            </span>
            <span className="flex ml-3 text-[13px] font-medium">
              {item?.response_range}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterviewDateSurvey;
