import React from "react";

type FormMessageType = {
  title?: string;
  message?: string;
  styleMessage?: "neutre" | "error" | "succes";
};

const FormMessage = ({ title, message, styleMessage = "neutre" }: FormMessageType) => {
  function getStyle(style: string) {
    switch (style) {
      case "neutre":
        return "bg-gray-500";
      case "error":
        return "bg-red-500/70";
      case "success":
        return "bg-green-500";
      default:
        return "bg-gray-500";
    }
  }
  return (
    <div
      className={`flex flex-col px-4 py-2 gap-1 rounded-[10px] ${getStyle(styleMessage)}`}
    >
      {title && (
        <span className="flex text-white text-[15px] font-semibold">
          {title}
        </span>
      )}
      {message && (
        <span className="flex text-white text-[14px] font-semibold">
          {message}
        </span>
      )}
      {!title && !message && (
        <span className="flex text-white text-[14px] font-semibold">
          Ajouter un title et/ou un message !
        </span>
      )}
    </div>
  );
};

export default FormMessage;
