import clsx from "clsx";
import { RotateCw } from "lucide-react";
import React from "react";

type ScreenActionRefreshProps = {
  title?: string;
  isLoading?: boolean;
  onClick?: () => void;
};

const ScreenHeaderActionRefreshBrut = React.memo(
  ({
    title = "Rafraichir",
    onClick,
    isLoading = false,
  }: ScreenActionRefreshProps) => {
    function handleClick() {
      if (!isLoading && onClick) {
        !!onClick && onClick();
      }
    }
    return (
      <div className="flex flex-col relative h-[40px] rounded-[8px] overflow-clip">
        <div
          onClick={!isLoading ? handleClick : undefined}
          className={clsx(
            "flex select-none h-full transition-all duration-200 flex-row justify-center items-center px-4 py-2 gap-x-2",
            !isLoading
              ? "bg-black/10 cursor-pointer hover:bg-black/15 active:bg-black/25"
              : "bg-black/10"
          )}
        >
          <div
            className={clsx(
              "flex rounded-[10px] items-center justify-center",
              isLoading ? "animate-spin" : ""
            )}
          >
            <RotateCw size={20} stroke="black" />
          </div>
          <span className="hidden min_pc_mini:flex text-[15px] font-medium">
            {!isLoading ? title : "Chargement..."}
          </span>
        </div>
      </div>
    );
  }
);

export default ScreenHeaderActionRefreshBrut;
