import React from "react";
import ScreenHeader, {
  ScreenHeaderActionRefresh,
} from "../../../../../../components/screen_header";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ArrowLeft, MoveRight, RefreshCcw, X } from "lucide-react";
import { useDataStore } from "../../../../../../configs/stores/dataStore";
import { useQuery } from "@tanstack/react-query";
import { useAuthStore } from "../../../../../../configs/stores/auth";
import moment from "moment";
import { capitalizeAllWords } from "../../../../../../constants";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import ButtonClassic from "../../../../../../components/button";
import { InterviewMeetType } from "../../../../../../configs/stores/admindataStore";
import DialogModal from "../../../../../../components/modals/models/dialog";

const DashMyMissionsScreenMission = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuthStore();
  const { missions, getMission, selectInterview } = useDataStore();
  const [checkAll, setCheckAll] = React.useState(true);
  const [checksSelected, setchecksSelected] = React.useState<Array<string>>([]);
  const [meetSelected, setMeetSelected] = React.useState<string>("");
  const [messageDialog, setMessageDialog] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  async function fetchData() {
    if (!id) {
      return;
    }
    const res = await getMission(id);
    if (!res.success) {
      return null;
    }
    return res.data;
  }
  function closeDialog() {
    setOpenDialog(false);
    setMessageDialog("");
  }
  const {
    isRefetching,
    data: mission,
    refetch,
  } = useQuery({
    enabled: isAuthenticated,
    queryFn: fetchData,
    initialData: missions.find((m) => m.id === id),
    queryKey: ["mymissions", id],
    refetchInterval: 5 * 60 * 1000, // 5 minutes in milliseconds
  });
  function checkForm() {
    let errorOccured = false;
    // check towns selections
    if (!checkAll && checksSelected.length === 0) {
      errorOccured = true;
      let msg =
        "Veuillez sélectionner au moins une ville avant de poursuivre !";
      setMessageDialog(msg);
      return errorOccured;
    }

    // select meet
    if (meetSelected.length === 0) {
      errorOccured = true;
      let msg = "Veuillez sélectionner un rendez-vous qui vous convient !";
      setMessageDialog(msg);
      return errorOccured;
    }
    return errorOccured;
  }
  function submitParticipation() {
    if (checkForm()) {
      setOpenDialog(true);
      return;
    }
    setIsLoading(true);
    setErrorMessage(null);
    selectInterview({
      user: user.id,
      mission: missions.find((m) => m.id === id).id,
      interview: missions
        .find((m) => m.id === id)
        .interviews.find((i: any) => i.id === meetSelected)?.interview?.id,
      date: meetSelected,
      all_localities: checkAll,
      selected_localities: checksSelected,
    }).then((res: any) => {
      setIsLoading(false);
      if (!res.success) {
        setErrorMessage(res.message.toString());
      } else {
        navigate(`../interviews`);
      }
    });
  }
  return (
    <React.Fragment>
      <div className="flex flex-col flex-1 h-full">
        <div className="flex flex-1 flex-col h-full w-full">
          <ScreenHeader
            title={
              <div className="flex flex-row items-center gap-4">
                <Link
                  to=".."
                  className="flex transition-all p-2 cursor-pointer items-center justify-center rounded-full bg-black/5 hover:bg-black/10 active:bg-black/15"
                >
                  <ArrowLeft size={20} />
                </Link>
                <div className="flex flex-col select-none">
                  <span className="flex text-[12px]">Mission</span>
                  {!!mission ? (
                    <span className="flex text-[18px]">{mission.name}</span>
                  ) : isRefetching ? (
                    <span className="flex text-[18px]">
                      Chargement de l'entretien...
                    </span>
                  ) : (
                    <div className="flex text-red-500 text-[16px]">
                      Entretien introuvable !
                    </div>
                  )}
                </div>
              </div>
            }
          ></ScreenHeader>
          <div
            className={`flex flex-1 flex-col pt-5 gap-5 w-full bg-slate-100`}
          >
            <div className="flex flex-col gap-2 bg-white rounded-[10px] w-full">
              <div className="flex flex-col gap-2 p-5">
                <InterviewItemInfo
                  title="Date de départ"
                  value={moment(mission.teams_departure).format("LL")}
                />
                <InterviewItemInfo
                  title="Date de retour"
                  value={moment(mission.teams_return).format("LL")}
                />
                <InterviewItemInfo title="Pays" value={mission.country.name} />
              </div>
            </div>
            {/* <div className="flex flex-col p-5 rounded-[10px] gap-2 bg-white">
              <div className="flex flex-col gap-1">
                <span className="flex text-[16px] font-medium">
                  Villes ciblées :
                </span>
                <span className="flex text-[13px] text-black/50 font-medium">
                  Pendant l’entretien, vous pourrez préciser si vous souhaitez
                  participer dans toutes les villes citées ci-dessous.
                </span>
              </div>
              <div className="flex flex-col w-full gap-3 pb-2 overflow-x-auto">
                {(mission?.localities ?? []).map((e: any, i: any) => {
                  return (
                    <div key={e.id} className="flex flex-row gap-3">
                      <div className="flex select-none w-[30px] h-[30px] text-[16px] items-center justify-center rounded-[50%] border border-black/50">
                        {(i + 1).toString()}
                      </div>
                      <LocalityItemCheck item={e} />
                    </div>
                  );
                })}
              </div>
            </div> */}
            {!!errorMessage && (
              <div className="flex flex-row items-center p-[10px] gap-3 bg-red-500/20 rounded-[10px]">
                <button
                  onClick={() => setErrorMessage(null)}
                  className="flex rounded-[6px] justify-center items-center h-[30px] w-[30px] bg-red-500/40"
                >
                  <X color="white" size={18} />
                </button>
                <span className="flex text-[15px] text-black">
                  {errorMessage}
                </span>
              </div>
            )}
            <div className="flex flex-row p-4 px-5 justify-between rounded-[10px] gap-4 bg-white">
              <ScreenHeaderActionRefresh
                title="Rafraichir les menus"
                isLoading={isRefetching}
                onClick={refetch}
              />
              {!isLoading ? (
                <ButtonClassic
                  color="primary"
                  text="Enregistrer ma participation"
                  onClick={submitParticipation}
                />
              ) : (
                <div className="flex flex-row gap-3 h-[40px] rounded-[10px] items-center px-[15px] bg-primary duration-150 transition-all">
                  <div className="flex animate-spin">
                    <RefreshCcw color="white" size={18} />
                  </div>
                  <span className="flex select-none text-[15px] text-white font-medium">
                    Enregistrement en cours...
                  </span>
                </div>
              )}
            </div>
            <div className="flex flex-col p-5 rounded-[10px] gap-2 bg-white">
              <div className="flex flex-col gap-1">
                <span className="flex text-[16px] font-medium">
                  Villes ciblées :
                </span>
                <span className="flex text-[13px] text-black/50 font-medium">
                  Sélectionner la ou les villes désirées
                </span>
              </div>
              <div className="flex flex-col w-full pb-2 overflow-x-auto">
                <FormGroup
                  sx={{
                    gap: 1,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkAll}
                        onChange={(e, c) => {
                          setCheckAll(c);
                          if (c) {
                            setchecksSelected(
                              mission?.localities?.map((e: any) => e.id) ?? []
                            );
                          }
                        }}
                        name="all"
                      />
                    }
                    label={"Toutes les villes"}
                  />
                  {(mission?.localities ?? []).map((e: any, i: any) => {
                    return (
                      <FormControlLabel
                        key={e.id}
                        disabled={checkAll}
                        control={
                          <Checkbox
                            checked={checksSelected.includes(e.id)}
                            onChange={(_, c) => {
                              if (!c) {
                                setchecksSelected((t) =>
                                  t.filter((f) => f !== e.id)
                                );
                              } else {
                                setchecksSelected((t) =>
                                  t.filter((f) => f !== e.id).concat(e.id)
                                );
                              }
                            }}
                            name={e.id}
                          />
                        }
                        label={<LocalityItemCheck item={e} />}
                      />
                    );
                  })}
                </FormGroup>
              </div>
            </div>
            <div className="flex flex-col p-5 rounded-[10px] gap-2 bg-white">
              <span className="flex text-[16px] font-medium">
                Sélectionner un rendez-vous :
              </span>
            </div>
            <div className="flex flex-row flex-wrap gap-5 w-full pb-2 overflow-x-auto">
              {(mission?.interviews ?? []).map((e: any, i: any) => {
                return (
                  <DateItem
                    key={i}
                    item={e}
                    selected={meetSelected}
                    select={(id) => {
                      setMeetSelected(id);
                    }}
                  />
                );
              })}
              {(mission?.interviews ?? [])?.length === 0 && (
                <span className="flex text-black/50 text-[13.5px] leading-none select-none">
                  Aucun rendez-vous enregistrées pour l'instant !
                </span>
              )}
            </div>
          </div>
        </div>
        {isRefetching && !mission && (
          <div className="absolute flex h-full w-full justify-center items-center backdrop-filter backdrop-blur-sm bg-black/20">
            <div className="flex flex-col p-5 items-center gap-3 max-w-[300px] cursor-default shadow-md select-none bg-white rounded-[10px]">
              <span className="flex text-[14px] text-center">
                Patientez un instant !
              </span>
              <span className="flex text-[18px] text-center">
                Chargement des données de l'entretien en cours...
              </span>
            </div>
          </div>
        )}
      </div>
      <DialogModal
        message={messageDialog}
        handleClose={closeDialog}
        open={openDialog}
      />
    </React.Fragment>
  );
};

type InfoItemProps = {
  title: string;
  value: string;
};

const InterviewItemInfo = ({ title, value }: InfoItemProps) => {
  return (
    <div className="flex flex-row gap-2 items-center">
      <span className="flex text-[14px] text-black/50">{title} :</span>
      <span className="flex text-[14px]">{value}</span>
    </div>
  );
};

type LocalityItemCheckProps = {
  item: any;
};

const LocalityItemCheck = ({ item }: LocalityItemCheckProps) => {
  return (
    <div className="flex flex-col gap-1 w-fit">
      <span className="flex text-[14px] font-medium line-clamp-1 leading-none text-ellipsis">
        {item?.name}
      </span>
      <div className="flex flex-row gap-4 items-center justify-between">
        <span className="flex text-[12px] leading-none">
          {capitalizeAllWords(moment(item?.start).format("ll"))}
        </span>
        <MoveRight size={15} color="black" />
        <span className="flex text-[12px] leading-none">
          {capitalizeAllWords(moment(item?.end).format("ll"))}
        </span>
      </div>
    </div>
  );
};

type DateProps = {
  item: any;
  selected: string;
  select: (id: string) => void;
};

const DateItem = ({ item, select, selected = "" }: DateProps) => {
  return (
    <div className="flex flex-col gap-3 select-none p-5 bg-white rounded-[10px] w-[280px]">
      <div className="flex flex-col">
        <span className="flex text-[14px] text-primary opacity-80">
          Jour d'entretien
        </span>
        <span className="flex text-[18px] font-medium">
          {moment(item?.date).format("LL")}
        </span>
      </div>
      <span className="flex text-primary text-[14px] leading-tight">
        {"Rencontre " +
          Object.entries(InterviewMeetType)
            .find((e) => e[0] === item.type)?.[1]
            ?.toLocaleLowerCase()}
      </span>
      <div className="flex flex-col pb-1 gap-1">
        <span className="flex text-[14px] opacity-80">
          {item.type === "ONLINE" ? "Lien" : "Lieu"} de la rencontre
        </span>
        {item.type === "ONLINE" ? (
          <a
            href={item.location}
            target="_blank"
            className="flex text-[14px] underline-offset-2 underline font-medium"
            rel="noreferrer"
          >
            Cliquez ici pour accéder à la réunion
          </a>
        ) : (
          <span className="flex text-[15px] line-clamp-2 font-medium text-ellipsis">
            {item.interview.mdvieoffice.name}
          </span>
        )}
      </div>
      <div className="flex flex-col w-full">
        {selected.length === 0 || selected !== item.id ? (
          <ButtonClassic
            onClick={() => {
              select(item.id);
            }}
            width={"100%"}
            height={43}
            color="primary"
            text="Sélectionner"
            centerText
            icon={undefined}
          />
        ) : (
          <ButtonClassic
            onClick={() => {
              select("");
            }}
            width={"100%"}
            height={43}
            centerText
            text="Désélectionner"
            icon={undefined}
          />
        )}
      </div>
    </div>
  );
};

export default DashMyMissionsScreenMission;
