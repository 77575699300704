import { useQuery } from "@tanstack/react-query";
import { useAuthStore } from "../../../../../../../../configs/stores/auth";
import { useParams } from "react-router-dom";
import { useAdminDataStore } from "../../../../../../../../configs/stores/admindataStore";

const WelcomeStat = () => {
  const { id } = useParams();
  const { isAuthenticated } = useAuthStore();
  const { getMissionStats } = useAdminDataStore();
  async function fetchMissionStat() {
    if (!id) {
      return;
    }
    const res = await getMissionStats(id);
    if (!res.success) {
      return null;
    }
    return res.data;
  }
  const { data, isLoading } = useQuery({
    enabled: isAuthenticated,
    queryFn: fetchMissionStat,
    queryKey: ["missions", id, "stats"],
    staleTime: 7 * 60 * 1000, // 5 minutes in milliseconds
    refetchInterval: 10 * 60 * 1000, // 5 minutes in milliseconds
  });
  return (
    <div className="flex flex-col gap-4">
      <span className="flex text-[20px]">
        Statistiques {isLoading && "- Chargement..."}
      </span>
      <div className="flex flex-row gap-7 flex-wrap">
        <StatItem
          item={{
            title: "Villes enregistrées",
            value: data?.localities ?? "0",
          }}
          onClick={() => {}}
        />
        <StatItem
          item={{
            title: "Missionnaires Validés",
            value: data?.missionaries_validated ?? "0",
          }}
          onClick={() => {}}
        />
        <StatItem
          item={{
            title: "Missionnaires en attentent",
            value: data?.missionaries_pended ?? "0",
          }}
          onClick={() => {}}
        />
      </div>
    </div>
  );
};

type StatItemProps = {
  item: any;
  onClick: () => void;
};
const StatItem = ({ item, onClick }: StatItemProps) => {
  return (
    <div className="flex p-5 items-center w-[200px] h-[210px] flex-col justify-between gap-4 bg-white rounded-[10px]">
      <span className="flex text-black text-center text-[14px] font-medium">
        {item.title}
      </span>
      <div className="flex flex-col items-center">
        <span className="flex text-black text-center text-[45px] font-medium">
          {item.value}
        </span>
      </div>
      {item?.action && (
        <span
          onClick={onClick}
          className="flex text-black text-center text-[16px] font-medium"
        >
          {item.action}
        </span>
      )}
      {!item?.action && <span></span>}
    </div>
  );
};

export default WelcomeStat;
