import React from "react";
import SideBarBottom from "./bottom";
import SideBarTop from "./top";
import SidebarContent from "./content";
import { ShieldCheck } from "lucide-react";
import { useAuthStore } from "../../../configs/stores/auth";
import { UserRole } from "../../../configs/stores/admindataStore";

const SideBar = () => {
  const { user } = useAuthStore();
  return (
    <div className="flex flex-col flex-1 w-full">
      <SideBarTop />
      {[UserRole.ADMIN, UserRole.SUPERADMIN].includes(user.role) && (
        <div className="flex flex-row items-center gap-2 pt-1 pb-1 pl-[23px] bg-white/10 border-t-[1px] border-t-white/15">
          <ShieldCheck color="white" size={13} strokeWidth={2.5} />
          <span className="flex text-white text-[10px] whitespace-nowrap">
            Espace d'Administration
          </span>
        </div>
      )}
      {[UserRole.ADMIN, UserRole.SUPERADMIN].includes(user.role) && (
        <SidebarContent />
      )}
      <SideBarBottom />
    </div>
  );
};

export default SideBar;
