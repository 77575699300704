import { useQuery } from "@tanstack/react-query";
import { useAuthStore } from "../../../../../../../../configs/stores/auth";
import { useParams } from "react-router-dom";
import { useAdminDataStore } from "../../../../../../../../configs/stores/admindataStore";
import LocalityItem from "./locality";

const WelcomeLocalities = () => {
  const { id } = useParams();
  const { isAuthenticated } = useAuthStore();
  const { refetchLocalities } = useAdminDataStore();
  async function fetchData() {
    if (!id) {
      return;
    }
    const res = await refetchLocalities(id);
    if (!res.success) {
      return null;
    }
    return res.data;
  }
  const { data, isLoading } = useQuery({
    enabled: isAuthenticated,
    queryFn: fetchData,
    queryKey: ["missions", id, "localities"],
    staleTime: 7 * 60 * 1000, // 5 minutes in milliseconds
    refetchInterval: 10 * 60 * 1000, // 5 minutes in milliseconds
  });
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between">
        <span className="flex text-[20px] font-medium">
          Villes enregistrées {isLoading && "- Chargement..."}
        </span>
      </div>
      <div className="flex flex-row gap-7 flex-wrap">
        {data?.map((e: any, index: any) => {
          return <LocalityItem key={index} item={e} />;
        })}
      </div>
    </div>
  );
};

export default WelcomeLocalities;
