import React from "react";
import ModalCustom from "../..";
import FormMessage from "../../../form_components/message";
import SubmitForm from "../../../form_components/submit";
import { v4 as uuIdv4 } from "uuid";
import { useAdminDataStore } from "../../../../configs/stores/admindataStore";
import TextInputForm from "../../../form_components/text_input";

type Props = {
  item: any;
  handleClose: () => void;
  open: boolean;
};

const CountryUpdateModal = ({
  item,
  open = false,
  handleClose = () => {},
}: Props) => {
  const [formKey, setFormKey] = React.useState(uuIdv4());
  const { updateCountry } = useAdminDataStore();
  const [closable] = React.useState(true);
  const [isLoading, setisLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<{
    [x: string]: any;
  }>({
    general: null,
  });
  function checkForm(form: any) {
    let errorOccured = false;
    // name
    if (form.name.length < 2) {
      errorOccured = true;
      setErrors((e) => ({ ...e, name: true }));
    } else {
      setErrors((e) => ({ ...e, name: null }));
    } // code
    if (form.code.length < 2) {
      errorOccured = true;
      setErrors((e) => ({ ...e, code: true }));
    } else {
      setErrors((e) => ({ ...e, code: null }));
    }
    return errorOccured;
  }
  function updateCheckForm(e: any) {
    e.preventDefault();
    const formInput = e.target;
    if (!formInput) {
      return;
    }
    if (!!errors.general) {
      setErrors((e) => ({ ...e, general: null }));
    }
    setErrors((e) => ({ ...e, [formInput.name]: null }));
  }
  function onSubmitForm(e: any) {
    e.preventDefault();
    if (!item?.id) {
      setErrors((e) => ({ ...e, general: "Formulaire incorrecte !" }));
      return;
    }
    const form = e.target.elements;
    if (errors.general !== null) {
      setErrors((e) => ({ ...e, general: null }));
    }
    if (form === null) {
      setErrors((e) => ({ ...e, general: "Renseigner le formulaire !" }));
      return;
    }
    const formValues = {
      name: form.name.value,
      code: form.code.value,
    };
    if (checkForm(formValues)) {
      console.log("Error Occured !!!");
      return;
    }
    const formDatas = {
      name: form.name.value,
      code: form.code.value,
    };
    setErrors((e) => ({
      ...e,
      general: "",
    }));
    setisLoading(true);
    updateCountry(item.id, formDatas).then((res: any) => {
      setisLoading(false);
      if (res.success) {
        setFormKey(uuIdv4());
        setErrors({
          general: null,
        });
        handleClose();
      } else {
        setErrors((e) => ({
          ...e,
          general: res.message.toString(),
        }));
      }
    });
  }
  React.useEffect(() => {
    if (!open) {
      setFormKey(uuIdv4());
      setErrors({
        general: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  return (
    <ModalCustom open={open} handleClose={closable ? handleClose : undefined}>
      <div className="flex flex-col w-[400px] p-3 pt-0 space-y-2 items-start justify-center">
        <span className="flex text-[20px] font-medium">Modifier Pays</span>
        <form
          key={formKey}
          className="flex flex-col w-full px-1 gap-2 max-h-[80vh] overflow-y-auto"
          onSubmit={!isLoading ? onSubmitForm : undefined}
          onChange={updateCheckForm}
        >
          <TextInputForm
            title={"Pays"}
            name={"name"}
            defaultValue={item.name}
            error={!!errors.name}
          />
          <TextInputForm
            title={"Code"}
            name={"code"}
            defaultValue={item.code}
            error={!!errors.code}
          />
          {errors.general && (
            <FormMessage message={errors.general} styleMessage={"error"} />
          )}
          <SubmitForm isLoading={isLoading} title="Enregistrer" />
        </form>
      </div>
    </ModalCustom>
  );
};

export default CountryUpdateModal;
