import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { ZustandLocalStorageCrypted } from "./utils";
import {
  getAllData,
  getAllMeet,
  getAllMission,
  getMission,
  selectInterview,
  cancelMeet,
} from "./functions/dataStoreFunctions";

const LOCAL_DATASTORE_NAME = "missionary-datas";

type ContextProps = {
  isLoadingAll: boolean;
  getAll: () => Promise<any>;
  departments: Array<any>;
  countries: Array<any>;
  churchs: Array<any>;
  missions: Array<any>;
  isRefetchingMissions: boolean;
  refetchMissions: () => Promise<any>;
  getMission: (id: string) => Promise<any>;
  mdvieOffices: Array<any>;
  missionTypes: Array<any>;
  surveys: Array<any>;
  interviews: Array<any>;
  isRefetchingInterviews: boolean;
  refetchInterviews: () => Promise<any>;
  selectInterview: (form: any) => Promise<any>;
  cancelMeet: (id: string) => Promise<any>;
};

export const useDataStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      isLoadingAll: false,
      getAll: async () => {
        set(
          produce((state: ContextProps) => {
            state.isLoadingAll = true;
          })
        );
        const res = await getAllData();
        const data = res?.data;
        set(
          produce((state: ContextProps) => {
            state.missions = data?.missions ?? [];
            state.countries = data?.countries ?? [];
            state.mdvieOffices = data?.mdvieOffices ?? [];
            state.departments = data?.departments ?? [];
            state.churchs = data?.churchs ?? [];
            state.missionTypes = data?.missionTypes ?? [];
            state.isLoadingAll = false;
          })
        );
        return data;
      },
      isRefetchingMissions: false,
      missions: [],
      refetchMissions: async () => {
        set(
          produce((state: ContextProps) => {
            state.isRefetchingMissions = true;
          })
        );
        const res = await getAllMission();
        set(
          produce((state: ContextProps) => {
            state.missions = res?.data ?? [];
            state.isRefetchingMissions = false;
          })
        );
        return res;
      },
      getMission: async (id: string) => {
        const res = await getMission(id);
        if (res.success) {
          set(
            produce((state: ContextProps) => {
              state.missions = state.missions
                .filter((i) => i.id !== id)
                .concat(res.data);
            })
          );
        }
        return res;
      },
      countries: [],
      mdvieOffices: [],
      departments: [],
      churchs: [],
      missionTypes: [],
      surveys: [],
      interviews: [],
      isRefetchingInterviews: false,
      refetchInterviews: async () => {
        set(
          produce((state: ContextProps) => {
            state.isRefetchingInterviews = true;
          })
        );
        const res = await getAllMeet();
        set(
          produce((state: ContextProps) => {
            state.interviews = res?.data ?? [];
            state.isRefetchingInterviews = false;
          })
        );
        return res;
      },
      selectInterview: async (form) => {
        const res = await selectInterview(form);
        if (res.success) {
          set(
            produce((state: ContextProps) => {
              state.interviews = res.data;
            })
          );
        }
        return res;
      },
      cancelMeet: async (id) => {
        const res = await cancelMeet(id);
        if (res.success) {
          set(
            produce((state: ContextProps) => {
              state.interviews = res.data;
            })
          );
        }
        return res;
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_ds")),
    }
  )
);
