import clsx from "clsx";
import { Trash2 } from "lucide-react";
import React from "react";
import { useAdminDataStore } from "../../../../../../../../../configs/stores/admindataStore";
import ConfirmActionModal from "../../../../../../../../../components/modals/models/confirmation";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

type Props = {
  row?: any;
};

const LocalityTeamActions = ({ row }: Props) => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { updateLocality } = useAdminDataStore();
  const [isOpen, setisOpen] = React.useState(false);
  function close() {
    setisOpen(false);
  }
  async function confirm() {
    if (!id) {
      return;
    }
    const formDatas = {
      director_team: (row.locality?.director_team ?? [])
        .map((e: any) => e.id)
        .filter((e: any) => e !== row.item.id),
    };
    const res = await updateLocality(row.locality.id, formDatas);
    if (!res.success) {
      return null;
    }
    queryClient.setQueryData(["missions", id, "localities"], res.data);
    return res.data;
  }
  return (
    <React.Fragment>
      <div className="flex flex-col">
        <div
          onClick={() => {
            setisOpen(true);
          }}
          className={clsx(
            "flex cursor-pointer flex-row gap-[8px] h-[40px] w-full items-center rounded-[8px] px-3",
            "hover:bg-slate-500/20 active:bg-slate-500/40 "
          )}
        >
          <Trash2 size={18} />
          <span className="flex text-black font-medium text-[15px]">
            Supprimer
          </span>
        </div>
      </div>
      <ConfirmActionModal
        open={isOpen}
        message="Voulez-vous vraiment supprimer ?"
        confirm={confirm}
        handleClose={close}
      />
    </React.Fragment>
  );
};

export default LocalityTeamActions;
