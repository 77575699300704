import React, { ReactNode } from "react";

type Props = {
  isScrollable?: boolean;
  children?: ReactNode;
};

const ScreenContentLayout = ({ children, isScrollable = true }: Props) => {
  return (
    <div
      className={`flex flex-1 flex-col w-full bg-slate-100 ${
        isScrollable ? "overflow-y-auto" : ""
      }`}
    >
      <div
        className={`flex flex-1 flex-col px-5 py-4 relative ${
          isScrollable ? "h-auto pb-[100px]" : ""
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default ScreenContentLayout;
