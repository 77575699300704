import React from "react";
import ScreenHeader, {
  ScreenHeaderActionItem,
  ScreenHeaderActionRefresh,
} from "../../../../../components/screen_header";
import ScreenContentLayout from "../../../../../components/screen_content_layout";
import { Plus } from "lucide-react";
import TableDataAutoList from "../../../../../components/table";
import { useQuery } from "@tanstack/react-query";
import { useAdminDataStore } from "../../../../../configs/stores/admindataStore";
import { useAuthStore } from "../../../../../configs/stores/auth";
import MdvieActions from "./actions";
import MdvieOfficeNewModal from "../../../../../components/modals/models/mdvieoffice_new";

const DashMdvieOfficesScreen = () => {
  const { isAuthenticated } = useAuthStore();
  const [isOpen, setisOpen] = React.useState(false);
  function openCloseModal() {
    setisOpen(!isOpen);
  }
  const { mdvieOffices, isRefetchingMdvieOffices, refetchMdvieOffices } =
    useAdminDataStore();
  useQuery({
    enabled: isAuthenticated,
    queryFn: refetchMdvieOffices,
    queryKey: ["mdvieOffices"],
    staleTime: 2 * 60 * 1000, // 5 minutes in milliseconds
    refetchInterval: 2 * 60 * 1000, // 5 minutes in milliseconds
  });
  const columns = [
    {
      label: "Représentation",
      slug: "name",
    },
    {
      label: "Pays",
      slug: "country",
    },
  ];
  return (
    <React.Fragment>
      <div className="flex flex-1 flex-col h-full w-full">
        <ScreenHeader title="Représentation Messages De Vie">
          <ScreenHeaderActionItem
            isLoading={isRefetchingMdvieOffices}
            title="Ajouter"
            icon={<Plus size={20} fill="black" />}
            onClick={openCloseModal}
          />
          <ScreenHeaderActionRefresh
            onClick={refetchMdvieOffices}
            isLoading={isRefetchingMdvieOffices}
          />
        </ScreenHeader>
        <ScreenContentLayout>
          <TableDataAutoList
            columns={columns}
            rows={mdvieOffices.map((e) => ({
              item: e,
              ...e,
              country:
                (e?.countries ?? []).map((e: any) => {
                  return e.name;
                }).join(", ") ?? "Aucune pays",
            }))}
            actions={MdvieActions}
          />
        </ScreenContentLayout>
      </div>
      <MdvieOfficeNewModal open={isOpen} handleClose={openCloseModal} />
    </React.Fragment>
  );
};

export default DashMdvieOfficesScreen;
