import React from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Undo2 } from "lucide-react";
import ParticipationFormPage from "./participation";
import { useBasicsStore } from "../../configs/stores/basics";

const ParticipationPage = () => {
  // Get the search params
  const [searchParams] = useSearchParams();
  // Retrieve specific search values
  const id = searchParams.get("id");
  const { getParticipant } = useBasicsStore();
  async function fetchData() {
    if (!!!id) {
      return null;
    }
    return getParticipant(id);
  }
  const { isLoading, data } = useQuery({
    enabled: !!id,
    queryFn: fetchData,
    queryKey: ["participant", id],
    staleTime: 1 * 60 * 1000, // 5 minutes in milliseconds
    refetchInterval: 1 * 60 * 1000, // 5 minutes in milliseconds
  });
  return (
    <React.Fragment>
      {isLoading ? (
        <div className="flex flex-1 items-center justify-center">
          <span className="flex text-[23px] font-medium">
            Chargement de votre demande...
          </span>
        </div>
      ) : !!data?.success ? (
        <ParticipationFormPage participant={data?.data} />
      ) : (
        <div className="flex flex-col flex-1 gap-3">
          <span className="flex text-[22px] font-medium">
            Requête Incorrecte
          </span>
          <a
            href="/"
            className="flex flex-row items-center gap-2 text-[16px] underline text-primary active:text-active hover:text-hoverå"
          >
            <Undo2 size={18} />
            Afficher le formulaire
          </a>
        </div>
      )}
    </React.Fragment>
  );
};

export default ParticipationPage;
