import React from "react";
import WelcomeInfoScreen from "./info";
import WelcomeStat from "./stats";
import WelcomeLocalities from "./localities";

const MissionWelcomeScreen = () => {
  return (
    <div className="flex flex-col gap-5">
      <WelcomeInfoScreen />
      <WelcomeStat />
      <WelcomeLocalities />
    </div>
  );
};

export default MissionWelcomeScreen;
