import React from "react";

type Props = {
  title?: string;
  value?: string;
  url?: string;
};

const DashWelcomeScreenItem = ({ title, value="0", url="/" }: Props) => {
  return (
    <div className="flex w-[250px] h-[150px] p-5 flex-col gap-4 bg-white rounded-[10px] border-black/10">
      <span className="flex text-[32px] font-medium select-none">{value}</span>
      <a href={url} className="flex cursor-pointer">
        <span className="flex text-[20px] font-semibold">{title}</span>
      </a>
    </div>
  );
};

export default DashWelcomeScreenItem;
