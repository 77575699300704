import React from "react";
import ModalCustom from "../..";
import FormMessage from "../../../form_components/message";
import SubmitForm from "../../../form_components/submit";
import { v4 as uuIdv4 } from "uuid";
import {
  useAdminDataStore,
} from "../../../../configs/stores/admindataStore";
import SelectInputForm from "../../../form_components/select_input";
import TextInputForm from "../../../form_components/text_input";
import PhoneInputForm from "../../../form_components/phone_input";

type Props = {
  item: any;
  handleClose: () => void;
  open: boolean;
};

const MissionaryUpdateModal = ({
  item,
  open = false,
  handleClose = () => {},
}: Props) => {
  const [haveOtherChurch, setHaveOtherChurch] = React.useState(
    !!item?.otherchurch
  );
  const [formKey, setFormKey] = React.useState(uuIdv4());
  const { updateUser, churchs, countries, departments } = useAdminDataStore();
  const [closable] = React.useState(true);
  const [isLoading, setisLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<{
    [x: string]: any;
  }>({
    general: null,
  });
  function checkForm(form: any) {
    let errorOccured = false;
    // lastname
    if (form.lastname.length < 2) {
      errorOccured = true;
      setErrors((e) => ({ ...e, lastname: true }));
    } else {
      setErrors((e) => ({ ...e, lastname: null }));
    } // firstname
    if (form.firstname.length < 2) {
      errorOccured = true;
      setErrors((e) => ({ ...e, firstname: true }));
    } else {
      setErrors((e) => ({ ...e, firstname: null }));
    } // country
    if (form.country.length === 0) {
      errorOccured = true;
      setErrors((e) => ({ ...e, country: true }));
    } else {
      setErrors((e) => ({ ...e, country: null }));
    } // church
    if (form.church.length === 0) {
      errorOccured = true;
      setErrors((e) => ({ ...e, church: true }));
    } else {
      setErrors((e) => ({ ...e, church: null }));
    } // otherchurch
    if (haveOtherChurch && form.otherchurch.length === 0) {
      errorOccured = true;
      setErrors((e) => ({ ...e, otherchurch: true }));
    } else {
      setErrors((e) => ({ ...e, otherchurch: null }));
    }
    return errorOccured;
  }
  function updateCheckForm(e: any) {
    e.preventDefault();
    const formInput = e.target;
    if (!formInput) {
      return;
    }
    if (!!errors.general) {
      setErrors((e) => ({ ...e, general: null }));
    }
    setErrors((e) => ({ ...e, [formInput.name]: null }));
  }
  function onSubmitForm(e: any) {
    e.preventDefault();
    if (!item?.id) {
      setErrors((e) => ({ ...e, general: "Formulaire incorrecte !" }));
      return;
    }
    const form = e.target.elements;
    if (errors.general !== null) {
      setErrors((e) => ({ ...e, general: null }));
    }
    if (form === null) {
      setErrors((e) => ({ ...e, general: "Renseigner le formulaire !" }));
      return;
    }
    const formValues = {
      lastname: form.lastname.value,
      firstname: form.firstname.value,
      country: form.country.value,
      church: form.church.value,
      otherchurch: haveOtherChurch ? form.otherchurch.value : null,
      department: form.department.value,
      phone: form.phone.value,
    };
    if (checkForm(formValues)) {
      console.log("Error Occured !!!");
      return;
    }
    function getValue(t: any) {
      let value = JSON.parse(t)?.value ?? "none";
      return value !== "none" ? value : null;
    }
    const formDatas = {
      lastname: form.lastname.value,
      firstname: form.firstname.value,
      country: getValue(form.country.value),
      church: !haveOtherChurch ? getValue(form.church.value) : null,
      otherchurch: haveOtherChurch ? form.otherchurch.value : "",
      department: getValue(form.department.value),
      phone: form.phone.value,
    };
    setErrors((e) => ({
      ...e,
      general: "",
    }));
    setisLoading(true);
    updateUser(item.id, formDatas).then((res: any) => {
      setisLoading(false);
      if (res.success) {
        setFormKey(uuIdv4());
        setErrors({
          general: null,
        });
        handleClose();
      } else {
        setErrors((e) => ({
          ...e,
          general: res.message.toString(),
        }));
      }
    });
  }
  React.useEffect(() => {
    if (!open) {
      setFormKey(uuIdv4());
      setErrors({
        general: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  const egliseCentre = churchs.filter((e) =>
    e.name.toLowerCase().startsWith("c")
  );
  return (
    <ModalCustom open={open} handleClose={closable ? handleClose : undefined}>
      <div className="flex flex-col w-[400px] p-3 pt-0 space-y-2 items-start justify-center">
        <span className="flex text-[20px] font-medium">
          Modifier Missionnaire
        </span>
        <form
          key={formKey}
          className="flex flex-col w-full px-1 gap-2 max-h-[80vh] overflow-y-auto"
          onSubmit={!isLoading ? onSubmitForm : undefined}
          onChange={updateCheckForm}
        >
          <TextInputForm
            title={"Nom"}
            name={"lastname"}
            defaultValue={item.lastname}
            error={!!errors.lastname}
          />
          <TextInputForm
            title={"Prénoms"}
            name={"firstname"}
            defaultValue={item.firstname}
            error={!!errors.firstname}
          />
          <SelectInputForm
            title={"Pays"}
            name={"country"}
            defaultValue={item.country?.id ?? undefined}
            error={!!errors.country}
            options={countries
              .filter((e) => e.active)
              .map((item) => ({
                value: item.id,
                label: item.name,
              }))}
            onChange={(_) => {
              setErrors((e) => ({ ...e, country: null }));
            }}
          />
          <SelectInputForm
            title={"Église"}
            name={"church"}
            defaultValue={
              !haveOtherChurch ? item.church?.id ?? undefined : "otherchurch"
            }
            error={!!errors.church}
            options={egliseCentre
              .concat(
                churchs.filter(
                  (e) =>
                    !egliseCentre
                      .map((i) => i.id.toString())
                      .includes(e.id.toString())
                )
              )
              .map((item) => ({
                value: item.id,
                label: item.name,
              }))
              .concat({
                value: "otherchurch",
                label: "Autre Église",
              })}
            onChange={(_) => {
              if (_) {
                const v = _.value;
                if (v === "otherchurch") {
                  setHaveOtherChurch(true);
                } else {
                  if (haveOtherChurch) {
                    setHaveOtherChurch(false);
                  }
                }
              }
              setErrors((e) => ({ ...e, church: null }));
            }}
          />
          {haveOtherChurch && (
            <TextInputForm
              key={"otherchurch"}
              title={"Préciser le nom de l'église"}
              name={"otherchurch"}
              defaultValue={item.otherchurch}
              error={!!errors.otherchurch}
            />
          )}
          <SelectInputForm
            title={"Département"}
            name={"department"}
            error={!!errors.department}
            defaultValue={item.department?.id ?? undefined}
            options={departments.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            onChange={(_) => {
              setErrors((e) => ({ ...e, department: null }));
            }}
          />
          <PhoneInputForm
            title={"Contact Téléphonique"}
            subTitle={"Préciser l’indicatif du pays (Ex: +000)"}
            name={"phone"}
            defaultValue={item.phone}
            error={!!errors.phone}
          />
          {errors.general && (
            <FormMessage message={errors.general} styleMessage={"error"} />
          )}
          <SubmitForm isLoading={isLoading} title="Enregistrer" />
        </form>
      </div>
    </ModalCustom>
  );
};

export default MissionaryUpdateModal;
