import React from "react";
import ScreenHeader from "../../../../components/screen_header";
import ScreenContentLayout from "../../../../components/screen_content_layout";
import { Outlet } from "react-router-dom";

const DashMyMissionsScreen = () => {
  return (
    <div className="flex flex-1 flex-col h-full w-full">
      <ScreenHeader
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="flex"
          >
            <rect width="8" height="4" x="8" y="2" rx="1" ry="1" />
            <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />
            <path d="M12 11h4" />
            <path d="M12 16h4" />
            <path d="M8 11h.01" />
            <path d="M8 16h.01" />
          </svg>
        }
        title="Mes missions"
      />
      <ScreenContentLayout>
        <Outlet />
      </ScreenContentLayout>
    </div>
  );
};

export default DashMyMissionsScreen;
