import clsx from "clsx";
import React from "react";
import { NavLink } from "react-router-dom";

const MissionMenu = () => {
  const menus = [
    {
      title: "Générale",
      url: "",
    },
    {
      title: "Missionnaires",
      url: "missionaries",
    },
  ];
  return (
    <div className="flex flex-row overflow-x-auto bg-white items-end mb-4 rounded-t-[10px] pt-4 w-full border-b border-b-black/10 px-[17px]">
      {menus.map((menu, index) => {
        return <MissionMenuItem key={index} item={menu} />;
      })}
    </div>
  );
};

type MissionMenuProps = {
  item: any;
};

const MissionMenuItem = ({ item }: MissionMenuProps) => {
  return (
    <NavLink
      to={item.url}
      end={item.url===""}
      className={({ isActive }) => {
        return clsx(
          "flex flex-col pt-3 transition-all pb-2 px-2 w-[130px] items-center rounded-t-[8px] border-b-[4px]",
          isActive
            ? "hover:border-primary bg-primary/10 hover:bg-primary/15 border-primary text-primary"
            : "hover:border-hover/10 hover:bg-hover/5"
        );
      }}
    >
      {({ isActive }) => (
        <>
          <span className="flex font-medium text-[16px]">
            {item.title}
          </span>
        </>
      )}
    </NavLink>
  );
};

export default MissionMenu;
