import React from "react";
import ModalCustom from "../..";
import FormMessage from "../../../form_components/message";
import SubmitForm from "../../../form_components/submit";
import { v4 as uuIdv4 } from "uuid";
import {
  InterviewMeetType,
  useAdminDataStore,
} from "../../../../configs/stores/admindataStore";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import DateInputFrom from "../../../form_components/date_input";
import moment from "moment";
import SelectInputForm from "../../../form_components/select_input";
import TextInputForm from "../../../form_components/text_input";
// import TextInputForm from "../../../form_components/text_input";
// import NumberInputForm from "../../../form_components/number_input";

type Props = {
  handleClose: () => void;
  open: boolean;
};

const InterviewDateNewModal = ({
  open = false,
  handleClose = () => {},
}: Props) => {
  const queryClient = useQueryClient();
  const { id } = useParams();
  const [formKey, setFormKey] = React.useState(uuIdv4());
  const { addInterviewDate } = useAdminDataStore();
  const [closable] = React.useState(true);
  const [isLoading, setisLoading] = React.useState(false);
  const [isOnline, setIsOnline] = React.useState(false);
  const [errors, setErrors] = React.useState<{
    [x: string]: any;
  }>({
    general: null,
  });
  function checkForm(form: any) {
    let errorOccured = false;
    // type
    if (form.type.length < 2) {
      errorOccured = true;
      let msg = "Type incorrecte";
      setErrors((e) => ({ ...e, type: msg }));
    } else {
      setErrors((e) => ({ ...e, type: null }));
    }
    // date
    if (form.date.length === 0) {
      errorOccured = true;
      setErrors((e) => ({ ...e, date: true }));
    } else {
      setErrors((e) => ({ ...e, date: null }));
    }
    if (isOnline) {
      // location
      if (form.location.length < 2) {
        errorOccured = true;
        let msg = "Lien incorrecte";
        setErrors((e) => ({ ...e, location: msg }));
      } else {
        setErrors((e) => ({ ...e, location: null }));
      }
    }
    return errorOccured;
  }
  function updateCheckForm(e: any) {
    e.preventDefault();
    const formInput = e.target;
    if (!formInput) {
      return;
    }
    if (!!errors.general) {
      setErrors((e) => ({ ...e, general: null }));
    }
    setErrors((e) => ({ ...e, [formInput.name]: null }));
  }
  function onSubmitForm(e: any) {
    if (!id) {
      alert("Recharger la page !");
      return;
    }
    e.preventDefault();
    const form = e.target.elements;
    if (errors.general !== null) {
      setErrors((e) => ({ ...e, general: null }));
    }
    if (form === null) {
      setErrors((e) => ({ ...e, general: "Renseigner le formulaire !" }));
      return;
    }
    const formValues = {
      date: form.date.value,
      type: form.type.value,
      ...(isOnline && !!form?.location?.value
        ? { location: form.location.value }
        : {}),
    };
    if (checkForm(formValues)) {
      console.log("Error Occured !!!");
      return;
    }
    function getValue(t: any) {
      let value = JSON.parse(t)?.value ?? "none";
      return value !== "none" ? value : null;
    }
    const formDatas = {
      date: moment(form.date.value, "LL").toDate(),
      type: getValue(form.type.value),
      ...(isOnline && !!form?.location?.value
        ? { location: form.location.value }
        : {}),
    };
    setErrors((e) => ({
      ...e,
      general: "",
    }));
    setisLoading(true);
    addInterviewDate(id, formDatas).then((res: any) => {
      setisLoading(false);
      if (res.success) {
        queryClient.setQueryData(["interviews", id], res.data);
        setFormKey(uuIdv4());
        setErrors({
          general: null,
        });
        handleClose();
      } else {
        setErrors((e) => ({
          ...e,
          general: res.message.toString(),
        }));
      }
    });
  }
  React.useEffect(() => {
    if (!open) {
      setFormKey(uuIdv4());
      setErrors({
        general: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  return (
    <ModalCustom open={open} handleClose={closable ? handleClose : undefined}>
      <div className="flex flex-col w-[400px] p-3 pt-0 space-y-2 items-start justify-center">
        <span className="flex text-[20px] font-medium">
          Ajouter un jour d'entretien
        </span>
        <form
          key={formKey}
          className="flex flex-col w-full px-1 gap-3 max-h-[80vh] overflow-y-auto"
          onSubmit={!isLoading ? onSubmitForm : undefined}
          onChange={updateCheckForm}
        >
          <DateInputFrom
            title={"Jour d'entretien"}
            name={"date"}
            error={!!errors.date}
            onChange={(_) => {
              setErrors((e) => ({ ...e, date: null }));
            }}
          />
          <SelectInputForm
            title={"Type"}
            name={"type"}
            error={!!errors.type}
            defaultValue="FACETOFACE"
            options={Object.entries(InterviewMeetType).map((item) => ({
              value: item[0],
              label: item[1],
            }))}
            onChange={(_) => {
              if (_) {
                const v = _.value;
                if (v === "ONLINE") {
                  setIsOnline(true);
                } else {
                  setIsOnline(false);
                }
              }
              setErrors((e) => ({ ...e, type: null }));
            }}
          />
          {isOnline && (
            <TextInputForm
              title={"Lien en ligne"}
              name={"location"}
              placeholder="Entrer le lien de la reunion"
              error={!!errors.location}
            />
          )}
          {errors.general && (
            <FormMessage message={errors.general} styleMessage={"error"} />
          )}
          <SubmitForm isLoading={isLoading} title="Enregistrer" />
        </form>
      </div>
    </ModalCustom>
  );
};

export default InterviewDateNewModal;
