import React from "react";
import ScreenContentLayout from "../../../../components/screen_content_layout";

const DashboardAdminUserNotAuthorizedPage = () => {
  return (
    <div className="flex flex-1 justify-center items-center flex-col h-full w-full">
      <ScreenContentLayout>
        <div className="flex flex-1 justify-center items-center flex-col h-full w-full">
          <div className="flex h-[250px] w-[400px] px-5 justify-center items-center bg-white rounded-[15px]">
            <span className="flex text-[24px] text-center font-semibold">
              Vous n’êtes pas autorisé à accéder à cette page !
            </span>
          </div>
        </div>
      </ScreenContentLayout>
    </div>
  );
};

export default DashboardAdminUserNotAuthorizedPage;
