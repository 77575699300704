import axios from "axios";
import { getUrl, postUrl } from "../../apiRequest";

export function getAllBasic() {
  return getUrl(
    "/all_basics?lists=countries,churchs,mdvieOffices,missions,departments"
  );
}

export function confirmReinitForgotPassword(credentials: any) {
  return postUrl("/auth/reinitpassword/confirm", credentials);
}

export function sendReinitForgotPasswordLink(credentials: any) {
  return postUrl("/auth/reinitpassword", credentials);
}

export function registrationAspirant(credentials: any) {
  return postUrl("/auth/registration/aspirant", credentials);
}

export function getParticipant(id: string) {
  return getUrl("/participant/findone?id=" + id);
}

export function registrationParticipant(credentials: any) {
  return postUrl("/auth/registration/participation", credentials);
}

export function updateRegistrationParticipant(id:string,credentials: any) {
  return postUrl("/auth/registration/participation/update?id="+id, credentials);
}

export function getMissionary(id: string) {
  return getUrl("/user/info?id=" + id);
}

export function getDefaultDatas() {
  return getUrl("/default/all");
}

export function getAllLangs() {
  return getUrl("/default/langs");
}

export function getDestinationFavourites(country?: string | null) {
  return getUrl("/destination/favourites");
}

export function getDestinationCapitals() {
  return getUrl("/destination/capitals");
}

export function getUserCountry() {
  return new Promise<any>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        axios
          .get(
            `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
          )
          .then((reponse) => {
            resolve(reponse.data);
          });
      },
      (error) => {
        // error.
        // console.error(error);
      },
      {
        enableHighAccuracy: true,
      }
    );
  });
}
