import React from "react";
import ScreenHeader from "../../../../components/screen_header";
import ScreenContentLayout from "../../../../components/screen_content_layout";
import { useAuthStore } from "../../../../configs/stores/auth";
import DashWelcomeScreenCard from "./card";
import { ChartLine, HandCoins, Wallet } from "lucide-react";
import { useNavigate } from "react-router-dom";
import ButtonCard from "../../../../components/button_card";

const DashWelcomeScreen = () => {
  const { user } = useAuthStore();
  const navigate = useNavigate();
  return (
    <div className="flex flex-1 flex-col h-full w-full">
      <ScreenHeader
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="flex"
          >
            <path d="M15 21v-8a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v8" />
            <path d="M3 10a2 2 0 0 1 .709-1.528l7-5.999a2 2 0 0 1 2.582 0l7 5.999A2 2 0 0 1 21 10v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
          </svg>
        }
        title="Accueil"
      ></ScreenHeader>
      <ScreenContentLayout>
        <div className="flex flex-col gap-6">
          <span className="flex text-[25px] text-black font-medium">
            Bienvenue {user.firstname + " " + user.lastname}
          </span>
          <div className="flex p-4 rounded-[10px] bg-white w-fit">
            <span className="flex text-[18px] text-black font-medium">
              Découvrez votre espace. Ici, vous pouvez facilement demander à
              participer aux campagnes d’évangélisation.
            </span>
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex flex-row gap-3">
              <ChartLine size={24} color="black" />
              <span className="flex text-[18px] text-black font-medium">
                Mes Statistiques
              </span>
            </div>
            <div className="flex flex-row gap-4 flex-wrap">
              <DashWelcomeScreenCard text="Mon épargne" value="0" />
              <DashWelcomeScreenCard text="Mes participations" value="0" />
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <span className="flex text-[18px] text-black font-medium">
              Actions disponibles
            </span>
            <div className="flex flex-row gap-4 flex-wrap">
              <ButtonCard
                text="Faire un Don"
                icon={<HandCoins size={60} color="white" />}
                onClick={()=>{
                  navigate("./don")
                }}
              />
              <ButtonCard
                text="Épargner"
                icon={<Wallet color="white" size={60} />}
                onClick={()=>{
                  navigate("./wallet")
                }}
              />
              <ButtonCard
                text="Faire une demande"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="60"
                    height="60"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="flex"
                  >
                    <rect width="8" height="4" x="8" y="2" rx="1" ry="1" />
                    <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />
                    <path d="M12 11h4" />
                    <path d="M12 16h4" />
                    <path d="M8 11h.01" />
                    <path d="M8 16h.01" />
                  </svg>
                }
                onClick={()=>{
                  navigate("./mymissions")
                }}
              />
            </div>
          </div>
          <div className="flex flex-col gap-3 mt-5">
            <span className="flex text-[15px] text-black">
              Mes cinq (5) dernières récentes participations aux missions
            </span>
            <div className="flex flex-col bg-white min-h-[55px] rounded-[10px] max-w-[1300px]">
              <span className="flex flex-col pl-4 select-none h-[50px] justify-center text-[16px] text-black/30 font-light">
                Désolé, vous n’avez encore participé à aucune mission.
              </span>
            </div>
          </div>
        </div>
      </ScreenContentLayout>
    </div>
  );
};

export default DashWelcomeScreen;
