import React from "react";
import ScreenHeader, {
  ScreenHeaderActionRefresh,
} from "../../../../../components/screen_header";
import ScreenContentLayout from "../../../../../components/screen_content_layout";
import DepartmentNewModal from "../../../../../components/modals/models/department_new";
import ScreenContentLayoutMenu from "../../../../../components/screen_content_layout/menu";
import { Outlet } from "react-router-dom";

const DashNotificationsScreen = () => {
  const [isOpen, setisOpen] = React.useState(false);
  function openCloseModal() {
    setisOpen(!isOpen);
  }
  const menus = [
    {
      title: "Géneral",
      url: "",
    },
    {
      title: "SMS",
      url: "sms",
    },
    {
      title: "Email",
      url: "email",
    },
  ];
  return (
    <React.Fragment>
      <div className="flex flex-1 flex-col h-full w-full">
        <ScreenHeader title="Notifications">
          <ScreenHeaderActionRefresh onClick={() => {}} isLoading={false} />
        </ScreenHeader>
        <ScreenContentLayout>
          <ScreenContentLayoutMenu menus={menus} />
          <Outlet />
        </ScreenContentLayout>
      </div>
      <DepartmentNewModal open={isOpen} handleClose={openCloseModal} />
    </React.Fragment>
  );
};

export default DashNotificationsScreen;
