import clsx from "clsx";
import { MoveRight } from "lucide-react";
import moment from "moment";
import { capitalizeAllWords } from "../../../../../../../constants";

type Props = {
  item?: any;
  state?: "passed" | "next";
};

const DashMissionSubScribeItem = ({ item, state = "passed" }: Props) => {
  return (
    <div className="flex p-5 flex-col gap-3 justify-between bg-white rounded-[10px] border-black/10">
      <div className="flex flex-row gap-3">
        <div className="flex px-2 gap-1 h-[25px] flex-row items-center justify-center rounded-[4px] bg-primary/30 w-fit">
          <span className="flex text-[13px] text-primary font-medium">
            Mission
          </span>
          <span className="flex text-[13px] text-primary font-bold">
            {item.type}
          </span>
        </div>
        <span className="flex text-[18px] font-medium select-none line-clamp-1 phone:line-clamp-3">
          {item.name}
        </span>
      </div>
      <div className="flex flex-col gap-2">
        <LocalityItemInfo
          title="Départ"
          value={moment(item.teams_departure).format("LL")}
        />
        <LocalityItemInfo
          title="Retour"
          value={moment(item.teams_return).format("LL")}
        />
        <LocalityItemInfo title="Pays" value={item?.country?.name ?? ""} />
        <div className="flex flex-col gap-2">
          <span className="flex text-[14px] text-black/50">
            Villes cibles :
          </span>
          <div className="flex flex-row flex-wrap gap-3 w-full pb-2 overflow-x-auto">
            {(item?.localities ?? []).map((e: any, i: any) => {
              return <LocalityItem key={i} item={e} />;
            })}
            {(item?.localities ?? [])?.length === 0 && (
              <span className="flex text-black/50 text-[13.5px] select-none">
                Aucunes villes enregistrées pour l'instant !
              </span>
            )}
          </div>
        </div>
      </div>
      {["disponible", "next"].includes(state ?? "") && (
        <div
          className={clsx(
            "flex select-none h-[40px] max-w-[300px] rounded-[8px] transition-all duration-200 flex-row justify-center items-center px-3 py-2 gap-x-2",
            "bg-primary cursor-pointer hover:bg-primary/75 active:bg-primary/85 text-white"
          )}
        >
          <span className="flex text-[14px] font-semibold">
            Demander une participation
          </span>
        </div>
      )}
    </div>
  );
};

type InfoItemProps = {
  title: string;
  value: string;
};

const LocalityItemInfo = ({ title, value }: InfoItemProps) => {
  return (
    <div className="flex flex-row gap-2 items-center">
      <span className="flex text-[14px] text-black/50">{title} :</span>
      <span className="flex text-[14px]">{value}</span>
    </div>
  );
};

type LocalityItemItemProps = {
  item?: any;
};

const LocalityItem = ({ item }: LocalityItemItemProps) => {
  return (
    <div className="flex flex-col justify-between h-[55px] min-w-[150px] max-w-[350px] w-fit px-[10px] py-2 gap-1 border-[1px] rounded-[10px] border-black/20">
      <span className="flex text-[14.5px] pt-[1px] line-clamp-1 font-semibold leading-none text-ellipsis">
        {item.name}
      </span>
      <div className="flex flex-row gap-4 items-center justify-between">
        <span className="flex text-[12px] leading-none">
          {capitalizeAllWords(moment(item?.start).format("ll"))}
        </span>
        <MoveRight size={15} color="black" />
        <span className="flex text-[12px] leading-none">
          {capitalizeAllWords(moment(item?.end).format("ll"))}
        </span>
      </div>
    </div>
  );
};

export default DashMissionSubScribeItem;
