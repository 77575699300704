import React from "react";
import ScreenHeader, {
  ScreenHeaderActionRefresh,
} from "../../../../../components/screen_header";
import ScreenContentLayout from "../../../../../components/screen_content_layout";
import { useQuery } from "@tanstack/react-query";
import { useAdminDataStore } from "../../../../../configs/stores/admindataStore";
import { useAuthStore } from "../../../../../configs/stores/auth";
import DashStatsScreenItem from "./item";

const DashStatsScreen = () => {
  const { isAuthenticated } = useAuthStore();
  const { stats, isRefetchingStats, refetchStats } = useAdminDataStore();
  useQuery({
    enabled: isAuthenticated,
    queryFn: refetchStats,
    queryKey: ["stats"],
    staleTime: 2 * 60 * 1000, // 5 minutes in milliseconds
    refetchInterval: 2 * 60 * 1000, // 5 minutes in milliseconds
  });
  return (
    <div className="flex flex-1 flex-col h-full w-full">
      <ScreenHeader
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="flex"
          >
            <path d="M15 21v-8a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v8" />
            <path d="M3 10a2 2 0 0 1 .709-1.528l7-5.999a2 2 0 0 1 2.582 0l7 5.999A2 2 0 0 1 21 10v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
          </svg>
        }
        title="Tableau de bord"
      >
        <ScreenHeaderActionRefresh
          isLoading={isRefetchingStats}
          onClick={refetchStats}
        />
      </ScreenHeader>
      <ScreenContentLayout>
        <div className="flex flex-row flex-wrap gap-5">
          <DashStatsScreenItem
            title={"Representations"}
            value={stats.mdvieOffices}
            url="mdvieoffices"
          />
          <DashStatsScreenItem
            title={"Missions"}
            value={stats.missions}
            url="missions"
          />
          <DashStatsScreenItem
            title={"Missionnaires"}
            value={stats.users}
            url="missionaries"
          />
          <DashStatsScreenItem
            title={"Departements"}
            value={stats.departments}
            url="departments"
          />
          <DashStatsScreenItem
            title={"Églises"}
            value={stats.churchs}
            url="churchs"
          />
        </div>
      </ScreenContentLayout>
    </div>
  );
};

export default DashStatsScreen;
