import React from "react";
import { useAdminDataStore } from "../../../../../configs/stores/admindataStore";
import InterviewsScreenDefaultHeader from "./header";
import InterviewItem from "./interview";

const DashInterviewsScreenDefault = () => {
  const { interviews } = useAdminDataStore();
  return (
    <React.Fragment>
      <div className="flex flex-1 flex-col h-full w-full">
        <InterviewsScreenDefaultHeader />
        <div className="flex flex-col gap-5 pt-5">
          {interviews?.map((e: any, index: any) => {
            return <InterviewItem key={index} item={e} />;
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default DashInterviewsScreenDefault;
