import React from "react";
import ScreenContentLayout from "../../../../../../components/screen_content_layout";
import PasswordInputForm from "../../../../../../components/form_components/password_input";
import FormMessage from "../../../../../../components/form_components/message";
import SubmitForm from "../../../../../../components/form_components/submit";
import { isPassword } from "../../../../../../constants";
import { useAuthStore } from "../../../../../../configs/stores/auth";
import { v4 as uuIdv4 } from "uuid";

const DashProfilScreenUpdatePassword = () => {
  const [formKey, setFormKey] = React.useState(uuIdv4());
  const { updatePassword } = useAuthStore();
  const [isLoading, setIsLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<{
    [x: string]: any;
  }>({
    old: null,
    new: null,
    confirm: null,
    general: null,
  });
  function checkForm(form: any) {
    let errorOccured = false;
    // old
    if (form.old.length === 0) {
      errorOccured = true;
      let msg = "Incorrecte";
      setErrors((e) => ({ ...e, old: msg }));
    } else {
      setErrors((e) => ({ ...e, old: null }));
    }
    // new
    if (form.new.length === 0 || !isPassword(form.new)) {
      errorOccured = true;
      let msg = "Incorrecte";
      setErrors((e) => ({ ...e, new: msg }));
    } else {
      setErrors((e) => ({ ...e, new: null }));
    }
    // confirm
    if (form.confirm.length === 0 || !isPassword(form.confirm)) {
      errorOccured = true;
      let msg = "Incorrecte";
      setErrors((e) => ({ ...e, confirm: msg }));
    } else {
      setErrors((e) => ({ ...e, confirm: null }));
    }
    if (
      isPassword(form.new) &&
      isPassword(form.confirm) &&
      form.new !== form.confirm
    ) {
      errorOccured = true;
      let msg = "Confirmation du mot de passe incorrecte";
      setErrors((e) => ({ ...e, general: msg }));
    } else {
      setErrors((e) => ({ ...e, general: null }));
    }
    return errorOccured;
  }
  function updateCheckForm(e: any) {
    e.preventDefault();
    const formInput = e.target;
    if (!formInput) {
      return;
    }
    if (!!errors.general) {
      setErrors((e) => ({ ...e, general: null }));
    }
    setErrors((e) => ({ ...e, [formInput.name]: null }));
  }
  function onSubmitForm(e: any) {
    e.preventDefault();
    const form = e.target.elements;
    if (errors.general !== null) {
      setErrors((e) => ({ ...e, general: null }));
    }
    if (form === null) {
      setErrors((e) => ({ ...e, general: "Renseigner le formulaire !" }));
      return;
    }
    const formValues = {
      old: form.old.value,
      new: form.new.value,
      confirm: form.confirm.value,
    };
    if (checkForm(formValues)) {
      console.log("Error Occured !!!");
      return;
    }
    setErrors((e) => ({
      ...e,
      general: "",
    }));

    const formData = {
      old: form.old.value,
      new: form.new.value,
    };
    setIsLoading(true);
    updatePassword(formData).then((res: any) => {
      if (res.success) {
        setFormKey(uuIdv4());
      }
      if (!res.success) {
        setErrors((e) => ({
          ...e,
          general: res.message.toString(),
        }));
      }
      setIsLoading(false);
    });
  }
  return (
    <div className="flex flex-1 flex-col h-full w-full">
      <ScreenContentLayout>
        <div className="flex flex-col gap-y-4 min_tablet_mini:min-w-[60%] w-fit p-5 rounded-[10px] bg-white h-auto phone:w-[100%]">
          <span className="flex text-[20px] font-bold">
            Modifier votre mot de passe
          </span>
          <form
            key={formKey}
            className="flex flex-col gap-4 max-w-[500px]"
            onSubmit={!isLoading ? onSubmitForm : undefined}
            onChange={updateCheckForm}
          >
            <PasswordInputForm
              name={"old"}
              error={!!errors.old || !!errors.general}
              title={"Entrer votre ancien mot de passe"}
            />
            <span className="flex text-[15px] text-primary">
              Votre nouveau mot de passe doit comporter au minimum 10 caracteres
              et doit contenir au moins une lettre minuscule, une lettre
              majuscule et un chiffre.
            </span>
            <PasswordInputForm
              name={"new"}
              error={!!errors.new || !!errors.general}
              title={"Entrer votre nouveau mot de passe"}
            />
            <PasswordInputForm
              name={"confirm"}
              error={!!errors.confirm || !!errors.general}
              title={"Confirmer le nouveau mot de passe"}
            />
            {errors.general && (
              <FormMessage message={errors.general} styleMessage={"error"} />
            )}
            <SubmitForm
              isLoading={isLoading}
              title="Enregistrer la modification"
            />
          </form>
        </div>
      </ScreenContentLayout>
    </div>
  );
};

export default DashProfilScreenUpdatePassword;
