import React from "react";
import ScreenHeader, {
  ScreenHeaderActionItem,
  ScreenHeaderActionRefresh,
} from "../../../../../components/screen_header";
import ScreenContentLayout from "../../../../../components/screen_content_layout";
import TableDataAutoList from "../../../../../components/table";
import { useAdminDataStore } from "../../../../../configs/stores/admindataStore";
import { useQuery } from "@tanstack/react-query";
import { useAuthStore } from "../../../../../configs/stores/auth";
import UserActions from "./actions";
import { Plus } from "lucide-react";
import MissionnaireNewModal from "../../../../../components/modals/models/missionnaire_new";

const DashUsersScreen = () => {
  const { isAuthenticated } = useAuthStore();
  const { users, isRefetchingUsers, refetchUsers } = useAdminDataStore();
  const [isOpenNewUser, setisOpenNewUser] = React.useState(false);
  function openCloseModal() {
    setisOpenNewUser(!isOpenNewUser);
  }
  useQuery({
    enabled: isAuthenticated,
    queryFn: refetchUsers,
    queryKey: ["users"],
    staleTime: 7 * 60 * 1000, // 5 minutes in milliseconds
    refetchInterval: 10 * 60 * 1000, // 5 minutes in milliseconds
  });
  const columns = [
    {
      label: "Nom & Prénoms",
      slug: "name",
    },
    {
      label: "Contact",
      slug: "phone",
    },
    {
      label: "Adresse email",
      slug: "email",
    },
    {
      label: "Pays",
      slug: "country",
    },
    {
      label: "Église",
      slug: "church",
    },
    {
      label: "Département",
      slug: "department",
    },
    {
      label: "Grade",
      slug: "grade",
    },
  ];
  return (
    <React.Fragment>
      <div className="flex flex-1 flex-col h-full w-full">
        <ScreenHeader title="Missionnaires">
          <ScreenHeaderActionItem
            title="Ajouter nouveau"
            icon={<Plus size={20} fill="black" />}
            onClick={openCloseModal}
          />
          <ScreenHeaderActionRefresh
            onClick={refetchUsers}
            isLoading={isRefetchingUsers}
          />
        </ScreenHeader>
        <ScreenContentLayout>
          <TableDataAutoList
            columns={columns}
            rows={users
              .map((e) => ({
                item: e,
                ...e,
                name: `${e.lastname ?? ""} ${e.firstname ?? ""}`.trim(),
                country: e?.country?.name ?? "",
                department: e?.department?.name ?? "",
                church:
                  e?.church?.name ??
                  (e?.otherchurch ? "Autre: " + e.otherchurch : null) ??
                  "",
              }))
              .sort((a, b) =>
                a.name
                  .toLocaleLowerCase()
                  .localeCompare(b.name.toLocaleLowerCase(), undefined, {
                    sensitivity: "base",
                  })
              )}
            actions={UserActions}
          />
        </ScreenContentLayout>
      </div>
      <MissionnaireNewModal open={isOpenNewUser} handleClose={openCloseModal} />
    </React.Fragment>
  );
};

export default DashUsersScreen;
