import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import {
  getUserData,
  login,
  logout,
  registration,
  updatePassword,
  updateProfile,
  updateUser,
} from "./functions/auth";
import { ZustandLocalStorageCrypted } from "./utils";
import moment from "moment";

const LOCAL_DATASTORE_NAME = "missionary-auth";

type ContextProps = {
  lastRedirectedUrlToAuth: { url: string; user: string; date: number } | null;
  tokens: string | null;
  isAuthenticated: boolean;
  isLoadingSignUp: boolean;
  isLoadingLogout: boolean;
  user: any | null;
  isRefreshingUser: boolean;
  isLoadingLogin: boolean;
  login: (credentials: { email: string; password: string }) => Promise<{
    [x: string]: any;
    success: Boolean;
    message: String;
    data: any;
  }>;
  registration: (credentials: any) => Promise<{
    [x: string]: any;
    success: Boolean;
    message: String;
    data: any;
  }>;
  logout: (cb?: () => void) => Promise<void>;
  refetchUser: () => Promise<{
    [x: string]: any;
    success: Boolean;
    message: String;
    data: any;
  }>;
  refreshToken: (tokens: string) => Promise<any>;
  updateProfile: (image_data: any) => Promise<any>;
  updatePassword: (data: any) => Promise<any>;
  updateUser: (update: any) => Promise<any>;
  setlastRedirectedUrlToAuth: (user: string, url: string) => Promise<any>;
};

export const useAuthStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      user: null,
      tokens: null,
      isLoadingLogin: false,
      isAuthenticated: false,
      isLoadingSignUp: false,
      isLoadingLogout: false,
      isRefreshingUser: false,
      isLoadingLoginPhone: false,
      lastRedirectedUrlToAuth: null,
      isLoadingLoginPhoneConfirmation: false,
      refreshToken: async (tokens: string) => {
        set(
          produce((state: ContextProps) => {
            state.tokens = tokens;
          })
        );
      },
      login: async (credentials) => {
        set(
          produce((state: ContextProps) => {
            state.isLoadingLogin = true;
          })
        );
        const res = await login(credentials);
        const { success = false, data = null } = res;
        set(
          produce((state: ContextProps) => {
            if (success) {
              state.user = data;
              state.tokens = res.tokens;
            }
          })
        );
        if (success) {
          await new Promise((re, rj) => {
            setTimeout(() => {
              re("ok");
            }, 500);
          });
        }
        set(
          produce((state: ContextProps) => {
            state.isLoadingLogin = false;
            if (success) {
              state.isAuthenticated = true;
            } else {
              state.isAuthenticated = false;
            }
          })
        );
        return res;
      },
      registration: async (credentials) => {
        set(
          produce((state: ContextProps) => {
            state.isLoadingSignUp = true;
          })
        );
        const res = await registration(credentials);
        set(
          produce((state: ContextProps) => {
            state.isLoadingSignUp = false;
          })
        );
        return res;
      },
      logout: async (cb) => {
        set(
          produce((state: ContextProps) => {
            state.isLoadingLogout = true;
          })
        );
        await logout();
        set(
          produce((state: ContextProps) => {
            state.tokens = null;
            state.isLoadingLogout = false;
            state.isAuthenticated = false;
            state.user = null;
          })
        );
        !!cb && cb();
      },
      refetchUser: async () => {
        set(
          produce((state: ContextProps) => {
            state.isRefreshingUser = true;
          })
        );
        const res = await getUserData();
        const { success = false, data = null } = res;
        set(
          produce((state: ContextProps) => {
            state.isRefreshingUser = false;
            if (success) {
              state.user = data;
              if (!state.isAuthenticated) {
                state.isAuthenticated = true;
              }
            } else {
              state.isAuthenticated = false;
              state.user = null;
            }
          })
        );
        return res;
      },
      updateProfile: async (image_data) => {
        const res = await updateProfile(image_data);
        if (res.success) {
          set(
            produce((state: ContextProps) => {
              state.user = res.data;
            })
          );
        } else {
          // ToastErrorNotifier({
          //   message: "Echec de la modification de votre profile",
          // });
        }
      },
      updateUser: async (update) => {
        const res = await updateUser(update);
        if (res.success) {
          set(
            produce((state: ContextProps) => {
              state.user = res.data;
            })
          );
        }
        return res;
      },
      updatePassword: async (update) => {
        const res = await updatePassword(update);
        if (res.success) {
          set(
            produce((state: ContextProps) => {
              state.user = res.data;
            })
          );
        }
        return res;
      },
      setlastRedirectedUrlToAuth: async (user, url) => {
        localStorage.setItem("testurl", url);
        set(
          produce((state: ContextProps) => {
            state.lastRedirectedUrlToAuth = {
              url,
              user,
              date: moment().valueOf(),
            };
          })
        );
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_ath")),
    }
  )
);
