import React from "react";
import { capitalizeAllWords, isPhoneNumber } from "../../constants";
import { useBasicsStore } from "../../configs/stores/basics";
import TextInputForm from "../../components/form_components/text_input";
import PhoneInputForm from "../../components/form_components/phone_input";
import FormMessage from "../../components/form_components/message";
import SubmitForm from "../../components/form_components/submit";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@mui/material";
import { MoveRight } from "lucide-react";
import moment from "moment";
import DialogModal from "../../components/modals/models/dialog";
import SelectInputForm from "../../components/form_components/select_input";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";

type Props = {
  participant: any;
};

const ParticipationFormPage = ({ participant }: Props) => {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = React.useState(false);
  const [haveOtherChurch, setHaveOtherChurch] = React.useState(false);
  const [checkAll, setCheckAll] = React.useState(true);
  const [checksSelected, setchecksSelected] = React.useState<Array<string>>(
    participant?.selected_localities?.map((l: any) => l?.id)
  );
  const {
    countries,
    departments,
    missions,
    churchs,
    updateRegistrationParticipant,
  } = useBasicsStore();
  const [mission, setMission] = React.useState<any>(
    missions.find((m) => m?.id === participant.mission?.id)
  );
  const [meetSelected, setMeetSelected] = React.useState<string>(
    missions
      .find((m) => m?.id === participant.mission?.id)
      ?.interviews?.find(
        (i: any) => !!i.dates.find((d: any) => d.id === participant.date?.id)
      )
      ?.dates.find((d: any) => d.id === participant.date?.id)?.id ?? ""
  );
  const [meetInterviewSelected, setMeetInterviewSelected] =
    React.useState<string>(
      missions
        .find((m) => m?.id === participant.mission?.id)
        ?.interviews?.find((i: any) => !!i?.id === participant.date?.id)?.id ??
        ""
    );
  const [messageDialog, setMessageDialog] = React.useState<string>("");
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<{
    [x: string]: any;
  }>({
    lastname: null,
    firstname: null,
    country: null,
    department: null,
    mission: null,
    phone: null,
    general: null,
  });
  function closeDialog() {
    setOpenDialog(false);
    setMessageDialog("");
  }
  function checkForm(form: any) {
    let errorOccured = false;
    // lastname
    if (form.lastname.length < 2) {
      errorOccured = true;
      setErrors((e) => ({ ...e, lastname: true }));
    } else {
      setErrors((e) => ({ ...e, lastname: null }));
    } // firstname
    if (form.firstname.length < 2) {
      errorOccured = true;
      setErrors((e) => ({ ...e, firstname: true }));
    } else {
      setErrors((e) => ({ ...e, firstname: null }));
    } // country
    if (form.country.length === 0) {
      errorOccured = true;
      setErrors((e) => ({ ...e, country: true }));
    } else {
      setErrors((e) => ({ ...e, country: null }));
    } // church
    if (form.church.length === 0) {
      errorOccured = true;
      setErrors((e) => ({ ...e, church: true }));
    } else {
      setErrors((e) => ({ ...e, church: null }));
    } // otherchurch
    if (haveOtherChurch && form.otherchurch.length === 0) {
      errorOccured = true;
      setErrors((e) => ({ ...e, otherchurch: true }));
    } else {
      setErrors((e) => ({ ...e, otherchurch: null }));
    } // department
    if (form.department.length === 0) {
      errorOccured = true;
      setErrors((e) => ({ ...e, department: true }));
    } else {
      setErrors((e) => ({ ...e, department: null }));
    } // mission
    if (form.mission.length === 0) {
      errorOccured = true;
      setErrors((e) => ({ ...e, mission: true }));
    } else {
      setErrors((e) => ({ ...e, mission: null }));
    } // phone
    if (form.phone.length === 0 || !isPhoneNumber(form.phone)) {
      errorOccured = true;
      let msg = "Contact téléphonique incorrecte";
      setErrors((e) => ({ ...e, phone: msg }));
    } else {
      setErrors((e) => ({ ...e, phone: null }));
    }
    // check towns selections
    if (!checkAll && checksSelected.length === 0) {
      errorOccured = true;
      let msg =
        "Veuillez sélectionner au moins une ville avant de poursuivre !";
      setMessageDialog(msg);
      setOpenDialog(true);
    }
    // select meet
    if (meetSelected.length === 0) {
      errorOccured = true;
      let msg = "Veuillez sélectionner un rendez-vous qui vous convient !";
      setMessageDialog(msg);
      setOpenDialog(true);
    }
    return errorOccured;
  }
  function updateCheckForm(e: any) {
    e.preventDefault();
    const formInput = e.target;
    if (!formInput) {
      return;
    }
    if (!!errors.general) {
      setErrors((e) => ({ ...e, general: null }));
    }
    setErrors((e) => ({ ...e, [formInput.name]: null }));
  }
  function getValue(t: any) {
    let value = JSON.parse(t)?.value ?? "none";
    return value !== "none" ? value : null;
  }
  function onSubmitForm(e: any) {
    e.preventDefault();
    if (errors.general !== null) {
      setErrors((e) => ({ ...e, general: null }));
    }
    const form = e.target.elements;
    if (form === null) {
      setErrors((e) => ({ ...e, general: "Renseigner le formulaire !" }));
      return;
    }
    const formValues = {
      lastname: form.lastname.value,
      firstname: form.firstname.value,
      country: form.country.value,
      department: form.department.value,
      mission: form.mission.value,
      church: form.church.value,
      otherchurch: haveOtherChurch ? form.otherchurch.value : null,
      phone: form.phone.value,
    };
    if (checkForm(formValues)) {
      return;
    }
    setIsLoading(true);
    const formDatas = {
      lastname: form.lastname.value,
      firstname: form.firstname.value,
      country: getValue(form.country.value),
      department: getValue(form.department.value),
      church: !haveOtherChurch ? getValue(form.church.value) : null,
      otherchurch: haveOtherChurch ? form.otherchurch.value : "",
      phone: form.phone.value,
      mission: getValue(form.mission.value),
      interview: meetInterviewSelected,
      date: meetSelected,
      selected_localities: checkAll ? [] : checksSelected,
      all_localities: checkAll,
    };
    updateRegistrationParticipant(participant.id, {
      userform: formDatas,
    }).then((res: any) => {
      if (res.success) {
        queryClient.setQueryData(["participant", participant?.id], res);
      }
      if (!res.success) {
        setErrors((e) => ({
          ...e,
          general: res.message.toString(),
        }));
      }
      setIsLoading(false);
    });
  }
  const egliseCentre = churchs.filter((e: any) =>
    e.name.toLowerCase().startsWith("c")
  );
  return (
    <React.Fragment>
      <div className="flex w-full flex-row px-3">
        <div className="flex flex-1 h-auto flex-col min_pc_mini:py-10 py-10 phone:py-5">
          <div className="flex flex-col min_pc_mini:pt-7 gap-y-4 w-[80%] min_pc_mini:w-[100%] h-auto phone:w-[100%]">
            <span className="flex text-[22px] font-bold select-none">
              Statut de votre demande de participation
            </span>
            <div
              className={clsx(
                "flex p-4 border-l-8 rounded-[10px]",
                participant.status === "pending"
                  ? "border-primary bg-primary/15"
                  : "",
                participant.status === "success"
                  ? "border-green-500 bg-green-500/15"
                  : "",
                participant.status === "reject"
                  ? "border-red-500 bg-red-500/15"
                  : ""
              )}
            >
              <span className="flex gap-2 items-center text-[18px] font-semibold select-none">
                Résultat :{" "}
                <strong
                  className={clsx(
                    participant.status === "pending" ? "text-primary" : "",
                    participant.status === "success" ? "text-green-500" : "",
                    participant.status === "reject" ? "text-red-500" : ""
                  )}
                >
                  {participant.status === "pending"
                    ? "En attente"
                    : participant.status === "success"
                    ? "ACCEPTER"
                    : "REFUSER"}
                </strong>
              </span>
            </div>
            <span className="flex text-[22px] font-semibold select-none">
              Vos informations modifiables !
            </span>
            <span className="flex text-[15px] font-medium select-none">
              Vos informations restent modifiables jusqu'a la validation de
              votre demande de participation !
            </span>
            <form
              className="flex flex-col gap-4 max-w-[500px]"
              onSubmit={
                !isLoading
                  ? participant.status === "pending"
                    ? onSubmitForm
                    : undefined
                  : undefined
              }
              onChange={updateCheckForm}
            >
              <TextInputForm
                title={"Nom"}
                name={"lastname"}
                defaultValue={participant.lastname}
                error={!!errors.lastname}
              />
              <TextInputForm
                title={"Prénoms"}
                name={"firstname"}
                error={!!errors.firstname}
                defaultValue={participant.firstname}
              />
              <SelectInputForm
                title={"Pays"}
                name={"country"}
                defaultValue={participant.country?.id}
                error={!!errors.country}
                options={countries
                  .filter((e) => e.active)
                  .map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                onChange={(_) => {
                  setErrors((e) => ({ ...e, country: null }));
                }}
              />
              <PhoneInputForm
                title={"Contact Téléphonique"}
                defaultValue={participant.phone}
                subTitle={"Préciser l'indicatif Pays (Exemple: 225XXXXXXXXXX)"}
                name={"phone"}
                error={!!errors.phone}
              />
              <SelectInputForm
                title={"Église"}
                name={"church"}
                defaultValue={participant.church?.id}
                error={!!errors.church}
                options={egliseCentre
                  .concat(
                    churchs.filter(
                      (e) =>
                        !egliseCentre
                          .map((i) => i.id.toString())
                          .includes(e.id.toString())
                    )
                  )
                  .map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))
                  .concat({
                    value: "otherchurch",
                    label: "Autre Église",
                  })}
                onChange={(_) => {
                  if (_) {
                    const v = _.value;
                    if (v === "otherchurch") {
                      setHaveOtherChurch(true);
                    } else {
                      if (haveOtherChurch) {
                        setHaveOtherChurch(false);
                      }
                    }
                  }
                  setErrors((e) => ({ ...e, church: null }));
                }}
              />
              {haveOtherChurch && (
                <TextInputForm
                  key={"otherchurch"}
                  defaultValue={participant.otherchurch}
                  title={"Préciser le nom de l'église"}
                  name={"otherchurch"}
                  error={!!errors.otherchurch}
                />
              )}
              <SelectInputForm
                title={"Département souhaité ?"}
                name={"department"}
                defaultValue={participant.department?.id}
                error={!!errors.department}
                options={departments.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                onChange={(_) => {
                  setErrors((e) => ({ ...e, department: null }));
                }}
              />
              <SelectInputForm
                title={"Sélectionner la mission"}
                name={"mission"}
                error={!!errors.mission}
                defaultValue={participant.mission?.id}
                options={missions.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                onChange={(_) => {
                  console.log(_);
                  if (_) {
                    const mission = missions.find((m) => m.id === _?.value);
                    !!mission && setMission(mission);
                    !!mission &&
                      setchecksSelected(
                        mission?.localities?.map((e: any) => e.id) ?? []
                      );
                  }
                  setErrors((e) => ({ ...e, mission: null }));
                }}
              />
              {mission && participant.status === "pending" && (
                <div className="flex flex-col py-2 px-0 rounded-[10px] gap-2 bg-white">
                  <div className="flex flex-col gap-1">
                    <span className="flex text-[16px] font-medium">
                      Villes ciblées :
                    </span>
                    <span className="flex text-[13px] text-black/50 font-medium">
                      Sélectionner la ou les villes dans lesquelles vous
                      participez !
                    </span>
                  </div>
                  <div className="flex flex-col w-full overflow-x-auto">
                    <FormGroup
                      sx={{
                        gap: 1,
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkAll}
                            onChange={(e, c) => {
                              setCheckAll(c);
                              if (c) {
                                setchecksSelected(
                                  mission?.localities?.map((e: any) => e.id) ??
                                    []
                                );
                              }
                            }}
                            name="all"
                          />
                        }
                        label={"Toutes les villes"}
                      />
                      {(mission?.localities ?? []).map((e: any, i: any) => {
                        return (
                          <FormControlLabel
                            key={e.id}
                            disabled={checkAll}
                            control={
                              <Checkbox
                                checked={checksSelected.includes(e.id)}
                                onChange={(_, c) => {
                                  if (!c) {
                                    setchecksSelected((t) =>
                                      t.filter((f) => f !== e.id)
                                    );
                                  } else {
                                    setchecksSelected((t) =>
                                      t.filter((f) => f !== e.id).concat(e.id)
                                    );
                                  }
                                }}
                                name={e.id}
                              />
                            }
                            label={<LocalityItemCheck item={e} />}
                          />
                        );
                      })}
                    </FormGroup>
                  </div>
                </div>
              )}
              {mission && participant.status === "pending" && (
                <div className="flex flex-col py-2 px-0 rounded-[10px] gap-2 bg-white">
                  <div className="flex flex-col gap-1">
                    <span className="flex text-[16px] font-medium">
                      Sélectionner un rendez-vous
                    </span>
                  </div>
                  <div className="flex flex-col w-full pb-2 overflow-x-auto">
                    <FormGroup
                      sx={{
                        gap: 1,
                      }}
                    >
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={meetSelected}
                        name="radio-buttons-group"
                        onChange={(e, v) => {
                          setMeetSelected(v);
                          setMeetInterviewSelected(
                            mission?.interviews?.find(
                              (i: any) => !!i.dates.find((d: any) => d.id === v)
                            )?.id ?? ""
                          );
                        }}
                      >
                        {(mission?.interviews ?? []).map((e: any, i: any) => {
                          return (
                            <div key={e.id} className="flex flex-col gap-2">
                              <div className="flex flex-col">
                                <span className="flex text-[14px] text-primary leading-none">
                                  {e?.mdvieoffice?.name}
                                </span>
                              </div>
                              {(e?.dates ?? []).map((d: any, i: any) => {
                                return (
                                  <FormControlLabel
                                    key={d.id}
                                    value={d.id}
                                    control={<Radio />}
                                    label={
                                      <div className="flex flex-col gap-1 leading-none">
                                        <span className="flex text-[16px]">
                                          {moment(d?.date).format("LL")}
                                        </span>
                                        <div className="flex flex-row gap-2">
                                          <span className="flex text-[13px]">
                                            {d?.type === "FACETOFACE"
                                              ? "En présentiel à"
                                              : "En ligne: "}
                                          </span>
                                          {d?.type === "FACETOFACE" ? (
                                            <span className="flex text-[13px]">
                                              {e?.mdvieoffice?.name}
                                            </span>
                                          ) : (
                                            <a
                                              href={d?.location}
                                              target="_blank"
                                              className="flex text-[13px] underline-offset-2 underline font-medium"
                                              rel="noreferrer"
                                            >
                                              Cliquez ici pour accéder à la
                                              réunion
                                            </a>
                                          )}
                                        </div>
                                      </div>
                                    }
                                  />
                                );
                              })}
                            </div>
                          );
                        })}
                      </RadioGroup>
                    </FormGroup>
                  </div>
                </div>
              )}
              {errors.general && (
                <div className="mt-2">
                  <FormMessage
                    message={errors.general}
                    styleMessage={"error"}
                  />
                </div>
              )}
              {participant.status === "pending" && (
                <SubmitForm isLoading={isLoading} title={"Mettre à jour"} />
              )}
            </form>
          </div>
        </div>
      </div>
      <DialogModal
        message={messageDialog}
        handleClose={closeDialog}
        open={openDialog}
      />
    </React.Fragment>
  );
};

type LocalityItemCheckProps = {
  item: any;
};

const LocalityItemCheck = ({ item }: LocalityItemCheckProps) => {
  return (
    <div className="flex flex-col gap-1 w-fit">
      <span className="flex text-[14px] font-medium line-clamp-1 leading-none text-ellipsis">
        {item?.name}
      </span>
      <div className="flex flex-row gap-4 items-center justify-between">
        <span className="flex text-[12px] leading-none">
          {capitalizeAllWords(moment(item?.start).format("ll"))}
        </span>
        <MoveRight size={15} color="black" />
        <span className="flex text-[12px] leading-none">
          {capitalizeAllWords(moment(item?.end).format("ll"))}
        </span>
      </div>
    </div>
  );
};
export default ParticipationFormPage;
