import moment from "moment";
import React from "react";
import ConfirmActionModal from "../../../../../../components/modals/models/confirmation";
import { useDataStore } from "../../../../../../configs/stores/dataStore";
import { ExternalLink } from "lucide-react";

type Props = {
  item?: any;
  state?: "passed" | "next";
};

const DashMissionSubScribeItem = ({ item, state = "passed" }: Props) => {
  const { cancelMeet } = useDataStore();
  const [open, setOpenConfirmation] = React.useState(false);
  async function cancel() {
    return await cancelMeet(item?.id);
  }
  function getStatus(status: string) {
    switch (status) {
      case "pending":
        return "En attente";
      case "success":
        return "Vous êtes validé";
      case "reject":
        return "Refusé";
      default:
        return "En attente";
    }
  }
  return (
    <React.Fragment>
      <div className="flex p-5 h-[280px] flex-col gap-3 max-w-[330px] justify-between bg-white rounded-[10px] border-black/10">
        <div className="flex flex-col gap-2">
          <span className="flex text-[14px] text-primary leading-none opacity-80">
            Jour de l'entretien
          </span>
          <span className="flex text-[18px] font-medium leading-none">
            {moment(item?.date?.date).format("LL")}
          </span>
        </div>
          <span className="flex mt-1 text-[15px] w-full line-clamp-2">
            {item?.interview?.name}
          </span>
        <div className="flex flex-col gap-2">
          <LocalityItemInfo title={"Statut"} value={getStatus(item?.status)} />
          <LocalityItemInfo
            title={"Type d'entretien"}
            value={item?.interview?.type}
          />
          <LocalityItemInfo
            title={item?.date?.type === "ONLINE" ? "Lien" : "Lieu"}
            value={
              item?.date?.type === "ONLINE" ? (
                <a
                  href={item?.date?.location}
                  target="_blank"
                  className="flex text-[14px] items-center gap-1"
                  rel="noreferrer"
                >
                  <span>Ouvrir le lien</span>
                  <ExternalLink size={13} color="black" />
                </a>
              ) : (
                item?.interview?.mdvieoffice?.name ?? "Non definie"
              )
            }
          />
        </div>
        <div className="flex flex-col w-full">
          <button
            onClick={() => setOpenConfirmation(true)}
            className="flex w-fit transition-all underline underline-offset-2 duration-150 text-[12px] text-black/50 active:text-black/30"
          >
            Annuler l'entretien
          </button>
        </div>
      </div>
      <ConfirmActionModal
        open={open}
        message="Voulez-vous vraiment annuler votre rendez-vous ?"
        handleClose={() => setOpenConfirmation(false)}
        confirm={cancel}
      />
    </React.Fragment>
  );
};

type InfoItemProps = {
  title: string;
  value: any;
};

const LocalityItemInfo = ({ title, value = "" }: InfoItemProps) => {
  return (
    <div className="flex flex-row gap-2 items-center">
      <span className="flex text-[14px] text-black/50">{title} :</span>
      <span className="flex text-[14px]">{value}</span>
    </div>
  );
};

export default DashMissionSubScribeItem;
