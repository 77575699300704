import React from "react";

type Props = {
  title: string;
  defaultValue?: string;
  error?: boolean;
  name?: string;
};

const PasswordInputForm = ({
  title = "",
  defaultValue,
  error = false,
  name = "password",
}: Props) => {
  const [isVisible, setIsVisible] = React.useState(false);
  function setVisibility(has: boolean) {
    setIsVisible(has);
  }
  return (
    <div className="flex flex-col gap-2 w-full">
      <span className={`flex text-[15px] ${error ? "text-red-500" : ""}`}>
        {title}
      </span>
      <div className="flex flex-col w-full justify-center relative">
        <div className="flex transition-all duration-150 justify-center absolute right-2 p-[6px] rounded-full">
          {isVisible && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill={error ? "rgb(239 68 68 )" : "rgb(0 0 0 / 0.55)"}
              className="w-[22px] h-[22px]"
              onClick={() => setVisibility(false)}
            >
              <path d="M10 12.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
              <path
                fillRule="evenodd"
                d="M.664 10.59a1.651 1.651 0 0 1 0-1.186A10.004 10.004 0 0 1 10 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0 1 10 17c-4.257 0-7.893-2.66-9.336-6.41ZM14 10a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
                clipRule="evenodd"
              />
            </svg>
          )}
          {!isVisible && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill={error ? "rgb(239 68 68 )" : "rgb(0 0 0 / 0.55)"}
              className="w-[22px] h-[22px]"
              onClick={() => setVisibility(true)}
            >
              <path
                fillRule="evenodd"
                d="M3.28 2.22a.75.75 0 0 0-1.06 1.06l14.5 14.5a.75.75 0 1 0 1.06-1.06l-1.745-1.745a10.029 10.029 0 0 0 3.3-4.38 1.651 1.651 0 0 0 0-1.185A10.004 10.004 0 0 0 9.999 3a9.956 9.956 0 0 0-4.744 1.194L3.28 2.22ZM7.752 6.69l1.092 1.092a2.5 2.5 0 0 1 3.374 3.373l1.091 1.092a4 4 0 0 0-5.557-5.557Z"
                clipRule="evenodd"
              />
              <path d="m10.748 13.93 2.523 2.523a9.987 9.987 0 0 1-3.27.547c-4.258 0-7.894-2.66-9.337-6.41a1.651 1.651 0 0 1 0-1.186A10.007 10.007 0 0 1 2.839 6.02L6.07 9.252a4 4 0 0 0 4.678 4.678Z" />
            </svg>
          )}
        </div>
        <input
          placeholder={title}
          name={name}
          defaultValue={defaultValue}
          type={isVisible ? "text" : "password"}
          className={` transition-all duration-150 flex outline-4 h-[50px] outline-primary px-4 min-w-0 text-[18px] w-full bg-slate-200 rounded-[8px] ${
            error
              ? "placeholder-red-500 border-[3px] border-red-500 outline-transparent outline-none"
              : ""
          }`}
        />
      </div>
    </div>
  );
};

export default PasswordInputForm;
