import React from "react";
import { useAuthStore } from "../../configs/stores/auth";
import { Navigate } from "react-router-dom";

const HomePage = () => {
  const isAuthenticated = useAuthStore((e) => e.isAuthenticated);
  if (isAuthenticated) {
    return <Navigate to={"dashboard"} />;
  } else {
    return <Navigate to={"login"} />;
  }
};

export default HomePage;
