import React from "react";
import ModalCustom from "../..";
import FormMessage from "../../../form_components/message";
import SubmitForm from "../../../form_components/submit";
import { v4 as uuIdv4 } from "uuid";
import { useAdminDataStore } from "../../../../configs/stores/admindataStore";
import TextInputForm from "../../../form_components/text_input";
import TextAreaInputForm from "../../../form_components/textarea_input";
import { useParams } from "react-router-dom";
import { isNumber } from "../../../../constants";
import { useQueryClient } from "@tanstack/react-query";

type Props = {
  handleClose: () => void;
  item: any;
  open: boolean;
};

const SurveyQuestionUpdateModal = ({
  open = false,
  item,
  handleClose = () => {},
}: Props) => {
  const queryClient = useQueryClient();
  const { id } = useParams();
  const [formKey, setFormKey] = React.useState(uuIdv4());
  const { updateSurveyQuestion } = useAdminDataStore();
  const [closable] = React.useState(true);
  const [isLoading, setisLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<{
    [x: string]: any;
  }>({
    general: null,
  });
  function checkForm(form: any) {
    let errorOccured = false;
    // index
    if (form.index.length === 0 || !isNumber(form?.index ?? "null")) {
      errorOccured = true;
      setErrors((e) => ({ ...e, index: true }));
    } else {
      setErrors((e) => ({ ...e, index: null }));
    }
    // question
    if (form.question.length < 2) {
      errorOccured = true;
      setErrors((e) => ({ ...e, question: true }));
    } else {
      setErrors((e) => ({ ...e, question: null }));
    }
    // response_range
    if (form.response_range.length < 2) {
      errorOccured = true;
      setErrors((e) => ({ ...e, response_range: true }));
    } else {
      setErrors((e) => ({ ...e, response_range: null }));
    }
    return errorOccured;
  }
  function updateCheckForm(e: any) {
    e.preventDefault();
    const formInput = e.target;
    if (!formInput) {
      return;
    }
    if (!!errors.general) {
      setErrors((e) => ({ ...e, general: null }));
    }
    setErrors((e) => ({ ...e, [formInput.name]: null }));
  }
  function onSubmitForm(e: any) {
    if (!id) {
      alert("Recharger la page !");
      return;
    }
    if (!item?.id) {
      alert("Requète non valide !");
      return;
    }
    e.preventDefault();
    const form = e.target.elements;
    if (errors.general !== null) {
      setErrors((e) => ({ ...e, general: null }));
    }
    if (form === null) {
      setErrors((e) => ({ ...e, general: "Renseigner le formulaire !" }));
      return;
    }
    const formValues = {
      index: form.index.value,
      question: form.question.value,
      response_range: form.response_range.value,
    };
    if (checkForm(formValues)) {
      console.log("Error Occured !!!");
      return;
    }
    const formDatas = {
      index: form.index.value,
      question: form.question.value,
      response_range: form.response_range.value,
    };
    setErrors((e) => ({
      ...e,
      general: "",
    }));
    setisLoading(true);
    updateSurveyQuestion(id, { id: item.id, ...formDatas }).then((res: any) => {
      setisLoading(false);
      if (res.success) {
        queryClient.setQueryData(["surveys", id], res.data);
        setFormKey(uuIdv4());
        setErrors({
          general: null,
        });
        handleClose();
      } else {
        setErrors((e) => ({
          ...e,
          general: res.message.toString(),
        }));
      }
    });
  }
  React.useEffect(() => {
    if (!open) {
      setFormKey(uuIdv4());
      setErrors({
        general: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  return (
    <ModalCustom open={open} handleClose={closable ? handleClose : undefined}>
      <div className="flex flex-col w-[400px] p-3 pt-0 space-y-2 items-start justify-center">
        <span className="flex text-[20px] font-medium">
          Modifier une question
        </span>
        <form
          key={formKey}
          className="flex flex-col w-full px-1 gap-3 max-h-[80vh] overflow-y-auto"
          onSubmit={!isLoading ? onSubmitForm : undefined}
          onChange={updateCheckForm}
        >
          <TextInputForm
            title={"Numeroté la question"}
            placeholder="0"
            name={"index"}
            defaultValue={item.index}
            error={!!errors.index}
          />
          <TextAreaInputForm
            lines={3}
            title={"Question"}
            name={"question"}
            defaultValue={item.question}
            error={!!errors.question}
          />
          <TextAreaInputForm
            lines={3}
            title={"Intervalle de reponse"}
            name={"response_range"}
            defaultValue={item.response_range}
            error={!!errors.response_range}
          />
          {errors.general && (
            <FormMessage message={errors.general} styleMessage={"error"} />
          )}
          <SubmitForm isLoading={isLoading} title="Enregistrer" />
        </form>
      </div>
    </ModalCustom>
  );
};

export default SurveyQuestionUpdateModal;
