import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";
import InterviewScreenDefaultHeader from "./header";
import InterviewDateItem from "./date";
import { useAuthStore } from "../../../../../../../configs/stores/auth";
import { useAdminDataStore } from "../../../../../../../configs/stores/admindataStore";
import moment from "moment";

const InterviewsScreenInterviewDefault = () => {
  const { id } = useParams();
  const { isAuthenticated } = useAuthStore();
  const { getInterview, interviews } = useAdminDataStore();
  async function fetchData() {
    if (!id) {
      return;
    }
    const res = await getInterview(id);
    if (!res.success) {
      return null;
    }
    return res.data;
  }
  const {
    data: interview,
    refetch,
    isRefetching,
  } = useQuery({
    initialData: interviews.find((i) => i.id === id),
    enabled: isAuthenticated,
    queryFn: fetchData,
    queryKey: ["interviews", id],
    refetchInterval: 5 * 60 * 1000, // 5 minutes in milliseconds
  });
  return (
    <React.Fragment>
      <div className={`flex flex-col gap-5 w-full`}>
        <InterviewScreenDefaultHeader
          refetchData={refetch}
          isRefetching={isRefetching}
        />
        <div className="flex flex-row flex-wrap gap-5">
          {[...(interview?.dates ?? [])]
            .sort(
              (a: any, b: any) =>
                moment(b.date).valueOf() - moment(a.date).valueOf()
            )
            .map((e: any, index: any) => {
              return <InterviewDateItem key={e?.id ?? index} item={e} />;
            })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default InterviewsScreenInterviewDefault;
