import React from "react";
import ModalCustom from "../..";
import { useAdminDataStore } from "../../../../configs/stores/admindataStore";
import clsx from "clsx";
import { Plus, RotateCw } from "lucide-react";

type Props = {
  item: any;
  handleClose: () => void;
  open: boolean;
};

const MissionLocalitiesUpdateModal = ({
  item,
  open = false,
  handleClose = () => {},
}: Props) => {
  const [mission, setMission] = React.useState<any>(item);
  const { getMission } = useAdminDataStore();
  const [closable] = React.useState(true);
  const [isLoading, setisLoading] = React.useState(false);
  function refreshMission(e: any) {
    setisLoading(true);
    getMission(item.id).then((res: any) => {
      setisLoading(false);
      if (res.success) {
        if (res.data) {
          setMission(res.data);
        } else {
          handleClose();
        }
      } else {
        alert(res.message);
      }
    });
  }
  return (
    <React.Fragment>
      <ModalCustom open={open} handleClose={closable ? handleClose : undefined}>
        <div className="flex flex-col w-[400px] p-3 pt-0 space-y-2 items-start justify-center">
          <div className="flex flex-col">
            <span className="flex text-[13px]">Mission</span>
            <span className="flex text-[17px] font-medium">{mission.name}</span>
          </div>
          <div className="flex flex-row gap-3">
            <div
              className={clsx(
                "flex select-none items-center transition-all duration-200 flex-row justify-center items-cente gap-x-1",
                !isLoading ? "cursor-pointer" : "cursor-progress"
              )}
            >
              <div className="flex rounded-[10px] items-center justify-center">
                <Plus size={14} />
              </div>
              <span className="hidden min_pc_mini:flex text-[12px] font-medium">
                Ajouter une localité
              </span>
            </div>
            <div
              onClick={!isLoading ? refreshMission : undefined}
              className={clsx(
                "flex select-none items-center transition-all duration-200 flex-row justify-center items-cente gap-x-1",
                !isLoading ? "cursor-pointer" : "cursor-progress"
              )}
            >
              <div
                className={clsx(
                  "flex rounded-[10px] items-center justify-center",
                  isLoading ? "animate-spin" : ""
                )}
              >
                <RotateCw size={14} />
              </div>
              <span className="hidden min_pc_mini:flex text-[12px] font-medium">
                {!isLoading ? "Rafraichir" : "Chargement..."}
              </span>
            </div>
          </div>
          <div className="flex h-[400px] w-full border-[3.5px] border-primary/30 rounded-[10px] overflow-y-auto"></div>
        </div>
      </ModalCustom>
    </React.Fragment>
  );
};

export default MissionLocalitiesUpdateModal;
