import React from "react";
import { styled } from "@mui/material/styles";
import OutlinedInput, {
  outlinedInputClasses,
} from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { primaryColor } from "../../constants";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      borderRadius: 8,
      marginTop: 10,
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const StyledSelect = styled(Select<string | undefined>)(`
  & .${outlinedInputClasses.notchedOutline} {
    border-color: rgba(0,0,0,.5);
  }
  &:hover .${outlinedInputClasses.notchedOutline} {
    border-color: rgba(0,0,0,.5);
  }
  &.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline} {
    border-color: ${primaryColor};
    border-width: 2.5px;
  }
  &.${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline} {
    border-color: red;
    border-width: 3px;
  }
`);

type Props = {
  title?: string;
  placeholder?: string;
  height?: number;
  error?: boolean;
  name?: string;
  defaultValue?: string;
  options: Array<{ value: string; label: string }>;
  onChange?: (value: { value: string; label: string } | any) => void;
};

const SelectInputForm = ({
  title = "",
  placeholder,
  error = false,
  height = 50,
  name = "",
  options = [],
  defaultValue,
  onChange,
}: Props) => {
  return (
    <div className="flex flex-col w-full gap-2">
      {!!title && (
        <span className={`flex text-[15px] ${error ? "text-red-500" : ""}`}>
          {title}
        </span>
      )}
      <StyledSelect
        name={name}
        displayEmpty
        error={error}
        defaultValue={
          defaultValue && options.filter((e) => e.value === defaultValue)?.[0]
            ? JSON.stringify(
                options.filter((e) => e.value === defaultValue)[0]
              ).toString()
            : ""
        }
        input={<OutlinedInput />}
        onChange={(_) => {
          if (_.target.value) {
            !!onChange && onChange(JSON.parse(_.target.value));
          }
        }}
        renderValue={(selected) => {
          if (selected?.length === 0 || !selected) {
            return (
              <div
                className={`flex ${error ? "text-red-500" : "text-black/30"}`}
              >
                {(placeholder ?? title ?? "Sélectionner").slice(0, 42) +
                  ((placeholder ?? title ?? "Sélectionner").length > 40
                    ? "..."
                    : "")}
              </div>
            );
          }
          const Option = JSON.parse(selected);
          return Option.label;
        }}
        MenuProps={{ ...MenuProps }}
        sx={{
          borderRadius: 2.2,
          bgcolor: "#e2e8f0",
          fontSize: 18,
          height: height,
          paddingLeft: 0.4,
        }}
        inputProps={{
          "aria-label": "Without label",
        }}
      >
        <MenuItem
          disabled
          value=""
          key={""}
          style={{ paddingBlock: 0, paddingBottom: 6 }}
        >
          Sélectionner
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem key={index} value={JSON.stringify(option).toString()}>
            {option.label}
          </MenuItem>
        ))}
      </StyledSelect>
    </div>
  );
};

export default SelectInputForm;
