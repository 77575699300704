import clsx from "clsx";
import React from "react";
import { NavLink } from "react-router-dom";
import { sidebarContentList, sidebarListItemFilter } from "./contentList";
import { useAuthStore } from "../../../configs/stores/auth";

const SidebarContent = () => {
  const { user } = useAuthStore();
  return (
    <div className="flex flex-1 flex-col w-full overflow-y-auto overflow-x-clip border-t-[1px] border-t-white/30">
      <div className="flex flex-1 h-auto flex-col gap-2 pl-2 pr-2 pt-2 pb-20">
        {sidebarContentList
          .filter(sidebarListItemFilter(user.role, user.grade))
          .map((e, i) => {
            return <SidebarContentItem key={i} title={e.title} path={e.path} />;
          })}
      </div>
    </div>
  );
};

type SidebarContentItemProps = {
  title: string;
  path: string;
};

const SidebarContentItem = ({ title, path }: SidebarContentItemProps) => {
  return (
    <NavLink
      to={path ?? ""}
      end={path === ""}
      className={({ isActive }) => {
        return clsx(
          "flex flex-row h-[40px] px-4 gap-[10px] duration-150 transition-all rounded-[8px] items-center cursor-pointer",
          isActive
            ? "bg-slate-500/30"
            : "hover:bg-slate-500/20 active:bg-slate-500/40"
        );
      }}
    >
      {({ isActive }) => (
        <>
          <span className="flex text-white font-medium text-[15px] whitespace-nowrap">
            {title}
          </span>
        </>
      )}
    </NavLink>
  );
};

export default SidebarContent;
