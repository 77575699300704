import clsx from "clsx";
import React, { ReactNode } from "react";

type Props = {
  gap?: number;
  axis: "col" | "row";
  backgroundColor?: string;
  children?: ReactNode;
  minHeight?: number | string;
  maxHeight?: number | string;
  minWidth?: number | string;
  maxWidth?: number | string;
  rounded?: number | string;
  py?: number | string;
  px?: number | string;
  pb?: number | string;
};

const ScreenViewLayout = ({
  children,
  axis = "col",
  gap = 2,
  backgroundColor = "#f1f5f9",
  minHeight = 10,
  minWidth = 10,
  maxHeight,
  maxWidth,
  rounded = 8,
  py = 20,
  px = 16,
  pb = 100,
}: Props) => {
  function getValue(value: any, suffix: string = "px") {
    if (!value) {
      return "auto";
    }
    return value
      ? typeof value === "number"
        ? value.toString() + suffix
        : value
      : "auto";
  }
  return (
    <div
      className={clsx(
        `flex flex-1 overflow-clip rounded-[${getValue(
          rounded
        )}] bg-[${backgroundColor}]`,
        axis === "row"
          ? "flex-row overflow-x-auto"
          : "flex-col overflow-y-auto",
        axis === "row"
          ? `min-w-[${getValue(minWidth)}] max-w-[${getValue(maxWidth)}] h-auto`
          : `min-h-[${getValue(minHeight)}] max-h-[${getValue(
              maxHeight
            )}] w-full`
      )}
    >
      <div
        className={clsx(
          `flex flex-1 flex-col px-[${getValue(px)}] py-[${getValue(py)}]`,
          `relative h-auto pb-[${getValue(pb)}] gap-[${getValue(gap)}]`
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default ScreenViewLayout;
