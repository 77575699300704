import React from "react";

type SearchInputProps = {
  placeHolder: String;
  height?: number;
  width?: number;
  maxWidth?: number | string;
  onChange?: (str: string) => void;
};

const SearchInputForm = ({
  placeHolder,
  onChange,
  height = 50,
  maxWidth = 300,
}: SearchInputProps) => {
  return (
    <div
      style={{
        maxWidth,
      }}
      className="flex flex-col w-auto"
    >
      <input
        type="text"
        placeholder={"Rechercher : " + placeHolder}
        style={{
          height,
        }}
        className={`flex transition-all duration-150 outline-4 outline-primary px-4 text-[15px] min-w-0 w-full bg-slate-200 rounded-[8px]`}
        onChange={(e) => {
          !!onChange && onChange(e.currentTarget.value);
        }}
      />
    </div>
  );
};

export default SearchInputForm;
