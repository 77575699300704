import React from "react";
import { Plus } from "lucide-react";
import { useAdminDataStore } from "../../../../../../../configs/stores/admindataStore";
import SearchInputForm from "../../../../../../../components/form_components/search_input";
import { ScreenHeaderActionItem, ScreenHeaderActionRefresh } from "../../../../../../../components/screen_header";
import SurveyNewModal from "../../../../../../../components/modals/models/survey_new";

const SurveysScreenDefaultHeader = () => {
  const { isRefetchingSurveys, refetchSurveys } = useAdminDataStore();
  const [isOpen, setisOpen] = React.useState(false);
  function openCloseModal() {
    setisOpen(!isOpen);
  }
  return (
    <React.Fragment>
      <div className="flex flex-col bg-white rounded-[10px] px-5 py-5 gap-2">
        <div className="flex flex-row flex-wrap pt-0 gap-4">
          <SearchInputForm
            onChange={(e) => {}}
            height={40}
            placeHolder={"Titre"}
          />
          <ScreenHeaderActionItem
            title="Ajouter"
            icon={<Plus size={20} fill="black" />}
            onClick={openCloseModal}
          />
          <ScreenHeaderActionRefresh
            onClick={refetchSurveys}
            isLoading={isRefetchingSurveys}
          />
        </div>
      </div>
      <SurveyNewModal open={isOpen} handleClose={openCloseModal} />
    </React.Fragment>
  );
};

export default SurveysScreenDefaultHeader;
