import React from "react";
import { useAuthStore } from "../../configs/stores/auth";
import { usePopUpHook } from "../../hooks/popuphook";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import ConfirmActionModal from "../modals/models/confirmation";

const AccountHead = () => {
  const { user, logout } = useAuthStore();
  const { refInput, refPopUp, open, switchMenu } = usePopUpHook<
    HTMLDivElement,
    HTMLDivElement
  >({});
  const [isOpen, setisOpen] = React.useState(false);
  function close() {
    setisOpen(false);
  }
  async function confirm() {
    return await logout();
  }
  function onClick() {
    switchMenu();
  }
  return (
    <React.Fragment>
      <div className="flex flex-col relative">
        <div
          ref={refInput}
          onClick={onClick}
          className="group flex flex-row items-center gap-x-1 cursor-pointer"
        >
          <div className="flex h-[35px] w-[35px] rounded-[50px] bg-[#D0AF21] items-center justify-center">
            <span className="flex select-none text-white font-semibold text-[14px]">
              {user?.lastname[0].toUpperCase()}
              {user?.firstname[0].toUpperCase()}
            </span>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="flex transition-all duration-150 group-active:stroke-white/50"
          >
            <path d="m6 9 6 6 6-6" />
          </svg>
        </div>
        <div
          ref={refPopUp}
          className="absolute select-none z-[700] border-[1px] gap-4 p-2 border-black/10 flex-col top-[60px] right-0 phone:right-0 w-[250px] overflow-y-auto rounded-[10px] bg-white overflow-hidden shadow-sm shadow-black/15 text-[16px]"
          style={{
            visibility: open ? "visible" : "hidden",
          }}
        >
          <NavLink
            to={"/dashboard/profil"}
            onClick={onClick}
            className={clsx(
              "flex flex-row gap-[8px] h-[40px] w-full items-center rounded-[8px] px-3 mb-2",
              "hover:bg-slate-500/20 active:bg-slate-500/40 "
            )}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="flex"
            >
              <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
              <circle cx="12" cy="7" r="4" />
            </svg>
            <span className="flex text-black font-medium text-[14px]">
              Mon profile
            </span>
          </NavLink>
          <div
            onClick={() => {
              setisOpen(true);
            }}
            className={clsx(
              "flex cursor-pointer flex-row gap-[8px] h-[40px] w-full items-center rounded-[8px] px-3 border-[2.5px] border-red-700/30",
              "hover:bg-slate-500/20 active:bg-slate-500/40 "
            )}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke={"#b91c1c"}
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="flex"
            >
              <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
              <polyline points="16 17 21 12 16 7" />
              <line x1="21" x2="9" y1="12" y2="12" />
            </svg>
            <span className="flex text-red-700 font-semibold text-[14px]">
              Déconnexion
            </span>
          </div>
        </div>
      </div>
      <ConfirmActionModal
        open={isOpen}
        message="Voulez-vous vraiment vous déconnectez ?"
        confirm={confirm}
        handleClose={close}
      />
    </React.Fragment>
  );
};

export default AccountHead;
