import React from "react";
import { Outlet } from "react-router-dom";
import ScreenContentLayoutMenu from "../../../../components/screen_content_layout/menu";

const DashMyMissionsScreenLayoutWithMenu = () => {
  const menus = [
    {
      title: "Générale",
      url: "",
    },
    {
      title: "Mes entretiens",
      url: "interviews",
    },
    {
      title: "Mes participations",
      url: "participations",
    },
  ];
  return (
    <div className="flex flex-1 flex-col h-full w-full">
      <ScreenContentLayoutMenu menus={menus} />
      <Outlet />
    </div>
  );
};

export default DashMyMissionsScreenLayoutWithMenu;
