import React from "react";
import { ScreenHeaderActionRefresh } from "../../../../../../components/screen_header";
import ListItems from "./list_items";

const DashMyMissionsScreenParticipations = () => {
  // const { missions, refetchMissions, isRefetchingMissions } = useDataStore();
  return (
    <div className="flex flex-1 flex-col gap-4 h-full w-full">
      <div className="flex flex-col max-w-[300px]">
        <ScreenHeaderActionRefresh
          // onClick={refetchMissions}
          // isLoading={isRefetchingMissions}
          title="Rafraichir mes participations"
        />
      </div>
      <div className="flex flex-1 flex-col gap-8">
        <ListItems
          title="Cette année (2024)"
          // list={missions}
          state="next"
        />
        <ListItems
          title="2023"
          // list={missions}
          state="passed"
        />
      </div>
    </div>
  );
};

export default DashMyMissionsScreenParticipations;
