import React from "react";
import ScreenHeader from "../../../../../../components/screen_header";
import { useQuery } from "@tanstack/react-query";
import { Link, Navigate, Outlet, useParams } from "react-router-dom";
import { useAdminDataStore } from "../../../../../../configs/stores/admindataStore";
import { useAuthStore } from "../../../../../../configs/stores/auth";
import ScreenContentLayout from "../../../../../../components/screen_content_layout";
import { ArrowLeft } from "lucide-react";
import MissionMenu from "./header";

const MissionsScreenMission = () => {
  const { id } = useParams();
  const { isAuthenticated } = useAuthStore();
  const { getMission } = useAdminDataStore();
  async function fetchMission() {
    if (!id) {
      return;
    }
    const res = await getMission(id);
    if (!res.success) {
      return null;
    }
    return res.data;
  }
  const {
    isLoading,
    data: mission,
  } = useQuery({
    enabled: isAuthenticated,
    queryFn: fetchMission,
    queryKey: ["missions", id],
    staleTime: 5 * 60 * 1000, // 5 minutes in milliseconds
    refetchInterval: 5 * 60 * 1000, // 5 minutes in milliseconds
  });
  if (!id) {
    return <Navigate to={"/"} />;
  }
  return (
    <React.Fragment>
      <div className="flex flex-col flex-1 h-full relative">
        <div className="flex flex-1 flex-col h-full w-full">
          <ScreenHeader
            title={
              <div className="flex flex-row items-center gap-4">
                <Link to=".." className="flex transition-all p-2 cursor-pointer items-center justify-center rounded-full bg-black/5 hover:bg-black/10 active:bg-black/15">
                  <ArrowLeft size={20} />
                </Link>
                <div className="flex flex-col select-none">
                  <span className="flex text-[12px]">Missions</span>
                  {isLoading && (
                    <span className="flex text-[18px]">
                      Chargement de la mission...
                    </span>
                  )}
                  {!isLoading &&
                    (!!mission ? (
                      <span className="flex text-[18px]">{mission.name}</span>
                    ) : (
                      <div className="flex text-red-500 text-[16px]">
                        Mission introuvable !
                      </div>
                    ))}
                </div>
              </div>
            }
          ></ScreenHeader>
          <ScreenContentLayout>
            <MissionMenu />
            <Outlet />
          </ScreenContentLayout>
        </div>
        {isLoading && (
          <div className="absolute flex h-full w-full justify-center items-center backdrop-filter backdrop-blur-sm bg-black/20">
            <div className="flex flex-col p-5 items-center gap-3 max-w-[300px] cursor-default shadow-md select-none bg-white rounded-[10px]">
              <span className="flex text-[14px] text-center">
                Patientez un instant !
              </span>
              <span className="flex text-[18px] text-center">
                Chargement des données de la mission en cours...
              </span>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default MissionsScreenMission;
 