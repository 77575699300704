import clsx from "clsx";
import { Edit, PanelLeftOpen, Trash2 } from "lucide-react";
import moment from "moment";
import React from "react";
import { Link, useParams } from "react-router-dom";
import ConfirmActionModal from "../../../../../../../components/modals/models/confirmation";
import { useQueryClient } from "@tanstack/react-query";
import {
  InterviewMeetType,
  useAdminDataStore,
} from "../../../../../../../configs/stores/admindataStore";
import InterviewDateUpdateModal from "../../../../../../../components/modals/models/interview_date_update";

type Props = {
  item: any;
};

const InterviewDateItem = ({ item }: Props) => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { removeInterviewDate } = useAdminDataStore();
  const [isOpenModify, setisOpenModify] = React.useState(false);
  function closeModify() {
    setisOpenModify(false);
  }
  const [isOpen, setisOpen] = React.useState(false);
  function close() {
    setisOpen(false);
  }
  async function confirm() {
    if (!id) {
      alert("Requète non valide !");
      return;
    }
    return await removeInterviewDate(id, { id: item?.id }).then((res) => {
      if (!res.success) {
        !res?.message && alert(res?.message);
      } else {
        queryClient.setQueryData(["interviews", id], res.data);
      }
    });
  }
  return (
    <React.Fragment>
      <div className="flex flex-col gap-3 select-none p-5 bg-white rounded-[10px] w-[280px]">
        <div className="flex flex-col">
          <span className="flex text-[14px] text-primary opacity-80">
            Jour de l'entretien
          </span>
          <span className="flex text-[18px] font-medium">
            {moment(item?.date).format("LL")}
          </span>
        </div>
        <span className="flex text-primary text-[14px] leading-tight">
          {"Rencontre " +
            Object.entries(InterviewMeetType)
              .find((e) => e[0] === item.type)?.[1]
              ?.toLocaleLowerCase()}
        </span>
        <div className="flex flex-col gap-2">
          <InterviewDateItemInfo
            title="Entretenus"
            value={(
              (item?.interviews?.success?.length ?? 0) +
              (item?.participations?.success?.length ?? 0)
            ).toString()}
          />
          <InterviewDateItemInfo
            title="Rejetés"
            value={
              (item?.interviews?.reject?.length ?? 0) +
              (item?.participations?.reject?.length ?? 0)
            }
          />
          <InterviewDateItemInfo
            title="En attentent"
            value={
              (item?.interviews?.pending?.length ?? 0) +
              (item?.participations?.pending?.length ?? 0)
            }
          />
        </div>
        <div className="flex flex-row gap-3">
          <button
            onClick={() => setisOpen(true)}
            className={clsx(
              "flex transition-all cursor-pointer flex-row gap-[8px] h-[40px] items-center rounded-[8px] px-2",
              "bg-slate-500/10 hover:bg-slate-500/20 active:bg-slate-500/40 "
            )}
          >
            <Trash2 size={20} />
          </button>
          <Link
            to={item?.id}
            className={clsx(
              "flex flex-1 transition-all cursor-pointer flex-row gap-[8px] h-[40px] items-center rounded-[8px] px-4",
              "bg-primary hover:bg-hover active:bg-active "
            )}
          >
            <PanelLeftOpen size={18} color="white" />
            <span className="flex flex-1 justify-center text-white font-medium text-[15px]">
              Ouvrir
            </span>
          </Link>
          <button
            onClick={() => setisOpenModify(true)}
            className={clsx(
              "flex transition-all cursor-pointer flex-row gap-[8px] h-[40px] items-center rounded-[8px] px-2",
              "bg-slate-500/10 hover:bg-slate-500/20 active:bg-slate-500/40 "
            )}
          >
            <Edit size={20} />
          </button>
        </div>
      </div>
      <InterviewDateUpdateModal
        item={item}
        handleClose={closeModify}
        open={isOpenModify}
      />
      <ConfirmActionModal
        open={isOpen}
        message="Voulez-vous vraiment supprimer ?"
        confirm={confirm}
        handleClose={close}
      />
    </React.Fragment>
  );
};

type InfoItemProps = {
  title: string;
  value: string;
};

const InterviewDateItemInfo = ({ title, value }: InfoItemProps) => {
  return (
    <div className="flex flex-row gap-2 items-center">
      <span className="flex text-[15px] leading-tight">{title} :</span>
      <span className="flex text-[16px] leading-tight">{value}</span>
    </div>
  );
};

export default InterviewDateItem;
