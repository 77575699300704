type PropsCard = {
  icon: any;
  text: string;
  onClick?: () => void;
};

function ButtonCard({  text = "", icon, onClick }: PropsCard) {
  return (
    <button
      onClick={onClick}
      className="flex select-none flex-col p-4 w-[230px] h-[150px] rounded-[10px] transition-all duration-150 bg-primary active:bg-active hover:bg-hover active:scale-95"
    >
      <div className="flex h-[80%] pl-2 justify-center items-center">{icon}</div>
      <div className="flex flex-1 pt-2 mx-2 justify-center items-center">
        <span className="flex text-[17px] text-white font-semibold">
          {text}
        </span>
      </div>
    </button>
  );
}

export default ButtonCard;
