import { deleteUrl, getUrl, postUrl } from "../../apiRequest";

export function getAllData() {
  return getUrl("/all_basics");
}

export function getAllStat() {
  return getUrl("/stat/all");
}

export function selectInterview(form: any) {
  return postUrl("/mission/meet/add", form);
}

export function getAllMeet() {
  return getUrl("/mission/meet/all");
}

export function cancelMeet(id: string) {
  return postUrl("/mission/meet/cancel", { id });
}

export function addDepartment(name: string) {
  return postUrl("/department/add", { name });
}

export function getAllDepartment() {
  return getUrl("/department/all");
}

export function updateDepartment(id: string, form: any) {
  return postUrl("/department/update?id=" + id, form);
}

export function getAllUser() {
  return getUrl("/user/all");
}

export function updateUser(id: string, form: any) {
  return postUrl("/user/update?id=" + id, form);
}

export function addCountry(name: string) {
  return postUrl("/country/add", { name });
}

export function getAllCountry() {
  return getUrl("/country/all");
}

export function updateCountry(id: string, form: any) {
  return postUrl("/country/update?id=" + id, form);
}

export function addChurch(form: any) {
  return postUrl("/church/add", form);
}

export function getAllChurch() {
  return getUrl("/church/all");
}

export function updateChurch(id: string, form: any) {
  return postUrl("/church/update?id=" + id, form);
}

export function addMission(data: any) {
  return postUrl("/mission/add", data);
}

export function removeMission(id: string) {
  return deleteUrl("/mission/remove?id=" + id);
}

export function getMission(id: string) {
  return getUrl("/mission/find_unique_by_id?id=" + id);
}
export function getMissionStats(id: string) {
  return getUrl("/mission/stats?id=" + id);
}

export function updateMission(id: string, form: any) {
  return postUrl("/mission/update?id=" + id, form);
}

export function getAllMission() {
  return getUrl("/mission/all");
}

export function addLocality(data: any) {
  return postUrl("/mission/locality/add", data);
}
export function getLocalities(id: string) {
  return getUrl("/mission/locality/all?mission=" + id);
}
export function getLocality(id: string) {
  return getUrl("/mission/locality/find_unique_by_id?id=" + id);
}
export function updateLocality(id: string, form: any) {
  return postUrl("/mission/locality/update?id=" + id, form);
}
export function removeLocality(id: string) {
  return deleteUrl("/mission/locality/remove?id=" + id);
}

export function addLocalityTeamMember(data: any) {
  return postUrl("/mission/locality/team/add", data);
}
export function getLocalityTeamMembers(id: string) {
  return getUrl("/mission/locality/team/all?locality=" + id);
}
export function getLocalityTeamMember(id: string) {
  return getUrl("/mission/locality/team/find_unique_by_id?id=" + id);
}
export function updateLocalityTeamMember(id: string, form: any) {
  return postUrl("/mission/locality/team/update?id=" + id, form);
}
export function removeLocalityTeamMember(id: string) {
  return deleteUrl("/mission/locality/team/remove?id=" + id);
}

export function addMissionary(data: any) {
  return postUrl("/mission/missionary/add", data);
}
export function getMissionaries(id: string) {
  return getUrl("/mission/missionary/all?locality=" + id);
}
export function getMissionary(id: string) {
  return getUrl("/mission/missionary/find_unique_by_id?id=" + id);
}
export function updateMissionary(id: string, form: any) {
  return postUrl("/mission/missionary/update?id=" + id, form);
}
export function removeMissionary(id: string) {
  return deleteUrl("/mission/missionary/remove?id=" + id);
}

export function addMdvieOffice(data: any) {
  return postUrl("/mdvieoffice/add", data);
}

export function getAllMdvieOffice() {
  return getUrl("/mdvieoffice/all");
}

export function updateMdvieOffice(id: string, form: any) {
  return postUrl("/mdvieoffice/update?id=" + id, form);
}

export function addMissionType(data: any) {
  return postUrl("/mission/type/add", data);
}

export function getAllMissionType() {
  return getUrl("/mission/type/all");
}

export function addInterview(data: any) {
  return postUrl("/interview/add", data);
}

export function getInterview(id: string) {
  return getUrl("/interview/find_unique_by_id?id=" + id);
}

export function getAllInterview() {
  return getUrl("/interview/all");
}

export function updateInterview(id: string, form: any) {
  return postUrl("/interview/update?id=" + id, form);
}

export function removeInterview(id: string) {
  return deleteUrl("/interview/remove?id=" + id);
}

export function addInterviewDate(id: string, form: any) {
  return postUrl("/interview/date/add?id=" + id, form);
}

export function updateInterviewDate(id: string, form: any) {
  return postUrl("/interview/date/update?id=" + id, form);
}

export function removeInterviewDate(id: string, form: any) {
  return postUrl("/interview/date/remove?id=" + id, form);
}

export function addSurvey(data: any) {
  return postUrl("/survey/add", data);
}

export function getSurvey(id: string) {
  return getUrl("/survey/find_unique_by_id?id=" + id);
}

export function getAllSurvey() {
  return getUrl("/survey/all");
}

export function updateSurvey(id: string, form: any) {
  return postUrl("/survey/update?id=" + id, form);
}

export function removeSurvey(id: string) {
  return deleteUrl("/survey/remove?id=" + id);
}

export function addSurveyQuestion(id: string, form: any) {
  return postUrl("/survey/question/add?id=" + id, form);
}

export function updateSurveyQuestion(id: string, form: any) {
  return postUrl("/survey/question/update?id=" + id, form);
}

export function removeSurveyQuestion(id: string, form: any) {
  return postUrl("/survey/question/remove?id=" + id, form);
}
