import React, { ReactNode } from "react";
// import { Navigate } from "react-router-dom";
// import { useAuthStore } from "../configs/stores/auth";

type Props = {
  children: ReactNode;
};

const RouteRequireAuth = ({ children }: Props) => {
  // const { isAuthenticated, setlastRedirectedUrlToAuth } = useAuthStore();

  // if (!isAuthenticated) {
  //   const url = window.location.href;
  //   // Set the last redirected URL to the auth page
  //   setlastRedirectedUrlToAuth("", url);
  //   return <Navigate to="/" />;
  // }

  return <React.Fragment>{children}</React.Fragment>;
};

export default RouteRequireAuth;
