import React from "react";
import { useAuthApiInterceptor } from "../../configs/api";
import { useAuthStore } from "../../configs/stores/auth";
import { useQuery } from "@tanstack/react-query";
import { useAdminDataStore } from "../../configs/stores/admindataStore";

const AuthProvider = ({ children = <div /> }) => {
  const { isAuthenticated, refetchUser } = useAuthStore();
  const { getAll,refetchMissionTypes } = useAdminDataStore();
  useAuthApiInterceptor();
  useQuery({
    enabled: isAuthenticated,
    queryFn: refetchUser,
    queryKey: ["userconnected"],
    staleTime: 5 * 60 * 1000, // 5 minutes in milliseconds
    refetchInterval: 5 * 60 * 1000, // Refetch every 2 minutes
  });
  useQuery({
    enabled: isAuthenticated,
    queryFn: getAll,
    queryKey: ["dataStoreBasics"],
    staleTime: 10 * 60 * 1000, // 5 minutes in milliseconds
    refetchInterval: 10 * 60 * 1000, // Refetch every 2 minutes
  });
  useQuery({
    enabled: isAuthenticated,
    queryFn: refetchMissionTypes,
    queryKey: ["missionTypes"],
    staleTime: 10 * 60 * 1000, // 2 minutes in milliseconds
    refetchInterval: 10 * 60 * 1000, // Refetch every 2 minutes
  });
  return (
    <React.Fragment>
      <div className="flex flex-1">{children}</div>
    </React.Fragment>
  );
};

export default AuthProvider;
