import React from "react";
import ModalCustom from "../..";
import ButtonClassic from "../../../button";

type Props = {
  message: string;
  handleClose: () => void;
  open: boolean;
};

const DialogModal = ({
  message = "evenement a confirmer ",
  handleClose = () => {},
  open = false,
}: Props) => {
  const [closable] = React.useState(true);
  async function onConfirm() {
    handleClose();
  }
  return (
    <ModalCustom open={open} handleClose={closable ? handleClose : undefined}>
      <div className="flex flex-col w-[300px] p-1 pt-0 space-y-4 items-center justify-center">
        <span className="flex text-[20px] font-medium">Message</span>
        <div className="flex py-2">
          {message && (
            <span className="flex text-center text-[17px] leading-tight font-normal">
              {message}
            </span>
          )}
        </div>
        <div className="flex flex-col text-[14px] w-full">
          <ButtonClassic
            text="D'accord"
            width={"100%"}
            color="primary"
            onClick={onConfirm}
          />
        </div>
      </div>
    </ModalCustom>
  );
};

export default DialogModal;
