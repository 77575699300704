import { Outlet } from "react-router-dom";
import { useAuthStore } from "./configs/stores/auth";

const ContentLayout = () => {
  const { isAuthenticated } = useAuthStore();
  return (
    <div className="flex flex-1 flex-col">
      <div className="flex h-[60px] min-h-[60px]" />
      {!isAuthenticated && (
        <div className="flex flex-1 flex-col px-10 phone:px-5 tablet_mini:px-6 w-full overflow-y-auto">
          <div className="flex w-full h-auto max-w-[1532px] my-auto mx-auto">
            <Outlet />
          </div>
        </div>
      )}
      {isAuthenticated && (
        <div className="flex flex-1 max-h-[calc(100vh-60px)] flex-col w-full">
          <Outlet />
        </div>
      )}
    </div>
  );
};

export default ContentLayout;
