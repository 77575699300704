import { deleteUrl, getUrl, postUrl } from "../../apiRequest";

export function getAllData() {
  return getUrl("/admin/all_basics");
}

export function getAllStat() {
  return getUrl("/admin/stat/all");
}

export function addDepartment(name: string) {
  return postUrl("/admin/department/add", { name });
}

export function getAllDepartment() {
  return getUrl("/admin/department/all");
}

export function updateDepartment(id: string, form: any) {
  return postUrl("/admin/department/update?id=" + id, form);
}

export function getAllUser() {
  return getUrl("/admin/user/all");
}

export function updateUser(id: string, form: any) {
  return postUrl("/admin/user/update?id=" + id, form);
}

export function updateUserReinitPassword(id: string) {
  return getUrl("/admin/user/update/reinit-password?id=" + id);
}

export function addUser(form: any) {
  return postUrl("/admin/user/add", form);
}

export function removeUser(id: string) {
  return deleteUrl("/admin/user/remove?id=" + id);
}

export function addCountry(name: string) {
  return postUrl("/admin/country/add", { name });
}

export function getAllCountry() {
  return getUrl("/admin/country/all");
}

export function updateCountry(id: string, form: any) {
  return postUrl("/admin/country/update?id=" + id, form);
}

export function addChurch(form: any) {
  return postUrl("/admin/church/add", form);
}

export function getAllChurch() {
  return getUrl("/admin/church/all");
}

export function updateChurch(id: string, form: any) {
  return postUrl("/admin/church/update?id=" + id, form);
}

export function addMission(data: any) {
  return postUrl("/admin/mission/add", data);
}

export function removeMission(id: string) {
  return deleteUrl("/admin/mission/remove?id=" + id);
}

export function getMission(id: string) {
  return getUrl("/admin/mission/find_unique_by_id?id=" + id);
}

export function getMissionStats(id: string) {
  return getUrl("/admin/mission/stats?id=" + id);
}

export function updateMission(id: string, form: any) {
  return postUrl("/admin/mission/update?id=" + id, form);
}

export function getAllMission() {
  return getUrl("/admin/mission/all");
}

export function addLocality(data: any) {
  return postUrl("/admin/mission/locality/add", data);
}

export function getLocalities(id: string) {
  return getUrl("/admin/mission/locality/all?mission=" + id);
}

export function getLocality(id: string) {
  return getUrl("/admin/mission/locality/find_unique_by_id?id=" + id);
}

export function updateLocality(id: string, form: any) {
  return postUrl("/admin/mission/locality/update?id=" + id, form);
}

export function removeLocality(id: string) {
  return deleteUrl("/admin/mission/locality/remove?id=" + id);
}

export function addLocalityTeamMember(data: any) {
  return postUrl("/admin/mission/locality/team/add", data);
}

export function getLocalityTeamMembers(id: string) {
  return getUrl("/admin/mission/locality/team/all?locality=" + id);
}

export function getLocalityTeamMember(id: string) {
  return getUrl("/admin/mission/locality/team/find_unique_by_id?id=" + id);
}

export function updateLocalityTeamMember(id: string, form: any) {
  return postUrl("/admin/mission/locality/team/update?id=" + id, form);
}

export function removeLocalityTeamMember(id: string) {
  return deleteUrl("/admin/mission/locality/team/remove?id=" + id);
}

export function addMissionary(data: any) {
  return postUrl("/admin/mission/missionary/add", data);
}

export function getMissionaries(id: string) {
  return getUrl("/admin/mission/missionary/all?locality=" + id);
}

export function getMissionary(id: string) {
  return getUrl("/admin/mission/missionary/find_unique_by_id?id=" + id);
}

export function updateMissionary(id: string, form: any) {
  return postUrl("/admin/mission/missionary/update?id=" + id, form);
}

export function removeMissionary(id: string) {
  return deleteUrl("/admin/mission/missionary/remove?id=" + id);
}

export function addMdvieOffice(data: any) {
  return postUrl("/admin/mdvieoffice/add", data);
}

export function getAllMdvieOffice() {
  return getUrl("/admin/mdvieoffice/all");
}

export function updateMdvieOffice(id: string, form: any) {
  return postUrl("/admin/mdvieoffice/update?id=" + id, form);
}

export function addMissionType(data: any) {
  return postUrl("/admin/mission/type/add", data);
}

export function getAllMissionType() {
  return getUrl("/admin/mission/type/all");
}

export function addInterview(data: any) {
  return postUrl("/admin/interview/add", data);
}

export function getInterview(id: string) {
  return getUrl("/admin/interview/find_unique_by_id?id=" + id);
}

export function getInterviewDate(id: string) {
  return getUrl("/admin/interview/date/find_unique_by_id?id=" + id);
}

export function getAllInterview() {
  return getUrl("/admin/interview/all");
}

export function updateInterview(id: string, form: any) {
  return postUrl("/admin/interview/update?id=" + id, form);
}

export function removeInterview(id: string) {
  return deleteUrl("/admin/interview/remove?id=" + id);
}

export function addInterviewDate(id: string, form: any) {
  return postUrl("/admin/interview/date/add?id=" + id, form);
}

export function updateInterviewDate(id: string, form: any) {
  return postUrl("/admin/interview/date/update?id=" + id, form);
}

export function removeInterviewDate(id: string, form: any) {
  return postUrl("/admin/interview/date/remove?id=" + id, form);
}

export function setUserInterviewDateValidation(id: string, validation: any) {
  return postUrl("/admin/interview/date/meet/validate?id=" + id, {
    ...validation,
  });
}

export function setParticipantInterviewDateValidation(
  id: string,
  validation: any
) {
  return postUrl("/admin/interview/date/participant/validate?id=" + id, {
    ...validation,
  });
}

export function addSurvey(data: any) {
  return postUrl("/admin/survey/add", data);
}

export function getSurvey(id: string) {
  return getUrl("/admin/survey/find_unique_by_id?id=" + id);
}

export function getAllSurvey() {
  return getUrl("/admin/survey/all");
}

export function updateSurvey(id: string, form: any) {
  return postUrl("/admin/survey/update?id=" + id, form);
}

export function removeSurvey(id: string) {
  return deleteUrl("/admin/survey/remove?id=" + id);
}

export function addSurveyQuestion(id: string, form: any) {
  return postUrl("/admin/survey/question/add?id=" + id, form);
}

export function updateSurveyQuestion(id: string, form: any) {
  return postUrl("/admin/survey/question/update?id=" + id, form);
}

export function removeSurveyQuestion(id: string, form: any) {
  return postUrl("/admin/survey/question/remove?id=" + id, form);
}
