import React from "react";
import { Plus } from "lucide-react";
import {
  ScreenHeaderActionItem,
  ScreenHeaderActionRefresh,
} from "../../../../../../../components/screen_header";
import InterviewDateNewModal from "../../../../../../../components/modals/models/interview_date_new";

type Props = {
  onSearchText?: (term: string) => void;
  refetchData?: () => void;
  isRefetching?: boolean;
};

const InterviewScreenDefaultHeader = ({
  refetchData,
  isRefetching = false,
}: Props) => {
  const [isOpen, setisOpen] = React.useState(false);
  function openCloseModal() {
    setisOpen(!isOpen);
  }
  return (
    <React.Fragment>
      <div className="flex flex-col bg-white rounded-[10px] px-5 py-5 gap-2">
        <div className="flex flex-row flex-wrap pt-0 gap-4">
          <ScreenHeaderActionItem
            title="Ajouter une date"
            icon={<Plus size={20} fill="black" />}
            onClick={openCloseModal}
          />
          <ScreenHeaderActionRefresh
            onClick={refetchData}
            isLoading={isRefetching}
          />
        </div>
      </div>
      <InterviewDateNewModal open={isOpen} handleClose={openCloseModal} />
    </React.Fragment>
  );
};

export default InterviewScreenDefaultHeader;
