import React from "react";
import SurveysScreenDefaultHeader from "./header";
import TableDataAutoList from "../../../../../../../components/table";
import { useAdminDataStore } from "../../../../../../../configs/stores/admindataStore";
import SurveyActions from "./actions";

const DashInterviewsScreenSurveysDefault = () => {
  const { surveys } = useAdminDataStore();
  const columns = [
    {
      label: "Questionnaire",
      slug: "name",
    },
    {
      label: "Description",
      slug: "description",
    },
    {
      label: "Questions",
      slug: "questions",
    },
  ];
  return (
    <React.Fragment>
      <div className="flex flex-1 gap-4 flex-col h-full w-full">
        <SurveysScreenDefaultHeader />
        <TableDataAutoList
          showSearchRow={false}
          columns={columns}
          rows={surveys.map((e) => ({
            item: e,
            ...e,
            questions: e?.questions.length ?? 0,
          }))}
          actions={SurveyActions}
        />
      </div>
    </React.Fragment>
  );
};

export default DashInterviewsScreenSurveysDefault;
