import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Link, Navigate, Outlet, useParams } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import { useAuthStore } from "../../../../../../../configs/stores/auth";
import { useAdminDataStore } from "../../../../../../../configs/stores/admindataStore";
import ScreenHeader from "../../../../../../../components/screen_header";

const InterviewsScreenInterview = () => {
  const { id } = useParams();
  const { isAuthenticated } = useAuthStore();
  const { getInterview, interviews } = useAdminDataStore();
  async function fetchData() {
    if (!id) {
      return;
    }
    const res = await getInterview(id);
    if (!res.success) {
      return null;
    }
    return res.data;
  }
  const {
    isLoading,
    data: interview,
    refetch,
  } = useQuery({
    enabled: isAuthenticated,
    queryFn: fetchData,
    initialData: interviews.find((i) => i.id === id),
    queryKey: ["interviews", id],
    refetchInterval: 5 * 60 * 1000, // 5 minutes in milliseconds
  });
  React.useEffect(() => {
    if (!interview) {
      refetch();
    }
  }, [refetch, interview]);
  if (!id) {
    return <Navigate to={".."} />;
  }
  return (
    <React.Fragment>
      <div className="flex flex-col flex-1 h-full">
        <div className="flex flex-1 flex-col h-full w-full">
          <ScreenHeader
            title={
              <div className="flex flex-row items-center gap-4">
                <Link
                  to=".."
                  className="flex transition-all p-2 cursor-pointer items-center justify-center rounded-full bg-black/5 hover:bg-black/10 active:bg-black/15"
                >
                  <ArrowLeft size={20} />
                </Link>
                <div className="flex flex-col select-none">
                  <span className="flex text-[12px]">Entretien</span>
                  {isLoading && (
                    <span className="flex text-[18px]">
                      Chargement de l'entretien...
                    </span>
                  )}
                  {!isLoading &&
                    (!!interview ? (
                      <span className="flex text-[18px]">{interview.name}</span>
                    ) : (
                      <div className="flex text-red-500 text-[16px]">
                        Entretien introuvable !
                      </div>
                    ))}
                </div>
              </div>
            }
          ></ScreenHeader>
          <div
            className={`flex flex-1 flex-col pt-5 gap-5 w-full bg-slate-100`}
          >
            <div className="flex flex-col gap-2 bg-white rounded-[10px] w-full">
              <div className="flex flex-col gap-2 p-5">
                <InterviewItemInfo
                  title="Questionnaire"
                  value={interview?.survey.name ?? "Non definie"}
                />
                <InterviewItemInfo
                  title="Type d'entretien"
                  value={interview?.type ?? "Non definie"}
                />
                {interview?.type === "PARTICIPATION" &&
                  !!interview?.mission?.name && (
                    <InterviewItemInfo
                      title="Mission Concernée"
                      value={interview?.mission?.name ?? "Non definie"}
                    />
                  )}
                <InterviewItemInfo
                  title="Représentation Concernée"
                  value={interview?.mdvieoffice?.name ?? "Non definie"}
                />
              </div>
            </div>
            <Outlet />
          </div>
        </div>
        {isLoading && (
          <div className="absolute flex h-full w-full justify-center items-center backdrop-filter backdrop-blur-sm bg-black/20">
            <div className="flex flex-col p-5 items-center gap-3 max-w-[300px] cursor-default shadow-md select-none bg-white rounded-[10px]">
              <span className="flex text-[14px] text-center">
                Patientez un instant !
              </span>
              <span className="flex text-[18px] text-center">
                Chargement des données de l'entretien en cours...
              </span>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

type InfoItemProps = {
  title: string;
  value: string;
};

const InterviewItemInfo = ({ title, value }: InfoItemProps) => {
  return (
    <div className="flex flex-row gap-2 items-center">
      <span className="flex text-[14px] text-black/50">{title} :</span>
      <span className="flex text-[14px]">{value}</span>
    </div>
  );
};

export default InterviewsScreenInterview;
