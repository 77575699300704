import React from "react";
import ScreenHeader, {
  ScreenHeaderActionItem,
} from "../../../../components/screen_header";
import ScreenContentLayout from "../../../../components/screen_content_layout";
import { HandCoins } from "lucide-react";

const DashDonScreen = () => {
  return (
    <div className="flex flex-1 flex-col h-full w-full">
      <ScreenHeader
        icon={<HandCoins size={20} color="white" />}
        title="Mes Dons"
      >
        <ScreenHeaderActionItem
          primary
          title="Faire un don"
          icon={<HandCoins size={20} color="white" />}
        />
      </ScreenHeader>
      <ScreenContentLayout>
        <span className="flex text-[18px]">Aucun disponible pour l'instant</span>
      </ScreenContentLayout>
    </div>
  );
};

export default DashDonScreen;
