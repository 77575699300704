import React from "react";
import ScreenHeader from "../../../../components/screen_header";
import ScreenContentLayout from "../../../../components/screen_content_layout";
import ScreenContentLayoutMenu from "../../../../components/screen_content_layout/menu";
import { Outlet } from "react-router-dom";

const DashProfilScreen = () => {
  const menus = [
    {
      title: "Modifier mes informations",
      url: "",
    },
    {
      title: "Modifier mot de passe",
      url: "updatePassword",
    },
  ];
  return (
    <div className="flex flex-1 flex-col h-full w-full">
      <ScreenHeader
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="flex"
          >
            <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
            <circle cx="12" cy="7" r="4" />{" "}
          </svg>
        }
        title="Mon profile"
      />
      <ScreenContentLayout>
        <ScreenContentLayoutMenu menus={menus} />
        <Outlet />
      </ScreenContentLayout>
    </div>
  );
};

export default DashProfilScreen;
