import clsx from "clsx";
import { HandCoins, Wallet } from "lucide-react";
import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";

const SideBarTop = () => {
  return (
    <div className="flex flex-col px-2 gap-2 py-2 ">
      <SideBarTopItem
        title="Accueil"
        url=""
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="flex"
          >
            <path d="M15 21v-8a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v8" />
            <path d="M3 10a2 2 0 0 1 .709-1.528l7-5.999a2 2 0 0 1 2.582 0l7 5.999A2 2 0 0 1 21 10v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
          </svg>
        }
      />
      <SideBarTopItem
        title="Mes missions"
        url="mymissions"
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="flex"
          >
            <rect width="8" height="4" x="8" y="2" rx="1" ry="1" />
            <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />
            <path d="M12 11h4" />
            <path d="M12 16h4" />
            <path d="M8 11h.01" />
            <path d="M8 16h.01" />
          </svg>
        }
      />
      <SideBarTopItem
        title="Donner"
        url="don"
        icon={<HandCoins size={20} color="white" />}
      />
      <SideBarTopItem
        title="Mon profile"
        url="profil"
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="flex"
          >
            <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
            <circle cx="12" cy="7" r="4" />
          </svg>
        }
      />
      <SideBarTopItem
        title="Mon épargne"
        url="wallet"
        icon={<Wallet color="white" size={20} />}
      />
    </div>
  );
};

type SideBarTopItemProps = {
  icon: ReactNode;
  title: string;
  url: string;
};

const SideBarTopItem = ({ icon, title, url }: SideBarTopItemProps) => {
  return (
    <NavLink
      to={url ?? ""}
      end={url === ""}
      className={({ isActive }) => {
        return clsx(
          "flex flex-row h-[40px] px-3 gap-[10px] duration-150 transition-all rounded-[8px] items-center cursor-pointer",
          isActive
            ? "bg-slate-500/30"
            : "hover:bg-slate-500/20 active:bg-slate-500/40"
        );
      }}
    >
      {({ isActive }) => (
        <>
          {icon}
          <span className="flex text-white font-medium text-[15px] whitespace-nowrap">
            {title}
          </span>
        </>
      )}
    </NavLink>
  );
};

export default SideBarTop;
