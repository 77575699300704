export const Forloadandnavigate = function () {
  return (
    <a
      href="/"
      id="forloadandnavigate"
      className="none fixed opacity-0 h-0 w-0"
    >
      .
    </a>
  );
};
export function useNaviLoadBoth(url: string) {
  let o = document.getElementById("forloadandnavigate") as HTMLAnchorElement;
  o.href = url ?? "/";
  o.click();
}
