import React from "react";
import ScreenHeader, {
  ScreenHeaderActionItem,
  ScreenHeaderActionRefresh,
} from "../../../../../components/screen_header";
import ScreenContentLayout from "../../../../../components/screen_content_layout";
import { Plus } from "lucide-react";
import TableDataAutoList from "../../../../../components/table";
import { useQuery } from "@tanstack/react-query";
import { useAdminDataStore } from "../../../../../configs/stores/admindataStore";
import { useAuthStore } from "../../../../../configs/stores/auth";
import MissionNewModal from "../../../../../components/modals/models/mission_new";
import MissionActions from "./actions";
import moment from "moment";

const DashMissionsListScreen = () => {
  const { isAuthenticated } = useAuthStore();
  const [isOpen, setisOpen] = React.useState(false);
  function openCloseModal() {
    setisOpen(!isOpen);
  }
  const { missions, isRefetchingMissions, refetchMissions } = useAdminDataStore();
  useQuery({
    enabled: isAuthenticated,
    queryFn: refetchMissions,
    queryKey: ["missions"],
    staleTime: 7 * 60 * 1000, // 5 minutes in milliseconds
    refetchInterval: 10 * 60 * 1000, // 5 minutes in milliseconds
  });
  const columns = [
    {
      label: "Mission",
      slug: "name",
    },
    {
      label: "Départ des équipes",
      slug: "teams_departure",
    },
    {
      label: "Retour des équipes",
      slug: "teams_return",
    },
    {
      label: "Disponible",
      slug: "closed",
    },
  ];
  return (
    <React.Fragment>
      <div className="flex flex-1 flex-col h-full w-full">
        <ScreenHeader title="Missions">
          <ScreenHeaderActionItem
            isLoading={isRefetchingMissions}
            title="Ajouter"
            icon={<Plus size={20} fill="black" />}
            onClick={openCloseModal}
          />
          <ScreenHeaderActionRefresh
            onClick={refetchMissions}
            isLoading={isRefetchingMissions}
          />
        </ScreenHeader>
        <ScreenContentLayout>
          <TableDataAutoList
            columns={columns}
            rows={missions.map((e) => ({
              item:e,
              ...e,
              teams_departure: moment(e.teams_departure).format("LL"),
              teams_return: moment(e.teams_return).format("LL"),
              closed: !e.closed ? "OUI" : "NON",
            }))}
            actions={MissionActions}
          />
        </ScreenContentLayout>
      </div>
      <MissionNewModal open={isOpen} handleClose={openCloseModal} />
    </React.Fragment>
  );
};

export default DashMissionsListScreen;
