import clsx from "clsx";
import React from "react";
import { Edit } from "lucide-react";
import CountryUpdateModal from "../../../../../components/modals/models/country_update";

type Props = {
  row?: any;
};

const CountryActions = ({ row }: Props) => {
  const [isOpenModify, setisOpenModify] = React.useState(false);
  function closeModify() {
    setisOpenModify(false);
  }
  return (
    <React.Fragment>
      <div className="flex flex-col">
        <div
          onClick={() => {
            setisOpenModify(true);
          }}
          className={clsx(
            "flex cursor-pointer flex-row gap-[8px] h-[40px] w-full items-center rounded-[8px] px-3",
            "hover:bg-slate-500/20 active:bg-slate-500/40 "
          )}
        >
          <Edit size={18} />
          <span className="flex text-black font-medium text-[15px]">
            Modifier
          </span>
        </div>
      </div>
      <CountryUpdateModal
        item={row}
        open={isOpenModify}
        handleClose={closeModify}
      />
    </React.Fragment>
  );
};

export default CountryActions;
