import React from "react";
import DashMissionSubScribeItem from "./item";

type Props = {
  title?: string;
  list?: Array<any>;
  state?: "passed" | "next";
};

const ListItems = ({ title = "", list = [], state = "passed" }: Props) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex bg-white flex-col rounded-[10px] p-4">
        <div className="flex w-full">
          <span className="flex text-[18px] select-none font-medium text-black">
            {title}
          </span>
        </div>
      </div>
      <div className="flex flex-col w-full overflow-y-auto gap-4">
        {list.map((e, i) => {
          return <DashMissionSubScribeItem state={state} key={i} item={e} />;
        })}
        {list.length === 0 && (
          <span className="flex pl-4 text-black/50 select-none">
            Aucune email pour l'instant !
          </span>
        )}
      </div>
    </div>
  );
};

export default ListItems;
