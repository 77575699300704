import { getUrl, postUrl } from "../../apiRequest";

export function login(credentials = { email: "", password: "" }) {
  return postUrl("/auth/login/missionary", credentials);
}

export function registration(credentials: any) {
  return postUrl("/auth/registration/participant", credentials);
}

export function logout() {
  return getUrl("/auth/logout");
}

export function getUserData() {
  return getUrl("/auth/me");
}

export function updateProfile(image_data: any) {
  return postUrl("/auth/update/profile", { image_data });
}

export function updateUser(update: any) {
  return postUrl("/auth/update", update);
}

export function updatePassword(update: any) {
  return postUrl("/auth/update/password", update);
}

