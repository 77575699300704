import React from "react";
import ModalCustom from "../..";
import { capitalizeAllWords } from "../../../../constants";
import { Check, MoveRight, RotateCw, X } from "lucide-react";
import moment from "moment";
import clsx from "clsx";
import { useAdminDataStore } from "../../../../configs/stores/admindataStore";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import TextAreaInputForm from "../../../form_components/textarea_input";

type Props = {
  handleClose: () => void;
  open: boolean;
  item: any;
};

const ShowDemandeModal = ({
  handleClose = () => {},
  open = false,
  item,
}: Props) => {
  const { id, date_id } = useParams();
  const queryClient = useQueryClient();
  const { setUserInterviewDateValidation } = useAdminDataStore();
  const [appreciation, setAppreciation] = React.useState("");
  const [validating, setIsValidating] = React.useState(false);
  const [rejecting, setIsRejecting] = React.useState(false);
  const [closable] = React.useState(true);
  function validate() {
    if (!date_id) {
      return;
    }
    setIsValidating(true);
    setUserInterviewDateValidation(date_id, {
      id: item.id,
      status: "success",
      appreciation: appreciation,
    }).then((res) => {
      if (res.success) {
        queryClient.setQueryData(["interview", id, "dates", date_id], res.data);
      }
      setIsValidating(false);
      handleClose();
    });
  }
  function reject() {
    if (!date_id) {
      return;
    }
    setIsRejecting(true);
    setUserInterviewDateValidation(date_id, {
      id: item.id,
      status: "reject",
      appreciation: "",
    }).then((res) => {
      if (res.success) {
        queryClient.setQueryData(["interview", id, "dates", date_id], res.data);
      }
      setIsRejecting(false);
      handleClose();
    });
  }
  // console.log(item);
  return (
    <ModalCustom open={open} handleClose={closable ? handleClose : undefined}>
      <div className="flex flex-col w-[400px] p-1 pt-0 space-y-4 items-center justify-center">
        <span className="flex text-[20px] font-medium">
          Demande de{" "}
          {capitalizeAllWords(item?.interview?.type.toLowerCase() ?? "")}
        </span>
        <div className="flex flex-col p-3 gap-4 w-full">
          <div className="flex flex-col w-full rounded-[10px] gap-2 bg-white">
            <span className="flex text-[16px] font-medium leading-tight">
              Information sur le demandeur :
            </span>
            {item?.user && (
              <InterviewItemInfo
                title="Nom & Prénoms"
                value={item.user.lastname + " " + item.user.firstname}
              />
            )}
            {item?.user?.grade && (
              <InterviewItemInfo
                title="grade"
                value={capitalizeAllWords(item.user.grade.toLowerCase())}
              />
            )}
            {item?.user?.country?.id && (
              <InterviewItemInfo title="Pays" value={item.user.country.name} />
            )}
            {item?.user?.church?.name && (
              <InterviewItemInfo title="Église" value={item.user.church.name} />
            )}
            {item?.user?.department?.name && (
              <InterviewItemInfo
                title="Département"
                value={item.user.department.name}
              />
            )}
            {item?.user?.mdvieoffice?.name && (
              <InterviewItemInfo
                title="Membre de MDVIE"
                value={item.user.mdvieoffice.name}
              />
            )}
          </div>
          <div className="flex flex-col w-full rounded-[10px] gap-2 bg-white">
            <span className="flex text-[16px] font-medium leading-tight">
              Villes sélectionnées :
            </span>
            <div className="flex flex-col w-full gap-3 pb-2 overflow-x-auto">
              {item.all_localities ? (
                <span className="flex text-[14px] text-primary font-medium leading-none">
                  Toutes les villes
                </span>
              ) : (
                (item?.selected_localities ?? [])
                  .sort((a: any, b: any) => {
                    return (
                      moment(a?.start).valueOf() - moment(b?.start).valueOf()
                    );
                  })
                  .map((e: any, i: any) => {
                    return (
                      <div
                        key={e.id}
                        className="flex flex-row gap-3 items-center"
                      >
                        <div className="flex select-none w-[30px] h-[30px] text-[14px] leading-none items-center justify-center rounded-[50%] border border-black/50">
                          {(i + 1).toString()}
                        </div>
                        <LocalityItemCheck item={e} />
                      </div>
                    );
                  })
              )}
            </div>
          </div>
          <div className="flex flex-col">
            <TextAreaInputForm
              lines={3}
              title={"Donner une appreciation"}
              name={"appreciation"}
              onChange={(e) => {
                setAppreciation(e);
              }}
            />
          </div>
          <div className="flex flex-row gap-4">
            <button
              onClick={validate}
              disabled={validating}
              className="flex flex-row rounded-[10px] transition-all duration-150 items-center gap-3 flex-1 p-4 py-3 px-5 bg-primary active:bg-active hover:bg-hover text-white"
            >
              <div className={clsx("flex", validating ? "animate-spin" : "")}>
                {validating ? (
                  <RotateCw size={20} strokeWidth={2.5} />
                ) : (
                  <Check size={20} strokeWidth={2.5} />
                )}
              </div>
              <span className="flex text-[16px] leading-none">Accepter</span>
            </button>
            <button
              onClick={reject}
              disabled={rejecting}
              className="flex flex-row rounded-[10px] transition-all duration-150 items-center gap-3 flex-1 p-4 py-3 px-5 bg-tertiary active:bg-tertiaryactive hover:bg-tertiaryhover text-black"
            >
              <div className={clsx("flex", rejecting ? "animate-spin" : "")}>
                {rejecting ? (
                  <RotateCw size={20} strokeWidth={2.5} />
                ) : (
                  <X size={20} strokeWidth={2.5} />
                )}
              </div>
              <span className="flex text-[16px] leading-none">Refuser</span>
            </button>
          </div>
        </div>
      </div>
    </ModalCustom>
  );
};
type InfoItemProps = {
  title: string;
  value: string;
};

const InterviewItemInfo = ({ title, value }: InfoItemProps) => {
  return (
    <div className="flex flex-row gap-2 items-center">
      <span className="flex text-[14px] text-black/50">{title} :</span>
      <span className="flex text-[14px]">{value}</span>
    </div>
  );
};
type LocalityItemCheckProps = {
  item: any;
};

const LocalityItemCheck = ({ item }: LocalityItemCheckProps) => {
  return (
    <div className="flex flex-col gap-1 w-fit">
      <span className="flex text-[14px] font-medium line-clamp-1 leading-none text-ellipsis">
        {item?.name}
      </span>
      <div className="flex flex-row gap-4 items-center justify-between">
        <span className="flex text-[12px] leading-none">
          {capitalizeAllWords(moment(item?.start).format("ll"))}
        </span>
        <MoveRight size={15} color="black" />
        <span className="flex text-[12px] leading-none">
          {capitalizeAllWords(moment(item?.end).format("ll"))}
        </span>
      </div>
    </div>
  );
};
export default ShowDemandeModal;
