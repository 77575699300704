import React from "react";
import ScreenHeader, {
  ScreenHeaderActionRefresh,
} from "../../../../../components/screen_header";
import ScreenContentLayout from "../../../../../components/screen_content_layout";
import TableDataAutoList from "../../../../../components/table";
import { useQuery } from "@tanstack/react-query";
import { useAdminDataStore } from "../../../../../configs/stores/admindataStore";
import { useAuthStore } from "../../../../../configs/stores/auth";
import CountryActions from "./actions";

const DashCountriesScreen = () => {
  const { isAuthenticated } = useAuthStore();
  const { countries, isRefetchingCountries, refetchCountries } = useAdminDataStore();
  useQuery({
    enabled: isAuthenticated,
    queryFn: refetchCountries,
    queryKey: ["countries"],
    staleTime: 7 * 60 * 1000, // 5 minutes in milliseconds
    refetchInterval: 10 * 60 * 1000, // 5 minutes in milliseconds
  });
  const columns = [
    {
      label: "Nom du pays",
      slug: "name",
    },
    {
      label: "Code",
      slug: "code",
    },
  ];
  return (
    <div className="flex flex-1 flex-col h-full w-full">
      <ScreenHeader title="Pays">
        <ScreenHeaderActionRefresh
          onClick={refetchCountries}
          isLoading={isRefetchingCountries}
        />
      </ScreenHeader>
      <ScreenContentLayout>
        <TableDataAutoList
          columns={columns}
          rows={countries}
          actions={CountryActions}
        />
      </ScreenContentLayout>
    </div>
  );
};

export default DashCountriesScreen;
