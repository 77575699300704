import React from "react";
import { useAuthStore } from "../../../../configs/stores/auth";
import { isEmail, isPhoneNumber } from "../../../../constants";
import TextInputForm from "../../../../components/form_components/text_input";
import SelectInputForm from "../../../../components/form_components/select_input";
import SubmitForm from "../../../../components/form_components/submit";
import PhoneInputForm from "../../../../components/form_components/phone_input";
import EmailInputForm from "../../../../components/form_components/email_input";
import FormMessage from "../../../../components/form_components/message";
import { useAdminDataStore } from "../../../../configs/stores/admindataStore";
import ProfilUpdateUserProfil from "./updateUserProfil";

const ProfilUpdateUserForm = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { updateUser, user } = useAuthStore();
  const { churchs, departments, mdvieOffices, countries } = useAdminDataStore();
  const [errors, setErrors] = React.useState<{
    [x: string]: any;
  }>({
    lastname: null,
    firstname: null,
    country: null,
    representation: null,
    church: null,
    department: null,
    phone: null,
    email: null,
    general: null,
  });
  function checkForm(form: any) {
    let errorOccured = false;
    // lastname
    if (form.lastname.length < 2) {
      errorOccured = true;
      setErrors((e) => ({ ...e, lastname: true }));
    } else {
      setErrors((e) => ({ ...e, lastname: null }));
    } // firstname
    if (form.firstname.length < 2) {
      errorOccured = true;
      setErrors((e) => ({ ...e, firstname: true }));
    } else {
      setErrors((e) => ({ ...e, firstname: null }));
    } // country
    if (form.country.length === 0) {
      errorOccured = true;
      setErrors((e) => ({ ...e, country: true }));
    } else {
      setErrors((e) => ({ ...e, country: null }));
    } // representation
    if (form.representation.length === 0) {
      errorOccured = true;
      setErrors((e) => ({ ...e, representation: true }));
    } else {
      setErrors((e) => ({ ...e, representation: null }));
    } // church
    if (form.church.length === 0) {
      errorOccured = true;
      setErrors((e) => ({ ...e, church: true }));
    } else {
      setErrors((e) => ({ ...e, church: null }));
    } // department
    if (form.department.length === 0) {
      errorOccured = true;
      setErrors((e) => ({ ...e, department: true }));
    } else {
      setErrors((e) => ({ ...e, department: null }));
    } // phone
    if (form.phone.length === 0 || !isPhoneNumber(form.phone)) {
      errorOccured = true;
      let msg = "Contact téléphonique incorrecte";
      setErrors((e) => ({ ...e, phone: msg }));
    } else {
      setErrors((e) => ({ ...e, phone: null }));
    } // email
    if (form.email.length === 0 || !isEmail(form.email)) {
      errorOccured = true;
      let msg = "Email incorrecte";
      setErrors((e) => ({ ...e, email: msg }));
    } else {
      setErrors((e) => ({ ...e, email: null }));
    }
    return errorOccured;
  }
  function updateCheckForm(e: any) {
    e.preventDefault();
    const formInput = e.target;
    if (!formInput) {
      return;
    }
    if (!!errors.general) {
      setErrors((e) => ({ ...e, general: null }));
    }
    setErrors((e) => ({ ...e, [formInput.name]: null }));
  }
  function onSubmitForm(e: any) {
    e.preventDefault();
    if (errors.general !== null) {
      setErrors((e) => ({ ...e, general: null }));
    }
    const form = e.target.elements;
    if (form === null) {
      setErrors((e) => ({ ...e, general: "Renseigner le formulaire !" }));
      return;
    }
    const formValues = {
      lastname: form.lastname.value,
      firstname: form.firstname.value,
      country: form.country.value,
      representation: form.representation.value,
      department: form.department.value,
      church: form.church.value,
      phone: form.phone.value,
      email: form.email.value,
    };
    if (checkForm(formValues)) {
      return;
    }
    function getValue(t: any) {
      let value = JSON.parse(t)?.value ?? "none";
      return value !== "none" ? value : null;
    }
    const formDatas = {
      lastname: form.lastname.value,
      firstname: form.firstname.value,
      church: getValue(form.church.value),
      country: getValue(form.country.value),
      mdvieoffice: getValue(form.representation.value),
      department: getValue(form.department.value),
      phone: form.phone.value,
      email: form.email.value,
    };
    setIsLoading(true);
    updateUser({
      ...formDatas,
    }).then((res: any) => {
      if (!res.success) {
        setErrors((e) => ({
          ...e,
          general: res.message.toString(),
        }));
      }
      setIsLoading(false);
    });
  }
  return (
    <div className="flex flex-col gap-y-4 w-fit p-5 min_tablet_mini:min-w-[60%] rounded-[10px] bg-white h-auto phone:w-[100%]">
      <span className="flex text-[20px] font-bold">
        Modifier vos informations personnel
      </span>
      <div className="flex flex-col min_pc_mini:flex-row gap-6">
        <ProfilUpdateUserProfil />
        <form
          className="flex flex-col gap-4"
          onSubmit={!isLoading ? onSubmitForm : undefined}
          onChange={updateCheckForm}
        >
          <TextInputForm
            title={"Nom"}
            name={"lastname"}
            defaultValue={user.lastname}
            error={!!errors.lastname}
          />
          <TextInputForm
            title={"Prénoms"}
            name={"firstname"}
            defaultValue={user.firstname}
            error={!!errors.firstname}
          />
          <SelectInputForm
            title={"Pays"}
            name={"country"}
            defaultValue={user.country?.id ?? undefined}
            error={!!errors.country}
            options={countries
              .filter((e) => e.active)
              .map((item) => ({
                value: item.id,
                label: item.name,
              }))}
            onChange={(_) => {
              setErrors((e) => ({ ...e, country: null }));
            }}
          />
          <SelectInputForm
            title={"Représentation Messages de Vie à laquelle vous appartenez"}
            name={"representation"}
            error={!!errors.representation}
            defaultValue={user.mdvieoffice?.id ?? undefined}
            options={mdvieOffices
              .map((item) => ({
                value: item.id,
                label: item.name,
              }))
              .concat({
                value: "none",
                label: "Aucune Représentation",
              })}
            onChange={(_) => {
              setErrors((e) => ({ ...e, representation: null }));
            }}
          />
          <SelectInputForm
            title={"Église"}
            name={"church"}
            defaultValue={user.church?.id ?? undefined}
            error={!!errors.church}
            options={churchs.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            onChange={(_) => {
              setErrors((e) => ({ ...e, church: null }));
            }}
          />
          <SelectInputForm
            title={"Département souhaité ?"}
            name={"department"}
            error={!!errors.department}
            defaultValue={user.department?.id ?? undefined}
            options={departments.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            onChange={(_) => {
              setErrors((e) => ({ ...e, department: null }));
            }}
          />
          <PhoneInputForm
            title={"Contact Téléphonique"}
            subTitle={"Préciser l’indicatif du pays (Ex: +000)"}
            name={"phone"}
            defaultValue={user.phone}
            error={!!errors.phone}
          />
          <EmailInputForm
            title={"Email"}
            error={!!errors.email}
            defaultValue={user.email}
          />
          {errors.general && (
            <div className="mt-2">
              <FormMessage message={errors.general} styleMessage={"error"} />
            </div>
          )}
          <SubmitForm isLoading={isLoading} title="Mettre à jour" />
        </form>
      </div>
    </div>
  );
};

export default ProfilUpdateUserForm;
