import React from "react";
import { Plus } from "lucide-react";
import SearchInputForm from "../../../../../../../../components/form_components/search_input";
import {
  ScreenHeaderActionItem,
  ScreenHeaderActionRefresh,
} from "../../../../../../../../components/screen_header";
import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import SurveyQuestionNewModal from "../../../../../../../../components/modals/models/survey_question_new";
type Props = {
  onSearchText?: (term: string) => void;
};

const SurveyScreenDefaultHeader = ({ onSearchText }: Props) => {
  const queryClient = useQueryClient();
  const { id } = useParams();
  const isFetching = useIsFetching({ queryKey: ["surveys", id] }) > 0;
  const refetch = () =>
    queryClient.refetchQueries({
      queryKey: ["surveys", id],
      exact: true,
    });
  const [isOpen, setisOpen] = React.useState(false);
  function openCloseModal() {
    setisOpen(!isOpen);
  }
  return (
    <React.Fragment>
      <div className="flex flex-col bg-white rounded-[10px] px-5 py-5 gap-2">
        <div className="flex flex-row flex-wrap pt-0 gap-4">
          <SearchInputForm
            onChange={(e) => {
              !!onSearchText && onSearchText(e);
            }}
            height={40}
            placeHolder={"Question"}
          />
          <ScreenHeaderActionItem
            title="Ajouter"
            icon={<Plus size={20} fill="black" />}
            onClick={openCloseModal}
          />
          <ScreenHeaderActionRefresh
            onClick={() => refetch()}
            isLoading={isFetching}
          />
        </div>
      </div>
      <SurveyQuestionNewModal open={isOpen} handleClose={openCloseModal} />
    </React.Fragment>
  );
};

export default SurveyScreenDefaultHeader;
