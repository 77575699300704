import React from "react";
import ScreenContentLayout from "../../../../../components/screen_content_layout";
import { ChartLine, MailsIcon, MessageCircle } from "lucide-react";
import StatCard from "../../../../../components/stat_card";
import ButtonCard from "../../../../../components/button_card";
import { useNavigate } from "react-router-dom";

const DashNotificationsScreenDefault = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="flex flex-1 flex-col h-full w-full">
        <ScreenContentLayout>
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-3">
              <div className="flex flex-row gap-3">
                <ChartLine size={24} color="black" />
                <span className="flex text-[18px] text-black font-medium">
                  Statistiques
                </span>
              </div>
              <div className="flex flex-row gap-4 flex-wrap">
                <StatCard
                  text=" Toutes Notif. envoyées"
                  value="0"
                  motion="Sept 2024"
                  suffix="Ce mois"
                />
                <StatCard
                  text="Notif. Email envoyés"
                  value="0"
                  motion="Sept 2024"
                  suffix="Ce mois"
                />
                <StatCard
                  text="Notif. Sms envoyés"
                  value="0"
                  motion="Sept 2024"
                  suffix="Ce mois"
                />
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <span className="flex text-[18px] text-black font-medium">
                Actions disponibles
              </span>
              <div className="flex flex-row gap-4 flex-wrap">
                <ButtonCard
                  text="Envoyer Notif. SMS"
                  icon={<MessageCircle size={60} color="white" />}
                  onClick={() => {
                    navigate("./sms/new");
                  }}
                />
                <ButtonCard
                  text="Envoyer Notif. Email"
                  icon={<MailsIcon color="white" size={60} />}
                  onClick={() => {
                    navigate("./email/new");
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col gap-3 mt-5">
              <span className="flex text-[15px] text-black">
                Quinze (15) dernières récentes Notifications (Sms / Email)
                envoyées
              </span>
              <div className="flex flex-col bg-white min-h-[55px] rounded-[10px] max-w-[1300px]">
                <span className="flex flex-col pl-4 select-none h-[50px] justify-center text-[16px] text-black/30 font-light">
                  Aucune information envoyé récemment !
                </span>
              </div>
            </div>
          </div>
        </ScreenContentLayout>
      </div>
    </React.Fragment>
  );
};

export default DashNotificationsScreenDefault;
