import React from "react";
import ScreenHeader, {
  ScreenHeaderActionItem,
  ScreenHeaderActionRefresh,
} from "../../../../../components/screen_header";
import ScreenContentLayout from "../../../../../components/screen_content_layout";
import { Plus } from "lucide-react";
import TableDataAutoList from "../../../../../components/table";
import { useAuthStore } from "../../../../../configs/stores/auth";
import { useAdminDataStore } from "../../../../../configs/stores/admindataStore";
import { useQuery } from "@tanstack/react-query";
import ChurchActions from "./actions";
import ChurchNewModal from "../../../../../components/modals/models/church_new";

const DashChurchsScreen = () => {
  const { isAuthenticated } = useAuthStore();
  const [isOpen, setisOpen] = React.useState(false);
  function openCloseModal() {
    setisOpen(!isOpen);
  }
  const { churchs, isRefetchingChurchs, refetchChurchs } = useAdminDataStore();
  useQuery({
    enabled: isAuthenticated,
    queryFn: refetchChurchs,
    queryKey: ["churchs"],
    staleTime: 7 * 60 * 1000, // 5 minutes in milliseconds
    refetchInterval: 10 * 60 * 1000, // 5 minutes in milliseconds
  });
  const columns = [
    {
      label: "Nom",
      slug: "name",
    },
    {
      label: "Type",
      slug: "type",
    },
    {
      label: "Pays",
      slug: "country",
    },
    {
      label: "Ville",
      slug: "city",
    },
  ];
  return (
    <React.Fragment>
      <div className="flex flex-1 flex-col h-full w-full">
        <ScreenHeader title="Églises">
          <ScreenHeaderActionItem
            isLoading={isRefetchingChurchs}
            title="Ajouter"
            icon={<Plus size={20} fill="black" />}
            onClick={openCloseModal}
          />
          <ScreenHeaderActionRefresh
            onClick={refetchChurchs}
            isLoading={isRefetchingChurchs}
          />
        </ScreenHeader>
        <ScreenContentLayout>
          <TableDataAutoList
            columns={columns}
            rows={churchs.map((e) => ({
              item: e,
              ...e,
              country: e?.country?.name ?? "",
            }))}
            actions={ChurchActions}
          />
        </ScreenContentLayout>
      </div>
      <ChurchNewModal open={isOpen} handleClose={openCloseModal} />
    </React.Fragment>
  );
};

export default DashChurchsScreen;
