import React from "react";
import ConfirmActionModal from "../../../../../../../../components/modals/models/confirmation";
import { useQueryClient } from "@tanstack/react-query";
import { useAdminDataStore } from "../../../../../../../../configs/stores/admindataStore";
import { useParams } from "react-router-dom";
import SurveyQuestionUpdateModal from "../../../../../../../../components/modals/models/survey_question_update";

type Props = {
  item: any;
};

const SurveyQuestion = ({ item }: Props) => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { removeSurveyQuestion } = useAdminDataStore();
  const [isOpenModify, setisOpenModify] = React.useState(false);
  function closeModify() {
    setisOpenModify(false);
  }
  const [isOpen, setisOpen] = React.useState(false);
  function close() {
    setisOpen(false);
  }
  async function confirm() {
    if (!id) {
      alert("Requète non valide !");
      return;
    }
    return await removeSurveyQuestion(id, { id: item?.id }).then((res) => {
      if (!res.success) {
        !res?.message && alert(res?.message);
      } else {
        queryClient.setQueryData(["surveys", id], res.data);
      }
    });
  }
  return (
    <React.Fragment>
      <div className="flex flex-row justify-start p-4 gap-4 rounded-[10px] bg-white">
        <div className="flex rounded-[50%] h-[40px] w-[40px] items-center justify-center border border-primary bg-white">
          <span className="flex text-[12px]">
            {item?.index !== null ? "n°" + item.index : "no"}
          </span>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-2">
            <div className="flex flex-col">
              <span className="flex text-[13px] font-semibold text-primary">
                Question
              </span>
              <span>{item?.question}</span>
            </div>
            <div className="flex flex-col">
              <span className="flex text-[13px] font-semibold text-primary">
                Intervalle de reponse
              </span>
              <span>{item?.response_range}</span>
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <div
              onClick={() => setisOpenModify(true)}
              className="flex flex-col cursor-pointer"
            >
              <span className="flex text-[12px] font-medium underline underline-offset-2 text-black/50">
                Modifier
              </span>
            </div>
            <div
              onClick={() => setisOpen(true)}
              className="flex flex-col cursor-pointer"
            >
              <span className="flex text-[12px] font-medium underline underline-offset-2 text-black/50">
                Supprimer
              </span>
            </div>
          </div>
        </div>
      </div>
      <SurveyQuestionUpdateModal
        handleClose={closeModify}
        item={item}
        open={isOpenModify}
      />
      <ConfirmActionModal
        open={isOpen}
        message="Voulez-vous vraiment supprimer cette mission ?"
        confirm={confirm}
        handleClose={close}
      />
    </React.Fragment>
  );
};

export default SurveyQuestion;
